import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'

export default class BrandDetailStore {
  @observable
  brandDetail = null

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 10

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openDeleteModal = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  description_en = ''

  @observable
  description_ar = ''

  @observable
  en_description_validation = null

  @observable
  ar_description_validation = null

  @observable
  imageFile_validation = null

  @observable
  response = null

  @observable
  showUploader = true

  @action
  getBrandDetail = (id, history) => {
    this.isLoading = false
    this.imageFile = null
    axios
      .get(`${servicePath}/brand/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.brandDetail = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  deleteBrand = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/brand/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.reset()
        this.openDeleteModal = false
        history.push('/app/brand')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.openDeleteModal = false
        this.isLoading = true
        this.response = null
      })
  }

  @action
  async validation(id,history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.brandDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })

    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.brandDetail.description_en,
      require: true,
      type: 'string',
      min: 3,
    })
    // this.ar_description_validation = await checkInput({
    //   name: 'Arabic Description',
    //   value: this.description_ar,
    //   require: true,
    //   type: 'string',
    //   min: 3,
    // })
    // this.imageFile_validation = await checkInput({
    //   name: 'Image',
    //   value: this.imageFile,
    //   require: true,
    // })
    // this.imageFile_validation.res &&
      this.en_description_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateBrand(id,history)
  }

  @action
  handleUpdateBrand = (id, history) => {
    this.isLoading = false
    let data = {
      name_en: this.brandDetail.name_en,
      name_ar: this.brandDetail.name_ar,
      description_en: this.brandDetail.description_en,
      description_ar: this.brandDetail.description_ar,
    }
    if (this.imageFile) {
      data.icon = this.imageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/brand/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(() => {
        this.reset()
        this.getBrandDetail(id)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }


  @action
  handleImageUploader = () => {
    this.showUploader = !this.showUploader
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploader = !this.showUploader
  }

  // @action
  // changeImageFile = (file) => {
  //   this.imageFile = file
  // }

  @action.bound
  changeEnName = (name) => {
    this.brandDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.brandDetail.name_ar = name
  }

  @action.bound
  changeEnDescription = (name) => {
    this.brandDetail.description_en = name
  }

  @action
  changeArDescription = (name) => {
    this.brandDetail.description_ar = name
  }

  @action
  reset() {
    this.brandDetail = null

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 10

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openDeleteModal = false

    this.en_name_validation = null

    this.ar_name_validation = null

    this.description_en = ''

    this.description_ar = ''

    this.en_description_validation = null

    this.ar_description_validation = null

    this.imageFile_validation = null

    this.showUploader = true

  }
}
