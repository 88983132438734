import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Label } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import DisplayOption from '../../../components/DisolayOption'
import CardImageList from '../../../components/Card/cardImageList'
import Pagination from '../../../components/List/Pagination'
import CollectionModal from '../../../components/Modal/collectionModal'
import SelectInput from '../../../components/select'
import { ToastContainer, toast } from 'react-toastify'

@inject('stores')
@observer
class HomePageList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
  }
  @observable
  store = this.props.stores.homePage

  @observable
  setting = this.props.stores.setting

  componentDidMount() {
    this.store.getData(this.props.history);
    this.store.getListOfLatestOption();
    // this.store.getDependencies();
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (!this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="d-flex align-items-center">
                <Colxx xxs="8">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.homePage" />}
                    match={this.props.match}
                  />
                  <Row style={{ margin: '0  0 10px 0' }}>
                    <Colxx xl="4">
                      <Label>Home page latest</Label>{' '}
                      <SelectInput
                        className="react-select"
                        classNamePrefix="react-select"
                        value={this.store.selectedLatestOption}
                        onChange={(value) => this.store.setLatestOption(value)}
                        options={this.store.listOfLatestOptions}
                        isMulti={false}
                      />
                      {this.store.latestOptionSaving}
                    </Colxx>
                  </Row>
                </Colxx>
                <Colxx xxs="4" className="float-righte">
                  <Button
                    color="primary"
                    className="btn-shadow float-right mb-3"
                    size="lg"
                    onClick={() => this.store.getProduct(this.props.history)}
                  >
                    <IntlMessages id="pages.edit" />
                  </Button>
                  {this.store.openProductModal ? (
                    <CollectionModal
                      openModal={this.store.openProductModal}
                      data={this.store.products}
                      loading={this.store.isLoadingProduct}
                      toggleModal={() => this.store.toggleProductModal()}
                      pagination={this.store.paginationProduct}
                      limit={this.store.limitProduct}
                      currentPage={this.store.currentPageProduct}
                      changePage={(e) => this.store.changePageModal(e)}
                      target="product"
                      selectedItems={this.store.productList}
                      addItems={(data) =>
                        this.store.addItemToSelected(data, this.props.history)
                      }
                    ></CollectionModal>
                  ) : null}
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <DisplayOption />
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Row>

            {this.store.isLoading ? (
              <div className="loading"></div>
            ) : (
              <>
                <Row>
                  <Colxx xxs="12">
                    {this.setting.displayMode === 'thumblist' ? (
                      <Row>
                        {this.store.data
                          ? this.store.data.map((product) => (
                            <CardThumbList
                              key={product.id}
                              data={product}
                              path="product/detail"
                            />
                          ))
                          : null}
                      </Row>
                    ) : (
                      <Row>
                        {this.store.data
                          ? this.store.data.map((product) => (
                            <CardImageList
                              // imageWidth="50%"
                              // imageLeft="25%"
                              key={product.id}
                              data={product}
                              path="product/detail"
                            />
                          ))
                          : null}
                      </Row>
                    )}
                  </Colxx>
                </Row>
                {this.store.pagination > 1 ? (
                  <Pagination
                    totalPage={this.store.pagination}
                    numberLimit={this.store.limit}
                    onChangePage={(e) =>
                      this.store.changePage(e, this.props.history)
                    }
                    currentPage={this.store.currentPage}
                  />
                ) : null}
              </>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

export default HomePageList
