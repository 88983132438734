import React, { Component } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Card, CardBody, CardText, Badge } from 'reactstrap'
import { Colxx } from '../CustomBootstrap/index'
// import { Link } from 'react-router-dom'

const SortableItem = SortableElement(({ value, action, full }) => {
  return (
    <div className={`${full ? 'col-xl-12': 'col-xl-6'} p-3`}>
       <Card className="col-12" style={{cursor:'move'}}>
        <CardBody className="col-12 d-flex flex-wrap align-items-center font-weight-bold">
          <CardText className="col-4 m-0">{value.title_en}</CardText>
          <CardText className="col-4 m-0 text-center">
            <Badge
              style={{ fontSize: 12 }}
              color={value.is_visible ? 'success' : 'danger'}
            >
              {value.is_visible ? 'Active' : 'Inactive'}
            </Badge>
          </CardText>

          <CardText
            className="col-4 m-0 text-center pointer d-flex font-weight-bold"
            color="link"
            // onClick={() => action(value.id)}
          >
           <a href={`/app/menu/detail/${value.id}`}>Go to Detail <i className="iconsminds-triangle-arrow-right" /></a> 
          </CardText>
        </CardBody>
      </Card>
    </div>
  )
})

const SortableList = SortableContainer(({ items, action, full }) => {
  return (
    <Colxx xxs="12" className="d-flex flex-wrap align-items-center">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} action={action} full={full}/>
      ))}
    </Colxx>
  )
})

class Sort extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: []
    }
  }
  componentDidMount() {
    this.setState({ items: this.props.data })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.data })
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {

     this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }))
    this.props.action(this.state.items)
  }

  handleClick=(id)=>{
      window.location.href=`/app/menu/detail/${id}`
  }


  render() {
    return (
      <div className="d-flex">
        <SortableList
          axis="xy"
          items={this.state.items}
          onSortEnd={this.onSortEnd}
          distance={1}
          action={(id)=>this.handleClick(id)}
          full={this.props.full}
        />
      </div>
    )
  }
}

export default Sort

