import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BrewList from './list';
import BrewDetail from "./detail"



const Brew = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={BrewList} />
            <Route path={`${match.url}/detail/:id`} component={BrewDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Brew;