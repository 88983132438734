import servers from "./config";
import url from "./url";

let config = servers["testServer"];

export default new class {
 

  /**
   * @function appAPI Generate and return api addresses
   *
   * @param {string} resource categoury name of data
   * @param {string} method get,put,post,delete
   * @param {string} version v1,v2,...
   *
   * @returns generated api URL
   */
  appAPI = (resource, method, type) => {
    let uri = `${config.application.protocol}://${config.application.host}/${config.application.app}/${resource}/${url[resource][method][type]}`;
    return uri;

  };

  /**
   * @function deleteAPI Generate and return api addresses
   *
   * @param {string} resource categoury name of data
   * @param {string} method get,put,post,delete
   * @param {string} type list,delete,...
   *
   * @returns generated api URL
   */
  deleteAPI = (resource, method, id , type) => {
    let uri = `${config.application.protocol}://${config.application.host}/${config.application.app}/${resource}/${id}/${url[resource][method][type]}`;
    return uri;

  };

  /**
   * @function detailAPI Generate and return api addresses
   *
   * @param {string} resource categoury name of data
   * @param {string} method get,put,post,delete
   * @param {string} id 1,2,...
   *
   * @returns generated api URL
   */
  detailAPI = (resource, id ,) => {
    let uri = `${config.application.protocol}://${config.application.host}/${config.application.app}/${resource}/${id}`;
    return uri;

  };

  
}();
