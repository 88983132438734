import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'

export default class CustomerDetailStore {
  @observable
  faqDetail = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 10

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openDeleteModal = false

  @observable
  selectedCategory = null

  @observable
  categoriesList = []

  @observable
  en_content_validation = null

  @observable
  en_title_validation = null

  @observable
  category_validation = null

  @observable
  response = null

  @action
  getFaqDetail = (id, history) => {
    this.isLoading = false
    this.imageFile = null
    axios
      .get(`${servicePath}/faq/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.faqDetail = data.data
        await this.getFaqCategories(history)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getFaqCategories = async (history) => {
    const data = { all: true }
    await axios
      .post(`${servicePath}/faq/category/list`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        data.data.items.forEach((category) => {
          if (category.id === this.faqDetail.category_id) {
            this.selectedCategory = {
              value: category.id,
              label: category.name_en,
            }
          }
          this.categoriesList.push({
            value: category.id,
            label: category.name_en,
          })
        })
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  deleteFaq = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/faq/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        this.reset()
        history.push('/app/faq')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.openDeleteModal = false
      })
  }

  @action
  async validation(id, history) {
    this.en_title_validation = await checkInput({
      name: 'English Title',
      value: this.faqDetail.title_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.category_validation = await checkInput({
      name: 'Category',
      value: this.selectedCategory,
      require: true,
    })
    this.en_content_validation = await checkInput({
      name: 'English content',
      value: this.faqDetail.content_en,
      require: true,
      min: 3,
    })
    this.category_validation.res &&
      this.en_content_validation.res &&
      this.en_title_validation &&
      this.handleUpdateFaq(id, history)
  }

  @action
  handleUpdateFaq = (id, history) => {
    this.isLoading = false
    let data = {
      title_en: this.faqDetail.title_en,
      title_ar: this.faqDetail.title_ar,
      content_en: this.faqDetail.content_en,
      content_ar: this.faqDetail.content_ar,
      category_id: this.selectedCategory.value,
    }

    axios
      .post(`${servicePath}/faq/${id}`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(() => {
        this.reset()
        this.getFaqDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeTitle_en = (name) => {
    this.faqDetail.title_en = name
  }

  @action
  changeTitle_ar = (name) => {
    this.faqDetail.title_ar = name
  }

  @action
  changeContent_en = (name) => {
    this.faqDetail.content_en = name
  }

  @action
  changeContent_ar = (name) => {
    this.faqDetail.content_ar = name
  }

  @action
  setCategory = (value) => {
    this.selectedCategory = value.selectedOption
  }

  @action
  reset() {
    this.faqDetail = null

    this.search = ''

    this.searchOn = null

    this.limit = 10

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openDeleteModal = false

    this.selectedCategory = null

    this.categoriesList = []

    this.en_content_validation = null

    this.en_title_validation = null

    this.category_validation = null
  }
}
