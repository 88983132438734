import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
// import axiFetch from "../config/fetch";

export default class AreaStore {
  @observable
  data = null

  @observable
  areaDetail = null

  @observable
  defaultPostingPrice = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  categoryDetail = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  isLoadingMini = false

  @observable
  openModal = false

  @observable
  openDeleteModal = false

  @observable
  imageFile = null

  @observable
  newArName = null

  @observable
  newEnName = null

  @observable
  imageFile_validation = null

  @observable
  price_validation = null

  @observable
  en_name_validation = null

  @observable
  btnDisable = false

  @observable
  response = null

  @action
  getData = async (history) => {
    this.isLoading = false

    axios
      .get(`${servicePath}/area/all`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.data = data.data.items
        await this.getDefaultDetail(history)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getAreaDetail = async (id, history) => {
    this.isLoading = false

    axios
      .get(`${servicePath}/area/detail/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.areaDetail = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  handleIsVisible = async (id, history) => {
    this.isLoadingMini = true
    this.areaDetail.status = this.areaDetail.status ? 0 : 1
    const body = { status: this.areaDetail.status }
    axios
      .put(`${servicePath}/area/activation/${id}`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.isLoadingMini = false
        // this.reset()
        // this.getAreaDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  handleUpdateArea = async (id, history) => {
    this.isLoading = false
    const body = { price: this.areaDetail.posting_price }
    axios
      .post(`${servicePath}/area/${id}`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.reset()
        this.getAreaDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  getDefaultDetail = async (history) => {
    await axios
      .get(`${servicePath}/area/default/get`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.defaultPostingPrice = data.data
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async validation(id, history) {
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.areaDetail.posting_price,
      require: true,
      type: 'number',
      min: 1,
    })

    this.price_validation.res && this.handleUpdateArea(id, history)
  }

  @action
  async defaultValidation(history) {
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.defaultPostingPrice.price,
      require: true,
      type: 'number',
      min: 1,
    })

    this.price_validation.res && this.handleUpdateDefault(history)
  }

  @action
  handleUpdateDefault = async (history) => {
    this.isLoading = false
    const body = {
      price: this.defaultPostingPrice.price,
      is_default: this.defaultPostingPrice.is_default,
    }
    axios
      .post(`${servicePath}/area/default/set`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.toggleModal()
        this.reset()
        this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.toggleModal()
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  changeNewEnName = (value) => {
    this.newEnName = value
  }

  @action
  changeNewArName = (value) => {
    this.newArName = value
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.price_validation = null
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changePrice = (value) => {
    this.areaDetail.posting_price = value
  }

  @action
  changeDefaultPrice = (value) => {
    this.defaultPostingPrice.price = value
  }

  @action
  changeIsDefault = (value) => {
    this.defaultPostingPrice.is_default = this.defaultPostingPrice.is_default
      ? 0
      : 1
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.areaDetail = null

    this.defaultPostingPrice = null

    this.pagination = 0

    this.currentPage = 1

    this.categoryDetail = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.isLoadingMini = false

    this.openModal = false

    this.openDeleteModal = false

    this.imageFile = null

    this.newArName = null

    this.newEnName = null

    this.imageFile_validation = null

    this.price_validation = null

    this.en_name_validation = null

    this.btnDisable = false
  }
}
