import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  // Label,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'
// import { Thumb } from '../Thumb'
import SelectInput from '../select'
import ImageUploader from '../Uploader/Uploader'

class FeaturetteModal extends Component {
  constructor(props) {
    super(props)
    this.state = { getImage: false }
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeImageFile_en(data)
    } else {
      this.setState({ getImage: false })
    }
    // this.props.handleSave()
  }

  changeArabicImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeImageFile_ar(data)
    } else {
      this.setState({ getImage: false })
    }
    this.props.handleSave()
  }
  render() {
    const {
      openModal,
      toggleModal,
      // imageFile_en,
      // imageFile_ar,
      title_en,
      title_ar,
      description_en,
      description_ar,
      // changeImageFile_en,
      // changeImageFile_ar,
      changeTitle_en,
      changeTitle_ar,
      changeDescription_en,
      changeDescription_ar,
      // handleSave,
      btnDisable,
      en_imageFile_validation,
      ar_imageFile_validation,
      en_title_validation,
      ar_title_validation,
      en_description_validation,
      ar_description_validation,
      selectedType,
      type_validation,
      item_validation,
      brand_validation,
      itemList,
      brandsList,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader
          toggle={toggleModal}
          className="d-flex justify-content-center align-items-center"
        >
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody className="p-2">
          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.title-en" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <Input
                name="title"
                value={title_en ? title_en : ''}
                type="text"
                onChange={(e) => changeTitle_en(e.target.value)}
              />
            </div>
            <span className="req-validation">
              {' '}
              {en_title_validation
                ? en_title_validation.msg
                  ? en_title_validation.msg
                  : null
                : null}
            </span>
          </Colxx>

          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.title-ar" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <Input
                name="title_ar"
                value={title_ar ? title_ar : ''}
                type="text"
                onChange={(e) => changeTitle_ar(e.target.value)}
                className="ar"
              />
            </div>
            <span className="req-validation">
              {' '}
              {ar_title_validation
                ? ar_title_validation.msg
                  ? ar_title_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
          <Colxx xxs="12" className=" mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.type" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <SelectInput
                className="react-select"
                classNamePrefix="react-select"
                value={selectedType ? selectedType : null}
                onChange={(value) => this.props.setType(value)}
                options={this.props.typeList}
                isMulti={false}
              ></SelectInput>
            </div>
            <span className="req-validation">
              {' '}
              {type_validation
                ? type_validation.msg
                  ? type_validation.msg
                  : null
                : null}
            </span>
          </Colxx>

          <Colxx xxs="12" className=" mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.item" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <SelectInput
                className="react-select"
                classNamePrefix="react-select"
                value={this.props.selectedItem ? this.props.selectedItem : null}
                onChange={(value) => this.props.setItem(value)}
                options={itemList ? itemList : []}
                isMulti={false}
              ></SelectInput>
            </div>
            <span className="req-validation">
              {' '}
              {item_validation
                ? item_validation.msg
                  ? item_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
          {brandsList[0] && selectedType && selectedType.label === 'product' && (
            <Colxx xxs="12" className=" mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="menu.brand" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <SelectInput
                  className="react-select"
                  classNamePrefix="react-select"
                  value={
                    this.props.selectedBrand ? this.props.selectedBrand : ''
                  }
                  onChange={(value) => this.props.setBrand(value)}
                  options={brandsList}
                  isMulti={false}
                ></SelectInput>
              </div>
              <span className="req-validation">
                {brand_validation
                  ? brand_validation.msg
                    ? brand_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          )}
          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.description" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <Input
                name="descriptipn_en"
                value={description_en ? description_en : ''}
                type="textarea"
                onChange={(e) => changeDescription_en(e.target.value)}
              />
            </div>
            <span className="req-validation">
              {' '}
              {en_description_validation
                ? en_description_validation.msg
                  ? en_description_validation.msg
                  : null
                : null}
            </span>
          </Colxx>

          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.description-ar" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <Input
                name="description_ar"
                value={description_ar ? description_ar : ''}
                type="textarea"
                onChange={(e) => changeDescription_ar(e.target.value)}
                className="ar"
              />
            </div>
            <span className="req-validation">
              {' '}
              {ar_description_validation
                ? ar_description_validation.msg
                  ? ar_description_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.enImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.VendorModal = instance)}
              images={this.state.imageFile}
              ratio="1:1"
              size={{ width: 1000, height: 1000 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeImageFile(data)
              }}
            />
            <span className="req-validation">
              {' '}
              {en_imageFile_validation
                ? en_imageFile_validation.msg
                  ? en_imageFile_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.arImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.featureteModal = instance)}
              images={this.state.imageFile}
              ratio="1:1"
              size={{ width: 1000, height: 1000 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeArabicImageFile(data)
              }}
            />
           
            <span className="req-validation">
              {' '}
              {ar_imageFile_validation
                ? ar_imageFile_validation.msg
                  ? ar_imageFile_validation.msg
                  : null
                : null}
            </span>
           
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => this.handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default FeaturetteModal
