import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import PriceModal from '../../../components/Modal/priceModal'
import Pagination from '../../../components/List/Pagination'
import { ToastContainer, toast } from 'react-toastify'

@inject('stores')
@observer
class AreaList extends Component {
  @observable
  store = this.props.stores.area

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.area" />}
                  match={this.props.match}
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => this.store.changeOpenModal()}
                >
                  <IntlMessages id="pages.set-default" />
                </Button>
                {this.store.defaultPostingPrice && (
                  <PriceModal
                    openModal={this.store.openModal}
                    toggleModal={() => this.store.toggleModal()}
                    price={this.store.defaultPostingPrice.price}
                    is_default={this.store.defaultPostingPrice.is_default}
                    changePrice={(data) => this.store.changeDefaultPrice(data)}
                    changeIsDefault={(data) => this.store.changeIsDefault(data)}
                    handleSave={() =>
                      this.store.defaultValidation(this.props.history)
                    }
                    btnDisable={this.store.btnDisable}
                    price_validation={this.store.price_validation}
                  ></PriceModal>
                )}
                {/* <Row className="col-12 justify-content-end p-0 m-0">
                  <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0">
                    <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                      <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                        <input
                          type="text"
                          name="keyword"
                          id="search"
                          value={this.store.search}
                          placeholder={`Search`}
                          onChange={e => this.store.hendleChangeKey(e)}
                          onKeyPress={event => this.store.handleKeyPress(event)}
                        />
                      </div>
                    </div>{" "}
                  </div>{" "}
                </Row> */}
                <Separator className="mb-5" />
              </Colxx>
            </Row>

            <Row>
              <Colxx xxs="12">
                <Row>
                  {this.store.data
                    ? this.store.data.map((area, index) => (
                        <CardThumbList
                          key={index}
                          data={area}
                          path="area/detail"
                        />
                      ))
                    : null}
                </Row>
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default AreaList
