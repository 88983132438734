import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { Thumb } from '../../../components/Thumb'
import DeleteModal from '../../../components/Modal/deleteModal'
import { ToastContainer, toast } from 'react-toastify'
import ImageUploader from '../../../components/Uploader/Uploader'

@inject('stores')
@observer
class BrandDetail extends Component {
  @observable
  store = this.props.stores.brandDetail
  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, getImage: false }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getBrandDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFile(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.validation(this.state.id, this.props.history)
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />

        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.brand" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={
                  this.store.brandDetail ? this.store.brandDetail.name_en : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteBrand(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() =>
                  this.store.showUploader
                    ? this.store.validation(this.state.id, this.props.history)
                    : this.handleSave()
                }
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <Row>
                    <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                      <Card className="mb-2 h-100">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              {`image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          {this.store.showUploader ? (
                            <>
                              {' '}
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploader()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Image</strong>
                                </Label>
                              </Button>
                              <Thumb
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.imageFile}
                                src={
                                  this.store.brandDetail
                                    ? this.store.brandDetail.image.url
                                    : '/assets/img/noimage.png'
                                }
                              />
                            </>
                          ) : (
                            <ImageUploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              getImage={this.state.getImage}
                              flag={true}
                              ratio="1:1"
                              size={{ width: 400, height: 400 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploader()
                              }
                              changeImageFile={(data) => {
                                this.changeImageFile(data)
                              }}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx xxs="12" sm="8" lg="8" className="mb-8">
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                            <Colxx xxs="12" lg="6">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.name" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-3">
                                <Input
                                  name="name"
                                  value={
                                    this.store.brandDetail
                                      ? this.store.brandDetail.name_en
                                      : ''
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeEnName(e.target.value)
                                  }
                                />
                                <span className="req-validation">
                                  {this.store.en_name_validation
                                    ? this.store.en_name_validation.msg
                                      ? this.store.en_name_validation.msg
                                      : null
                                    : null}
                                </span>
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="6">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.name-ar" />
                              </p>
                              <p className="mb-3">
                                <Input
                                  name="name_ar"
                                  value={
                                    this.store.brandDetail
                                      ? this.store.brandDetail.name_ar
                                      : ''
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeArName(e.target.value)
                                  }
                                  className="ar"
                                />
                              </p>
                            </Colxx>

                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.description" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-3">
                                <Input
                                  name="description"
                                  value={
                                    this.store.brandDetail
                                      ? this.store.brandDetail.description_en
                                      : ''
                                  }
                                  type="textarea"
                                  onChange={(e) =>
                                    this.store.changeEnDescription(
                                      e.target.value
                                    )
                                  }
                                />
                                <span className="req-validation">
                                  {this.store.en_description_validation
                                    ? this.store.en_description_validation.msg
                                      ? this.store.en_description_validation.msg
                                      : null
                                    : null}
                                </span>
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.description-ar" />
                              </p>
                              <p className="mb-3">
                                <Input
                                  name="description_ar"
                                  value={
                                    this.store.brandDetail
                                      ? this.store.brandDetail.description_ar
                                      : ''
                                  }
                                  type="textarea"
                                  onChange={(e) =>
                                    this.store.changeArDescription(
                                      e.target.value
                                    )
                                  }
                                  className="ar"
                                />
                              </p>
                            </Colxx>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default BrandDetail
