export default {
  admin:{
    post:{login:'login'}
  },

  category:{
    post:{list:'list'},
    get:{delete:'delete'}
  },
  area:{
    post:{list:'list'},
    get:{delete:'delete', all:"all"}
  },

  brand:{
    post:{list:'list'},
    get:{delete:'delete'}
  },

  tag:{
    post:{list:'list'},
    get:{delete:'delete'}
  },

  brew:{
    post:{list:'list'},
    get:{delete:'delete'}
  },
  roast:{
    post:{list:'list'},
    get:{delete:'delete'}
  },
  user:{
    post:{list:'list'},
    get:{delete:'delete'}
  },

  featurette:{
    post:{list:'list'},
    get:{delete:'delete' ,dependencies:'dependencies/get'}
  },
  banner:{
    post:{list:'list'},
    get:{delete:'delete'}
  },
  blog:{
    post:{list:'list'},
    get:{delete:'delete'}
  },

  coupon:{
    post:{list:'list', add:'add'},
    get:{delete:'delete'}
  },

  subscribe:{
    get:{all:'intervalList'}
  },

  product:{
    post:{getDependencies:'getDependencies', add:'add', getcakes:'getcakes', list:'list'},
    get:{delete:'delete'}
  },

  
};
