import React, { Component, Fragment } from 'react'
import {
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import IntlMessages from '../../../util/IntlMessages'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import SunEditor from 'suneditor-react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { ToastContainer, toast } from 'react-toastify'

@inject('stores')
@observer
class TermsList extends Component {
  @observable
  store = this.props.stores.terms

  constructor(props) {
    super(props)
    this.state = { id: null, getImage: false }
  }

  componentDidMount() {
    // let path = this.props.match.params.id
    // this.setState({ id: path })
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.termsOfServices" />}
                  match={this.props.match}
                />
                
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => {
                    this.store.handleSaveTerms(this.props.history)
                  }}
                >
                  <IntlMessages id="pages.save" />
                </Button>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                  <Card className="mb-2 h-100" style={{ overflow: 'unset' }}>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`English Content`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <SunEditor
                        setContents={this.store.data?.en||''}
                        onChange={(data) =>
                          this.store.handleChangeEnContent(data)
                        }
                        setOptions={{
                          placeholder: 'Please type here...',
                          autoFocus: true,
                          showPathLabel: true,
                          charCounter: true,
                          // maxCharCount: 720,
                          width: '100%',
                          height: 'auto',
                          minHeight: '200px',
                          buttonList: [
                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                            [
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                              'removeFormat',
                            ],
                            [
                              'fontColor',
                              'hiliteColor',
                              'outdent',
                              'indent',
                              'align',
                              'horizontalRule',
                              'list',
                              'table',
                            ],
                            [
                              'link',
                              'image',
                              'video',
                              'fullScreen',
                              'showBlocks',
                              'codeView',
                              'preview',
                              'print',
                              'save',
                            ],
                          ],
                        }}
                      />
                    </CardBody>
                  </Card>
                </Colxx>

                <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                  <Card className="mb-2 h-100" style={{ overflow: 'unset' }}>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`Arabic Content`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <SunEditor
                        setContents={this.store.data?.ar||""}
                        onChange={(data) =>
                          this.store.handleChangeArContent(data)
                        }
                        setOptions={{
                          placeholder: 'Please type here...',
                          autoFocus: true,
                          showPathLabel: true,
                          charCounter: true,
                          // maxCharCount: 720,
                          width: '100%',
                          height: 'auto',
                          minHeight: '200px',
                          buttonList: [
                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                            [
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                              'removeFormat',
                            ],
                            [
                              'fontColor',
                              'hiliteColor',
                              'outdent',
                              'indent',
                              'align',
                              'horizontalRule',
                              'list',
                              'table',
                            ],
                            [
                              'link',
                              'image',
                              'video',
                              'fullScreen',
                              'showBlocks',
                              'codeView',
                              'preview',
                              'print',
                              'save',
                            ],
                          ],
                        }}
                      />
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </Colxx>
          </>
        )}
      </Fragment>
    )
  }
}

export default TermsList
