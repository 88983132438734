import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import DisplayOption from '../../../components/DisolayOption'
import CardImageList from '../../../components/Card/cardImageList'
import Pagination from '../../../components/List/Pagination'
import UserModal from '../../../components/Modal/userModal'
import { ToastContainer, toast } from 'react-toastify'

@inject('stores')
@observer
class UserList extends Component {
  @observable
  store = this.props.stores.user

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />

        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="d-flex align-items-center">
                <Colxx xxs="8">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.user" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx xxs="4" className="float-right">
                  <Button
                    color="primary"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() => this.store.changeOpenModal()}
                  >
                    <IntlMessages id="pages.add-new" />
                  </Button>
                  <UserModal
                    openModal={this.store.openModal}
                    toggleModal={() => this.store.toggleModal()}
                    name_en={this.store.name}
                    email={this.store.email}
                    changeEnName={(data) => this.store.changeName(data)}
                    changeEmail={(data) => this.store.changeEmail(data)}
                    handleSave={() => this.store.validation(this.props.history)}
                    btnDisable={this.store.btnDisable}
                    name_en_validation={this.store.name_validation}
                    email_validation={this.store.email_validation}
                  ></UserModal>
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <DisplayOption />
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Row>

            <Row>
              <Colxx xxs="12">
                {this.setting.displayMode === 'thumblist' ? (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((user) => (
                          <CardThumbList
                            key={user.id}
                            data={user}
                            path="user/detail"
                          />
                        ))
                      : null}
                  </Row>
                ) : (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((user) => (
                          <CardImageList
                            key={user.id}
                            data={user}
                            path="user/detail"
                          />
                        ))
                      : null}
                  </Row>
                )}
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default UserList
