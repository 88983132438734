import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import _ from 'lodash'

export default class HomePageStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  cakeDetail = null

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = null

  @observable
  searchOn = ['name_en', 'name_ar']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = { is_hot: true }

  @observable
  isLoading = true

  @observable
  brewList = []

  @observable
  categoryList = []

  @observable
  searchProduct = null

  @observable
  searchOnProduct = null

  @observable
  limitProduct = 12

  @observable
  offsetProduct = 0

  @observable
  orderProduct = { id: -1 }

  @observable
  filterProduct = {}

  @observable
  discount = true

  @observable
  currentPageProduct = 1

  @observable
  isLoadingProduct = false

  @observable
  openProductModal = false

  @observable
  paginationProduct = null

  @observable
  productList = []

  @observable
  response = null

  @observable
  listOfLatestOptions = []

  @observable
  selectedLatestOption = null

  @observable
  latestOptionSaving = ''

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
    }
    const value = await axiFetch.post('product', body, 'list', history)
    if (value) {
      this.data = value.data.items
      this.productList = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    }
  }

  @action
  getProduct = async (history) => {
    this.isLoadingProduct = true
    this.changeOpenProductModal()
    const body = {
      order: this.orderProduct,
      searchOn: this.searchOnProduct,
      search: this.searchProduct,
      limit: this.limitProduct,
      offset: this.offsetProduct,
      filter: this.filterProduct,
    }
    const value = await axiFetch.post('product', body, 'list', history)
    if (value) {
      this.products = value.data.items
      this.paginationProduct =
        parseInt(value.data.pagination.total / this.limitProduct) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoadingProduct = false
    }
  }

  @action
  handleUpdateHomePage = async (history) => {
    this.isLoading = true
    const productId = []
    this.productList.forEach((item) => {
      productId.push(item.id)
    })
    const data = { items: productId }
    axios
      .post(`${servicePath}/product/hot/set`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        await this.reset()
        this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  getListOfLatestOption = async (history) => {
    this.isLoading = true
    axios
      .get(`${servicePath}/home/latest`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        data.data.map((d) => {
          if (d.selected === true) {
            this.selectedLatestOption = { label: d.title, value: d.value }
          }
          return this.listOfLatestOptions.push({
            value: d.value,
            label: d.title,
          })
        }
        )
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  setLatestOption = async (value) => {
    this.latestOptionSaving = 'saving...';
    this.selectedLatestOption = value.selectedOption
    axios
      .post(`${servicePath}/home/setLatest`, {
        value: value.selectedOption.value
      }, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.latestOptionSaving = 'saved';
      })
      .catch((err) => {
        console.log({ err })
        // checkUnauthorized(err, history)
      })
  }

  @action
  async handleKeyPress(e, history) {
    if (e.key === 'Enter') {
      this.getData(history)
    }
  }

  @action
  handleChangeSearch(value) {
    this.search = value
  }

  @action
  changeOpenProductModal = () => {
    this.openProductModal = true
  }

  @action
  toggleProductModal = () => {
    this.openProductModal = false
  }

  @action
  changePage(e, history) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData(history)
  }

  @action
  changePageModal(e) {
    this.offsetProduct = (e - 1) * 12
    this.currentPageProduct = e
    this.getProduct()
  }

  @action
  addItemToSelected = async (value, history) => {
    // await value.map(item => this.productList.push(item));

    const intersection = await _.intersectionBy(value, 'id')
    this.productList = [...intersection]
    this.handleUpdateHomePage(history)
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.cakeDetail = null

    this.currentPage = 1

    this.imageFile = null

    this.search = null

    this.searchOn = ['name_en', 'name_ar']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = { is_hot: true }

    this.isLoading = true

    this.brewList = []

    this.categoryList = []

    this.searchProduct = null

    this.searchOnProduct = null

    this.limitProduct = 12

    this.offsetProduct = 0

    this.orderProduct = { id: -1 }

    this.filterProduct = {}

    this.discount = true

    this.currentPageProduct = 1

    this.isLoadingProduct = false

    this.openProductModal = false

    this.paginationProduct = null

    this.productList = []

    this.listOfLatestOptions = []
  }
}
