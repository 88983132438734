import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'


export default class UserDetailStore {
  @observable
  userDetail = null

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 10

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openDeleteModal = false

  @observable
  response = null

  @observable
  name_validation = null

  @action
  getUserDetail = (id, history) => {
    this.isLoading = false
    this.imageFile = null
    axios
      .get(`${servicePath}/admin/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.userDetail = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  deleteUser = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/admin/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        this.reset()
        history.push('/app/user')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.openDeleteModal = false
      })
  }

  @action
  async validation(id,history) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.userDetail.name,
      require: true,
      type: 'string',
      min: 3,
    })


      this.name_validation.res &&
      this.handleUpdateUser(id,history)
  }

  @action
  handleUpdateUser = (id, history) => {
    this.isLoading = false
    const data = {
      name: this.userDetail.name,
      about_en: this.userDetail.about_en,
      about_ar: this.userDetail.about_ar,
    }
    if (this.imageFile) {
      data.profile_img = this.imageFile
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/admin/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async () => {
         this.reset()
        this.getUserDetail(id)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action.bound
  changeEnName = (name) => {
    this.userDetail.name = name
  }

  @action
  changeEnAbout = (value) => {
    this.userDetail.about_en = value
  }

  @action
  changeArAbout = (value) => {
    this.userDetail.about_ar = value
  }

  @action
  reset() {
    this.userDetail = null

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 10

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openDeleteModal = false

    this.name_validation = null
  }
}
