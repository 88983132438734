import React, { Component, Fragment } from "react";
import {
  Row,
  Button,
  Card,
  CardBody,
  Input,
  CardTitle
} from "reactstrap";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import IntlMessages from "../../../util/IntlMessages";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import SunEditor from "suneditor-react";
import SelectInput from "../../../components/select";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import ImageUploader from "../../../components/Uploader/Uploader";


@inject("stores")
@observer
class AddPost extends Component {
  @observable
  store = this.props.stores.event;

  constructor(props) {
    super(props);
    this.state = {getImage:false};
  }

  componentDidMount() {
    this.store.getDependencies(this.props.history);
  }

  componentWillUnmount() {
    this.store.reset();
  }

  handleChange=(date)=>{
    console.log(date)
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      await this.store.changeCoverImageFile(data)
    } else {
      await this.store.changeCoverImageFile(null)
    }
  }

  changeThumbImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeThumbImageFile(data)
    } else {
      this.setState({ getImage: false })
      await this.store.changeThumbImageFile(null)
    }
    this.store.validation(this.props.history)
  }

  notify = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      default:
        break;
    }
  };

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type);
      }
    }
    return (
      <Fragment>
         <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.event" />}
                  match={this.props.match}
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.handleSave()
                  }
                >
                  <IntlMessages id="pages.save" />
                </Button>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                  <Card className="mb-2 h-100">
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`cover image`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <ImageUploader
                        multiple={false}
                        ref={(instance) => (this.VendorModal = instance)}
                        images={this.state.imageFile}
                        ratio="3:1"
                        size={{ width: 1920, height: 640 }}
                        getImage={this.state.getImage}
                        changeImageFile={(data) => {
                          this.changeImageFile(data)
                        }}
                      />
                      <span className="req-validation ">
                        {this.store.coverImage_validation
                          ? this.store.coverImage_validation.msg
                            ? this.store.coverImage_validation.msg
                            : null
                          : null}
                      </span>
                    </CardBody>
                  </Card>
                </Colxx>

                <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                  <Card className="mb-2 h-100" >
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`thumb image`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <ImageUploader
                        multiple={false}
                        ref={(instance) => (this.VendorModal = instance)}
                        images={this.state.imageFile}
                        ratio="1:1"
                        size={{ width: 500, height: 500 }}
                        getImage={this.state.getImage}
                        changeImageFile={(data) => {
                          this.changeThumbImageFile(data)
                        }}
                      />
                      <span className="req-validation ">
                        {this.store.thumbImage_validation
                          ? this.store.thumbImage_validation.msg
                            ? this.store.thumbImage_validation.msg
                            : null
                          : null}
                      </span>
                    </CardBody>
                  </Card>
                </Colxx>
                <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                  <Card className="mb-2 h-100" style={{overflow:'unset'}}>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`Detail`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.title-en" />
                          <span className="req"> *</span>
                        </p>
                        <p className="mb-0">
                          <Input
                            name="name"
                            value={this.store.enName ? this.store.enName : ""}
                            type="text"
                            onChange={e =>
                              this.store.changeEnName(e.target.value)
                            }
                          />
                        </p>
                        <span className="req-validation ">
                          {this.store.en_name_validation
                            ? this.store.en_name_validation.msg
                              ? this.store.en_name_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.title-ar" />
                        </p>
                        <p className="mb-0">
                          <Input
                            name="title_ar"
                            value={this.store.arName ? this.store.arName : ""}
                            type="text"
                            onChange={e =>
                              this.store.changeArName(e.target.value)
                            }
                            className="ar"
                          />
                        </p>
                      </Colxx>

                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.date" />
                          <span className="req"> *</span>
                        </p>
                      
                          <DatePicker
                            selected={this.store.startDate}
                            onChange={(date)=>this.store.handleChangeDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm"
                            />
                       
                        <span className="req-validation ">
                          {this.store.date_validation
                            ? this.store.date_validation.msg
                              ? this.store.date_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.tag" />
                          {/* <span className="req"> *</span> */}
                        </p>
                        <SelectInput
                          className="react-select"
                          classNamePrefix="react-select"
                          value={this.store.tags}
                          onChange={value => this.store.setTag(value)}
                          options={this.store.tagsList}
                          isMulti={true}
                        />
                        <span className="req-validation ">
                          {this.store.tag_validation
                            ? this.store.tag_validation.msg
                              ? this.store.tag_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.area" />
                          <span className="req"> *</span>
                        </p>
                        <SelectInput
                          className="react-select"
                          classNamePrefix="react-select"
                          value={this.store.area}
                          onChange={value => this.store.setArea(value)}
                          options={this.store.areasList}
                          isMulti={false}
                        />
                        <span className="req-validation ">
                          {this.store.area_validation
                            ? this.store.area_validation.msg
                              ? this.store.area_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.summary" />
                          <span className="req"> *</span>

                        </p>
                        <p className="mb-3">
                          <Input
                            name="summary"
                            value={
                              this.store.enSummary ? this.store.enSummary : ""
                            }
                            type="textarea"
                            onChange={e =>
                              this.store.changeEnSummary(e.target.value)
                            }
                          />
                          <span className="req-validation ">
                            {this.store.summary_validation
                              ? this.store.summary_validation.msg
                                ? this.store.summary_validation.msg
                                : null
                              : null}
                          </span>
                        </p>
                        
                      </Colxx>

                      <Colxx xxs="12" lg="12">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.summary-ar" />
                        </p>
                        <p className="mb-3">
                          <Input
                            name="summary-ar"
                            className="ar"
                            value={
                              this.store.arSummary ? this.store.arSummary : ""
                            }
                            type="textarea"
                            onChange={e =>
                              this.store.changeArSummary(e.target.value)
                            }
                          />
                        </p>
                      </Colxx>
                    </CardBody>
                  </Card>
                </Colxx>

                <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                  <Card className="mb-2 h-100">
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`English Content`}
                        </span>

                        {this.store.content_validation ? <br /> : null}
                        <span className="req-validation ">
                          {this.store.content_validation
                            ? this.store.content_validation.msg
                              ? this.store.content_validation.msg
                              : null
                            : null}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <SunEditor
                        onChange={data =>
                          this.store.handleChangeEnContent(data)
                        }
                        setOptions={{
                          placeholder: "Please type here...",
                          autoFocus: false,
                          showPathLabel: true,
                          charCounter: true,
                          // maxCharCount: 720,
                          width: "100%",
                          height: "auto",
                          minHeight: "200px",
                          buttonList: [
                            ["undo", "redo", "font", "fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                              "removeFormat"
                            ],
                            [
                              "fontColor",
                              "hiliteColor",
                              "outdent",
                              "indent",
                              "align",
                              "horizontalRule",
                              "list",
                              "table"
                            ],
                            [
                              "link",
                              "image",
                              "video",
                              "fullScreen",
                              "showBlocks",
                              "codeView",
                              "preview",
                              "print",
                              "save"
                            ]
                          ]
                        }}
                      />
                    </CardBody>
                  </Card>
                </Colxx>

                <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                  <Card className="mb-2 h-100">
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`Arabic Content`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <SunEditor
                        onChange={data =>
                          this.store.handleChangeArContent(data)
                        }
                        setOptions={{
                          placeholder: "Please type here...",
                          autoFocus: false,
                          showPathLabel: true,
                          charCounter: true,
                          // maxCharCount: 720,
                          width: "100%",
                          height: "auto",
                          minHeight: "200px",
                          buttonList: [
                            ["undo", "redo", "font", "fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                              "removeFormat"
                            ],
                            [
                              "fontColor",
                              "hiliteColor",
                              "outdent",
                              "indent",
                              "align",
                              "horizontalRule",
                              "list",
                              "table"
                            ],
                            [
                              "link",
                              "image",
                              "video",
                              "fullScreen",
                              "showBlocks",
                              "codeView",
                              "preview",
                              "print",
                              "save"
                            ]
                          ]
                        }}
                      />
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </Colxx>
          </>
        )}
      </Fragment>
    );
  }
}

export default AddPost;
