import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import DisplayOption from '../../../components/DisolayOption'
import CardImageList from '../../../components/Card/cardImageList'
import Pagination from '../../../components/List/Pagination'

@inject('stores')
@observer
class BlogList extends Component {
  @observable
  store = this.props.stores.blog

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.blog" />}
                  match={this.props.match}
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => this.props.history.push('/app/blog/add')}
                >
                  <IntlMessages id="pages.add-new" />
                </Button>
                <Row className="col-12 justify-content-end p-0 m-0 flex-wrap align-items-center">
                  <div className="col-md-6 col-lg-6 col-md-6 col-sm-12">
                    <DisplayOption />
                  </div>
                  <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0">
                    <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                      <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                        <input
                          type="text"
                          name="keyword"
                          id="search"
                          value={this.store.search}
                          placeholder={`Search`}
                          onChange={(e) =>
                            this.store.hendleChangeKey(e.target.value)
                          }
                          onKeyPress={(event) =>
                            this.store.handleKeyPress(event)
                          }
                        />
                      </div>
                    </div>{' '}
                  </div>{' '}
                </Row>
                <Separator className="mb-5" />
              </Colxx>
            </Row>

            <Row>
              <Colxx xxs="12">
                {this.setting.displayMode === 'thumblist' ? (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((brew) => (
                          <CardThumbList key={brew.id} data={brew} path="blog/detail" />
                        ))
                      : null}
                  </Row>
                ) : (
                  <Row>
                    {this.store.data
                      ? this.store.data.map((brew) => (
                          <CardImageList key={brew.id} data={brew} path="blog/detail" />
                        ))
                      : null}
                  </Row>
                )}
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default BlogList
