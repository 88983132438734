import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class FeaturetteDetailStore {
  @observable
  featuretteDetail = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 'all'

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  imageFileEn = null

  @observable
  imageFileAr = null

  @observable
  typeList = [
    { value: 1, label: 'category' },
    { value: 2, label: 'product' },
    { value: 3, label: 'event' },
    { value: 4, label: 'blog' },
    { value: 5, label: 'memory' },
    { value: 6, label: 'brand' },
    { value: 7, label: 'subscribe' },
  ]

  @observable
  selectedType = null

  @observable
  itemList = null

  @observable
  productsList = []

  @observable
  subscribesList = []

  @observable
  eventsList = []

  @observable
  blogsList = []

  @observable
  brandsList = []

  @observable
  memoriesList = []

  @observable
  categoriesList = []

  @observable
  selectedItem = null

  @observable
  selectedBrand = null

  @observable
  response = null

  @observable
  en_title_validation = null

  @observable
  ar_title_validation = null

  @observable
  en_description_validation = null

  @observable
  ar_description_validation = null

  @observable
  type_validation = null

  @observable
  item_validation = null

  @observable
  brand_validation = null

  @observable
  showUploaderEnglish = true

  @observable
  showUploaderArabic = true

  @action
  handleImageUploaderEnglish = () => {
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  handleImageUploaderArabic = () => {
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderEnglish(file) {
    this.imageFileEn = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderEnglish = !this.showUploaderEnglish
  }


  @action
  async changeImageFileUploderArabic(file) {
    this.imageFileAr = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  async validation(id, history) {
    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.featuretteDetail.description_en,
      require: true,
      type: 'string',
      min: 20,
    })

    this.ar_description_validation = await checkInput({
      name: 'Arabic Description',
      value: this.featuretteDetail.description_ar,
      require: true,
      type: 'string',
      min: 20,
    })
    this.en_title_validation = await checkInput({
      name: 'English Title',
      value: this.featuretteDetail.title_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_title_validation = await checkInput({
      name: 'Arabic Title',
      value: this.featuretteDetail.title_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType ? this.selectedType : null,
      require: true,
    })

    this.item_validation = await checkInput({
      name: 'Item',
      value: this.selectedItem ? this.selectedItem : null,
      require: true,
    })

    if (this.selectedType && this.selectedType.label === 'product') {
      this.brand_validation = await checkInput({
        name: 'Brand',
        value:this.selectedBrand? this.selectedBrand: null,
        require: true,
      })
    }

    if (this.selectedType && this.selectedType.label === 'product') {
      this.type_validation.res &&
        this.brand_validation.res &&
        this.item_validation.res &&
        this.en_title_validation.res &&
        this.ar_title_validation.res &&
        this.en_description_validation.res &&
        this.ar_description_validation.res &&
        this.handleUpdateFeaturette(id, history)
    } else {
      this.type_validation.res &&
        this.item_validation.res &&
        this.en_title_validation.res &&
        this.ar_title_validation.res &&
        this.en_description_validation.res &&
        this.ar_description_validation.res &&
        this.handleUpdateFeaturette(id, history)
    }
  }

  @action
  async getFeaturetteDetail(id, history) {
    this.isLoading = true
    this.imageFile = null
    const value = await axiFetch.get('featurette', null, id, history)
    if (value) {
      this.featuretteDetail = value.data
      this.selectedType = this.typeList.find(
        (item) => item.label === value.data.target
      )
      await this.getDependencies(history)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {

    const dependencies = await axiFetch.get(
      'featurette',
      null,
      'dependencies/get',
      history
    )

    if (dependencies) {
      console.log(dependencies.data.Product)
      if (dependencies.data.Product) {
        await dependencies.data.Product.forEach((item) => {
          this.productsList.push({ value: item.id, label: item.name })
        })
      }

      if (dependencies.data.Brand) {
        await dependencies.data.Brand.forEach((item) => {
          this.brandsList.push({ value: item.id, label: item.name_en })
        })
      }

      if (dependencies.data.Category) {
        await dependencies.data.Category.forEach((item) => {
          this.categoriesList.push({ value: item.id, label: item.name_en })
        })
      }

      if (dependencies.data.Blog) {
        await dependencies.data.Blog.forEach((item) => {
          this.blogsList.push({ value: item.id, label: item.title })
        })
      }

      if (dependencies.data.Memory) {
        await dependencies.data.Memory.forEach((item) => {
          this.memoriesList.push({ value: item.id, label: item.title })
        })
      }
      if (dependencies.data.Event) {
        await dependencies.data.Event.forEach((item) => {
          this.eventsList.push({ value: item.id, label: item.title })
        })
      }

      if (dependencies.data.Subscribe) {
        await dependencies.data.Subscribe.forEach((item) => {
          this.subscribesList.push({ value: item.id, label: item.name })
        })
      }
    }
    this.itemList = {
      category: this.categoriesList,
      event: this.eventsList,
      blog: this.blogsList,
      product: this.productsList,
      memory: this.memoriesList,
      brand: this.brandsList,
      subscribe : this.subscribesList
    }

    this.selectedBrand =
      this.featuretteDetail.brand_id &&
      this.brandsList.find(
        (item) => item.value === this.featuretteDetail.brand_id
      )

    this.selectedItem = this.itemList[this.selectedType.label].find(
      (item) => Number(item.value) === Number(this.featuretteDetail.value)
    )
  }

  @action
  deleteFeaturette = (id, history) => {
    this.isLoading = true
    axios
      .get(`${servicePath}/featurette/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        this.reset()
        history.push('/app/slider')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
        this.openDeleteModal = false
      })
  }

  @action
  async handleUpdateFeaturette(id, history) {
    this.isLoading = true
    let data = {
      target: this.selectedType.label,
      value: this.selectedItem.value,
      description_en: this.featuretteDetail.description_en,
      description_ar: this.featuretteDetail.description_ar,
      title_en: this.featuretteDetail.title_en,
      title_ar: this.featuretteDetail.title_ar,
      is_active: this.featuretteDetail.is_active ? 1 : 0,
    }

    if (this.selectedType.label === 'product') {
      data.brand_id = this.selectedBrand.value
    }
    if (this.imageFileEn) {
      data.image_en = this.imageFileEn
    }

    if (this.imageFileAr) {
      data.image_ar = this.imageFileAr
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    axios
      .post(`${servicePath}/featurette/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(() => {
        this.reset()
        this.getFeaturetteDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFileEn = (file) => {
    this.imageFileEn = file
  }

  @action
  changeImageFileAr = (file) => {
    this.imageFileAr = file
  }

  @action
  changeTitleEn = (value) => {
    this.featuretteDetail.title_en = value
  }

  @action
  changeTitleAr = (value) => {
    this.featuretteDetail.title_ar = value
  }

  @action
  changeDescriptionEn = (value) => {
    this.featuretteDetail.description_en = value
  }

  @action
  changeDescriptionAr = (value) => {
    this.featuretteDetail.description_ar = value
  }

  @action
  setType(data) {
    this.selectedType = data.selectedOption
    this.selectedBrand = null
    this.selectedItem = null
  }

  @action
  setItem(data) {
    this.selectedItem = data.selectedOption
  }

  @action
  setBrand(data) {
    this.selectedBrand = data.selectedOption
  }
  @action
  handleIsSupported() {
    this.featuretteDetail.is_active = this.featuretteDetail.is_active ? 0 : 1
  }

  @action
  reset() {
  this.featuretteDetail = null

  this.search = null

  this.searchOn = null

  this.limit = 'all'

  this.offset = 0

  this.order = { id: -1 }

  this.filter = {}

  this.isLoading = true

  this.openDeleteModal = false

  this.imageFileEn = null

  this.imageFileAr = null

  this.typeList = [
    { value: 1, label: 'category' },
    { value: 2, label: 'product' },
    { value: 3, label: 'event' },
    { value: 4, label: 'blog' },
    { value: 5, label: 'memory' },
    { value: 6, label: 'brand' },
    { value: 7, label: 'subscribe' },
  ]

  this.selectedType = null

  this.itemList = null

  this.productsList = []

  this.subscribesList = []

  this.eventsList = []

  this.blogsList = []

  this.brandsList = []

  this.memoriesList = []

  this.categoriesList = []

  this.selectedItem = null

  this.selectedBrand = null

  this.response = null

  this.en_title_validation = null

  this.ar_title_validation = null

  this.en_description_validation = null

  this.ar_description_validation = null

  this.type_validation = null

  this.item_validation = null

  this.brand_validation = null

  this.showUploaderEnglish = true

  this.showUploaderArabic = true

  }
}
