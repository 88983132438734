import { isString } from 'util'

export const emailValidation = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(String(email))) {
    return true
  }
  return false
}

export const checkInputType = (value, type) => {
  switch (type) {
    case 'string':
      return isString(value)
    case 'number':
      return !isNaN(parseFloat(value))
    // return !isNaN(parseFloat(value));
    case 'email':
      return emailValidation(value)
    default:
      break
  }
}

export const checkInputMaxLength = (value, length) => {
  if (value.length < length + 1) {
    return true
  } else {
    return false
  }
}

export const checkInputMinLength = (value, length) => {
  if (value.length < length) {
    return false
  } else {
    return true
  }
}

export const checkInputMinValue = (value, min) => {
  if (parseFloat(value) < min) {
    return false
  } else {
    return true
  }
}

export const checkInputMaxValue = (value, max) => {
  if (parseFloat(value) > max) {
    return false
  } else {
    return true
  }
}

export const checkInputRequire = (value) => {
  if (value) {
    return true
  } else {
    return false
  }
}

export const checkInput = async (value) => {
  let require
  let min_length
  let max_length
  let type
  let min_value
  let max_value
  if (value.require) {
    require = await checkInputRequire(value.value)
    if (!require) {
      return { res: false, msg: `${value.name}  is required !` }
    }
  }

  if (value.min) {
    min_length = await checkInputMinLength(value.value, value.min)
    if (!min_length) {
      return {
        res: false,
        msg: `The ${value.name} must be of minimum length ${value.min} characters !`,
      }
    }
  }

  if (value.max) {
    max_length = await checkInputMaxLength(value.value, value.max)
    if (!max_length) {
      return {
        res: false,
        msg: `The ${value.name} must be of maximum length ${value.max} characters !`,
      }
    }
  }

  if (value.type) {
    type = await checkInputType(value.value, value.type)
    if (!type && value.type !== 'email') {
      return { res: false, msg: `${value.name} must be a ${value.type}!` }
    } else if (!type && value.type === 'email') {
      return { res: false, msg: 'You have entered an invalid email address!' }
    }
  }

  if (value.minValue) {
    min_value = await checkInputMinValue(value.value, value.minValue)
    if (!min_value) {
      return {
        res: false,
        msg: `${value.name} must be bigger than ${value.minValue}!`,
      }
    }
  }
  if (value.maxValue) {
    max_value = await checkInputMaxValue(value.value, value.maxValue)
    if (!max_value) {
      return {
        res: false,
        msg: `${value.name} must be Smaller than ${value.maxValue}!`,
      }
    }
  }

  return { res: true }
}
