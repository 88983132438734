import React from 'react'

export class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.file || typeof nextProps.file.name !== 'string') {
      return
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader()

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result })
      }
      reader.readAsDataURL(nextProps.file)
    })
  }

  render() {
    let { file, src, className } = this.props
    const { imageWidth, imageLeft, imageTop,imageHeight } = this.props.imageSize
      ? this.props.imageSize
      : {}
    let { thumb } = this.state
    let alt = ''

    if (!file && !src) return null
    if (file && typeof file.name == 'string') {
      alt = file.name
    } else {
      alt = 'just a preview image'
      thumb = src
    }
    className = 'img-thumbnail mb-2 ' + className

    return (
      <div
        className="position-relative mb-2"
        style={{ width: '100%', paddingTop: '80%' }}
      >
        <img
          src={thumb}
          alt={alt}
          className={className}
          style={{
            objectFit: 'cover',
            borderRadius: '0.75rem',
            height:imageHeight?imageHeight: '100%',
            width: imageWidth ? imageWidth : '100%',
            top: imageTop ? imageTop : 0,
            left: imageLeft ? imageLeft : 0,
            position: 'absolute',
          }}
          key="cat-thumb"
        />
      </div>
    )
  }
}
