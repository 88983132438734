import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import DeleteModal from "../../../components/Modal/deleteModal";
import { ToastContainer, toast } from "react-toastify";


@inject("stores")
@observer
class TagDetail extends Component {
  @observable
  store = this.props.stores.tag;
  constructor(props) {
    super(props);
    this.state = { id: null, imageFile: null };
  }
  componentDidMount() {
    let path = this.props.match.params.id;
    this.setState({ id: path });
    this.store.getTagDetail(path, this.props.history);
  }

  componentWillUnmount() {
    this.store.reset();
  }

  notify = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      default:
        break;
    }
  };

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type);
      }
    }
    return (
      <Fragment>
         <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.tag" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={
                  this.store.tagDetail
                    ? this.store.tagDetail.name_en
                    : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteTag(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => this.store.updaateValidation(this.state.id , this.props.history)}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: "25px" }}
                >
                  <Row>
                    
                    <Colxx xxs="12" sm="4" lg="4" className="mb-8">
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                             {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.tag-en" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-2">
                                <Input
                                  name="name"
                                  value={
                                    this.store.tagDetail
                                      ? this.store.tagDetail.name_en
                                      : ""
                                  }
                                  type="text"
                                  onChange={e =>
                                    this.store.changeEnTag(e.target.value)
                                  }
                                />
                              </p>
                              <span className="req-validation mb-2">
                              {this.store.en_tag_validation
                                ? this.store.en_tag_validation.msg
                                  ? this.store.en_tag_validation.msg
                                  : null
                                : null}
                            </span>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.tag-ar" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-2">
                                <Input
                                  name="name_ar"
                                  value={
                                    this.store.tagDetail
                                      ? this.store.tagDetail.name_ar
                                      : ""
                                  }
                                  type="text"
                                  onChange={e =>
                                    this.store.changeArTag(e.target.value)
                                  }
                                  className="ar"
                                />
                              </p>
                              <span className="req-validation mb-2">
                              {this.store.ar_tag_validation
                                ? this.store.ar_tag_validation.msg
                                  ? this.store.ar_tag_validation.msg
                                  : null
                                : null}
                            </span>
                            </Colxx>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default TagDetail;
