import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
// import { checkInput } from "../constants/validation";
// import axiFetch from "../config/fetch";

export default class OurStoryStore {
  @observable
  data = null

  @observable
  startImageFile = null

  @observable
  nowImageFile = null

  @observable
  futureImageFile = null

  @observable
  CoverImageFile = null

  @observable
  isLoading = false

  @observable
  response = null

  @observable
  showUploaderEnglish = true

  @observable
  showUploaderArabic = true

  @action
  handleImageUploaderEnglish = () => {
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  handleImageUploaderArabic = () => {
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderEnglish(file) {
    this.coverImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  async changeImageFileUploderArabic(file) {
    this.imageFileAr = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  getData = async (history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/story/get`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data

        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  handleUpdateOurStory = (history) => {
    this.isLoading = false

    const data = {
      en: JSON.stringify(this.data.en),
      ar: JSON.stringify(this.data.ar),
    }

    if (this.coverImageFile) {
      data.cover = this.coverImageFile
    }
    if (this.nowImageFile) {
      data.now = this.nowImageFile
    }
    if (this.startImageFile) {
      data.start = this.startImageFile
    }

    if (this.futureImageFile) {
      data.future = this.futureImageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/story`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.response = { msg: 'Item has been updated successfully', type: 'success' }
        this.isLoading = true
        this.response = null
        this.reset()
        this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeStartImageFile = (file) => {
    this.startImageFile = file
  }

  @action
  changeNowImageFile = (file) => {
    this.nowImageFile = file
  }
  @action
  changeFutureImageFile = (file) => {
    this.futureImageFile = file
  }
  @action
  changeCoverImageFile = (file) => {
    this.coverImageFile = file
  }

  @action
  changeDescription = (lang, position, value) => {
    this.data[lang][position].content = value
  }

  @action
  reset() {
    this.data = null
  
    this.startImageFile = null
  
    this.nowImageFile = null
  
    this.futureImageFile = null
  
    this.CoverImageFile = null
  
    this.isLoading = false
  
    this.response = null
  
    this.showUploaderEnglish = true
  
    this.showUploaderArabic = true
  }
}
