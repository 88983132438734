import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CustomerList from './list';
import CustomerDetail from './detail';



const Customers = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={CustomerList} />
            <Route path={`${match.url}/detail/:id`} component={CustomerDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Customers;