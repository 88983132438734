import React, { Component, Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import {inject, observer} from 'mobx-react';
import {observable} from "mobx"

import { Colxx } from "../../components/CustomBootstrap";

// import { connect } from "react-redux";
// import { registerUser } from "Redux/actions";
@inject("stores")
@observer
class RegisterLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: ""
    };
  }
  @observable
  store = this.props.stores.login
  onUserRegister() {
    if (this.state.email !== "" && this.state.password !== "") {
      this.store.registerUser(this.state, this.props.history);
    //   this.props.history.push("/");
    }
  }

  handleEmail=(email)=>{
    this.setState({email})
  }

  handleName=(name)=>{
      this.setState({name})
  }

  handlePassword=(password)=>{
      this.setState({password})
  }

  componentDidMount() {
    document.body.classList.add("background");
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }
  render() {
    return (
      <Fragment>
        <div className="fixed-background" />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="position-relative image-side ">
                    <p className="text-white h2">MAGIC IS IN THE DETAILS</p>
                    <p className="white mb-0">
                      Please use this form to register. <br />
                      If you are a member, please{" "}
                      <NavLink to={`/login`} className="white">
                        login
                      </NavLink>
                      .
                    </p>
                  </div>
                  <div className="form-side">
                    <NavLink to={`/`} className="white">
                      <span className="logo-single" />
                    </NavLink>
                    <CardTitle className="mb-4">
                      <IntlMessages id="user.register" />
                    </CardTitle>
                    <Form>
                      <Label className="form-group has-float-label mb-4">
                        <Input type="name" value={this.state.name}  onChange={(e)=>this.handleName(e.target.value)}/>
                        <IntlMessages id="user.fullname" />
                      </Label>
                      <Label className="form-group has-float-label mb-4">
                        <Input type="email" value={this.state.email}  onChange={(e)=>this.handleEmail(e.target.value)}/>
                        <IntlMessages id="user.email" />
                      </Label>
                      <Label className="form-group has-float-label mb-4">
                        <Input type="password" value={this.state.password} onChange={(e)=>this.handlePassword(e.target.value)}/>
                        <IntlMessages
                          id="user.password"
                        />
                      </Label>
                      <div className="d-flex justify-content-end align-items-center">
                        <Button
                          color="primary"
                          className="btn-shadow"
                          size="lg"
                          onClick={() => this.onUserRegister()}
                        >
                          <IntlMessages id="user.register-button" />
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    );
  }
}


export default RegisterLayout;
