import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class CustomerDetailStore {
  @observable
  customerDetail = null

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 10

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openDeleteModal = false

  @observable
  response = null

  @action
  getCustomerDetail = (id, history) => {
    this.isLoading = true
    this.imageFile = null
    axios
      .get(`${servicePath}/user/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.customerDetail = data.data
        this.isLoading = false
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  deleteCustomer = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/user/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        history.push('/app/customer')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.openDeleteModal = false
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action.bound
  changeEnName = (name) => {
    this.customerDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.customerDetail.name_ar = name
  }

  @action
  reset() {
    this.customerDetail = null

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 10

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openDeleteModal = false
  }
}
