import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";

import { defaultStartPath } from "../constants/defaultValues";
import MainRoute from "../routes";
import login from "../routes/pages/login";
import error from "../routes/pages/error";
import forgotPassword from "../routes/pages/forgot-password";
import resetPassword from "../routes/pages/reset-password";
import register from "../routes/pages/register";
import AppLocale from "../lang/index";

import "../assets/css/vendor/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";

const InitialPath = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

@inject("stores")
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  @observable
  store = this.props.stores.login;
  @observable
  setting = this.props.stores.setting;

  componentDidMount() {
    this.setting.setLocale();
    this.store.checkUserLogin(this.props.history);
  }

  render() {
    const { location, match } = this.props;
    const user = window.localStorage.getItem("user_email") ? true : false;
    const locale = this.setting.locale
      ? this.setting.locale
      : this.setting.defaultLocale;
    const currentAppLocale = AppLocale[locale];
    if (
      location.pathname === "/" ||
      location.pathname === "/app" ||
      location.pathname === "/app/"
    ) {
      return <Redirect to={defaultStartPath} />;
    }
    return (
      <Fragment>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Fragment>
            <Switch>
              <InitialPath
                path={`${match.url}app`}
                authUser={user}
                component={MainRoute}
              />
              <Route path={`/login`} component={login} />
              <Route path={`/register`} component={register} />
              <Route path={`/forgot-password`} component={forgotPassword} />
              <Route path={`/reset-password`} component={resetPassword} />
              <Route path={`/error`} component={error} />
              <Redirect to="/error" />
            </Switch>
          </Fragment>
        </IntlProvider>
      </Fragment>
    );
  }
}

export default App;
