export const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
      var A = a[key], B = b[key];
      if (order.indexOf(A + "") > order.indexOf(B + "")) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  };
  
  
  export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
  
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '.' + mm + '.' + yyyy;
  }
  
  export const getCurrentTime=()=>{
    const now = new Date();
    return now.getHours() + ":" + now.getMinutes()
  }
  
  export const addCommas = (nStr) => {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1 , $2");
    }
    return x1 + x2;
  }