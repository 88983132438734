import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  //  Card, CardBody, CardText, Badge
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
// import CardThumbList from '../../../components/Card/cardThumblist'
import DisplayOption from '../../../components/DisolayOption'
// import CardImageList from '../../../components/Card/cardImageList'
import Pagination from '../../../components/List/Pagination'
import MenuModal from '../../../components/Modal/menuModal'
import { ToastContainer, toast } from 'react-toastify'
// import { NavLink } from 'react-router-dom'
import Sort from '../../../components/Sort/index'

@inject('stores')
@observer
class MenuList extends Component {
  @observable
  store = this.props.stores.dynamicMenu

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />

        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="d-flex align-items-center">
                <Colxx xxs="8">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.menu" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx xxs="4" className="float-right">
                  <Button
                    color="primary"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() => this.store.changeOpenModal()}
                  >
                    <IntlMessages id="pages.add-new" />
                  </Button>
                  <MenuModal
                    openModal={this.store.openModal}
                    toggleModal={() => this.store.toggleModal()}
                    name_en={this.store.name_en}
                    name_ar={this.store.name_ar}
                    email={this.store.email}
                    changeEnName={(data) => this.store.changeNameEn(data)}
                    changeArName={(data) => this.store.changeNameAr(data)}
                    url={this.store.url}
                    urlList={this.store.urlList}
                    setUrl={(value) => this.store.setUrl(value)}
                    parent={this.store.parent}
                    parentList={this.store.parentList}
                    setParent={(value) => this.store.setParent(value)}
                    handleSave={() => this.store.validation(this.props.history)}
                    btnDisable={this.store.btnDisable}
                    name_en_validation={this.store.name_en_validation}
                    name_ar_validation={this.store.name_ar_validation}
                    url_validation={this.store.url_validation}
                  ></MenuModal>
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <DisplayOption />
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Row>

            <Row>
              {this.store.data ? (
                <Sort
                  data={this.store.data}
                  action={(value) =>
                    this.store.changeOrder(value, this.props.history)
                  }
                />
              ) : null}
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default MenuList
