import React from 'react'
import Slim from './slim.react'
import { Button, Label } from 'reactstrap'

class ImageUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validation: null,
      slimNumber: 1,
      images: [],
      imagesWithId: [],
      imagesWithoutId: [],
      slimWidth: this.props.size ? this.props.size.width : 800,
      slimHeight: this.props.size ? this.props.size.height : 800,
      ratio: this.props.ratio ? this.props.ratio : '1:1',
      getImage: false,
    }
    this.instance = []
  }
  async getInfo() {
    // this.setState({validation: null})
    let images = await this.fillImagesInfo()
    if (images && images[0] && images[0].imagePath) {
      this.props.changeImageFile(images[0].imagePath)
    } else {
      this.props.changeImageFile(null)
      this.setState({ validation: { res: true, msg: 'Image Is Required' } })
    }
    // return this.fillImagesInfo();
  }
  fillImagesInfo() {
    let images = this.instance
      .map((value, index) => {
        if (!value) return {}
        let image = value.getImage()
        image.imageOrder = index
        return image
      })
      .filter((value) => value.imagePath)
    let imagesWithId = this.state.images.filter((value) => value.id)
    if (imagesWithId.length) {
      images.push(...imagesWithId)
    }

    return images
  }
  componentDidMount() {
    let images = []
    if (Array.isArray(this.props.images) && this.props.images.length > 0) {
      images = this.props.images
    } else {
      images.push({ id: undefined, imagePath: undefined, imageOrder: 0 })
    }
    images.sort((a, b) => {
      return a.imageOrder - b.imageOrder
    })

    this.setState({ images: images })
  }
  componentDidUpdate() {
    this.loadSlims(this.state.images)
  }
  loadSlims(images) {
    this.instance.forEach((value, index) => {
      if (value && images[index] && images[index].imagePath) {
        alert(index)
        value.load(images[index].id, images[index].imagePath)
      }
    })
  }
  onAdd() {
    let imageOrder = 0
    if (this.state.images.length) {
      imageOrder = this.state.images.slice(-1)[0].imageOrder + 1
    }
    this.state.images.push({ id: undefined, imagePath: undefined, imageOrder })
    this.setState({ images: this.state.images })
    // this.setState({ slimNumber: ++this.state.slimNumber })
  }
  changeImageOrder(target_id, prevOrder, newOrder) {
    let images = this.fillImagesInfo()
    if (prevOrder === 0 && newOrder < prevOrder) return
    if (images.length === 1) return
    let itemWithNewOrder = images.filter(
      (value) => value.imageOrder === newOrder
    )
    if (
      itemWithNewOrder.length === 0 ||
      itemWithNewOrder[0].imageOrder < newOrder
    )
      return
    let target = images.filter((value) => value.imageOrder === prevOrder)
    target.forEach((value) => {
      value.imageOrder = newOrder
    })
    itemWithNewOrder.forEach((value) => {
      value.imageOrder = prevOrder
    })
    images.sort((a, b) => {
      return a.imageOrder - b.imageOrder
    })
    this.setState({ images })
  }
  onImageDelete(value, index) {
    if (value.id) {
      value.deleting = true
      this.setState({ images: this.state.images })
      this.props.removeImage(value.target_id, value.id, () => {
        this.setState({
          images: this.state.images.filter((img) => img.id !== value.id),
        })
      })
    } else {
      this.setState({
        images: this.state.images.filter(
          (img) => img.imageOrder !== value.imageOrder
        ),
      })
    }
  }

  renderOldImage(value) {
    return (
      <div
        className="position-relative"
        style={{
          width: '100%',
          paddingTop: this.props.ratio === '1:1' ? '100%' : '50%',
        }}
      >
        <img
          alt="cake"
          className="position-absolute"
          style={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            objectFit: 'cover',
          }}
          key={'image' + value.id}
          src={value.imagePath}
        />
      </div>
    )
  }
  renderSlim(index) {
    return (
      <div style={{ borderRadius: '0.75rem', overflow: 'hidden' }}>
        <Slim
          ref={(instance) => {
            this.instance[index] = instance
          }}
          key={index}
          label={`Drop your image here! ${this.state.slimWidth} x ${this.state.slimHeight} <br/><br/> ( Ratio = ${this.props.ratio} )`}
          ratio={this.state.ratio}
          size={{ width: this.state.slimWidth, height: this.state.slimHeight }}
          minSize={{
            width: this.state.slimWidth,
            height: this.state.slimHeight,
          }}
          dataJpegCompression={100}
          dataForceType="jpg"
        />
      </div>
    )
  }
  renderImage(value, index) {
    let imageClass = 'col-12'

    return (
      <div
        key={index}
        className="row d-flex align-items-center justify-content-center"
        style={{ minHeight: '145px' }}
      >
        <div className={imageClass}>
          {value.id
            ? this.renderOldImage(value)
            : this.renderSlim(value.imageOrder)}
        </div>
      </div>
    )
  }
  renderImages() {
    return this.state.images.map((value, index) =>
      this.renderImage(value, index)
    )
  }
  renderButton() {
    return (
      <div className="d-flex mb-4">
        {this.props.flag ? (
          <Button
            color="btn-outline-secondary"
            size="lg"
            className="pointer col-12 btn-shadow"
            style={{}}
            onClick={() => this.props.handleImageUploader()}
          >
            <Label
              className="btn btn-default no-padding col-12"
              style={{
                padding: '0px',
                marginBottom: '0px',
              }}
            >
              <strong>Cancel</strong>
            </Label>
          </Button>
        ) : (
          <>
            <Button
              color="btn-outline-secondary"
              size="lg"
              className="pointer mr-1 col-6 btn-shadow"
              style={{}}
              onClick={() => this.props.handleImageUploader()}
            >
              <Label
                className="btn btn-default no-padding col-12"
                style={{
                  padding: '0px',
                  marginBottom: '0px',
                }}
              >
                <strong>Cancel</strong>
              </Label>
            </Button>
            <Button
              color="primary"
              size="lg"
              className="pointer col-6 btn-shadow ml-1"
              style={{}}
              onClick={() => this.getInfo()}
            >
              <Label
                className="btn btn-default no-padding col-12"
                style={{
                  padding: '0px',
                  marginBottom: '0px',
                }}
              >
                <strong>Save Image</strong>
              </Label>
            </Button>
          </>
        )}
      </div>
    )
  }
  render() {
    if (this.props.getImage) {
      this.getInfo()
    }
    return (
      <div className="container p-0">
        {this.props.multiple && this.renderButton()}
        {this.renderImages()}
        <span className="req-validation ">
          {this.props.multiple && this.state.validation
            ? this.state.validation.msg
              ? this.state.validation.msg
              : null
            : null}
        </span>
      </div>
    )
  }
}

export default ImageUploader
