import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  // CardTitle,
  // Label,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
// import { Thumb } from '../../../components/Thumb'
import { ToastContainer, toast } from 'react-toastify'
import SelectInput from '../../../components/select'
import ImageUploader from '../../../components/Uploader/Uploader'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'

@inject('stores')
@observer
class AddProduct extends Component {
  @observable
  store = this.props.stores.addProduct
  constructor(props) {
    super(props)
    this.state = { selectedOption: null, getImage: false }
  }
  componentDidMount() {
    this.store.getDependencies(this.props.history)
    this.store.generateSkuNumber()
  }
  componentWillUnmount() {
    this.store.resetData()
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFile(data)
    } else {
      this.setState({ getImage: false })
      await this.store.changeImageFile(null)
    }
    this.store.validation(this.props.history)
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (!this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />

        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.product" />}
                match={this.props.match}
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => this.handleSave()}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <Card className="mb-12">
                    <CardBody>
                      <Row>
                        <Colxx xxs="12" sm="4" lg="3" className="mb-4">
                          <ImageUploader
                            multiple={false}
                            ref={(instance) => (this.AddProduct = instance)}
                            images={this.state.imageFile}
                            ratio="1:1"
                            size={{ width: 1000, height: 1000 }}
                            getImage={this.state.getImage}
                            changeImageFile={(data) => {
                              this.changeImageFile(data)
                            }}
                          />
                          <span className="req-validation ">
                            {this.store.image_validation
                              ? this.store.image_validation.msg
                                ? this.store.image_validation.msg
                                : null
                              : null}
                          </span>
                        </Colxx>
                        <Colxx
                          xxs="12"
                          sm="8"
                          lg="9"
                          className="mb-8  d-flex flex-wrap"
                          style={{ height: 'max-content' }}
                        >
                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.enName ? this.store.enName : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.arName ? this.store.arName : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              Is Subscribe
                            </p>
                            <div className="mb-0">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse"
                                name={'is_default'}
                                value={this.store.is_subscribe}
                                onChange={() =>
                                  this.store.changeSubscribeSwitch()
                                }
                                // onBlur={handleBlur}
                              />
                            </div>
                          </Colxx>

                          {this.store.is_subscribe && (
                            <div
                              className="col-12 p-0 pt-3 d-flex flex-wrap"
                              style={{ borderRadius: 8 }}
                            >
                              {this.store.subscribes.map((item, index) => (
                                <div
                                  className="col-12 border p-0 pt-3 d-flex flex-wrap mb-3 position-relative"
                                  key={index}
                                  style={{ borderRadius: 8 }}
                                >
                                  <Button
                                    onClick={() =>
                                      this.store.removeSubscribe(index)
                                    }
                                    color="primary"
                                    style={{
                                      position: 'absolute',
                                      width: '30px',
                                      height: '30px',
                                      borderRadius: '50%',
                                      padding: '0',
                                      right: '5px',
                                      top: '5px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      zIndex: 1,
                                    }}
                                  >
                                    <i
                                      style={{ fontSize: '20px' }}
                                      className="iconsminds-close"
                                    />
                                  </Button>
                                  <Colxx xxs="12" lg="6" className="mb-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.weight" />
                                      <span className="req"> *</span>
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="weight"
                                        value={item.weight ? item.weight : ''}
                                        type="number"
                                        min="0"
                                        onChange={(e) =>
                                          this.store.changeSubscribeValue(
                                            index,
                                            'weight',
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>

                                  {/* <Colxx xxs="12" lg="6" className="mb-3">
                                    <p className="text-muted text-small mb-2">
                                      Interval
                                      <span className="req"> *</span>
                                    </p>

                                    <SelectInput
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      value={
                                        item.interval ? item.interval : null
                                      }
                                      onChange={(value) =>
                                        this.store.changeSubscribeValue(
                                          index,
                                          'interval',
                                          value.selectedOption
                                        )
                                      }
                                      options={this.store.intervalList}
                                      isMulti={false}
                                    />
                                  </Colxx> */}

                                  <Colxx xxs="12" lg="6" className="mb-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.price" />
                                      <span className="req"> *</span>
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="price"
                                        value={item.price ? item.price : ''}
                                        type="number"
                                        min="0"
                                        onChange={(e) =>
                                          this.store.changeSubscribeValue(
                                            index,
                                            'price',
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>

                                  {/* <Colxx xxs="12" lg="6" className="mb-3">
                                    <p className="text-muted text-small mb-2">
                                      Roast
                                      <span className="req"> *</span>
                                    </p>

                                    <SelectInput
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      value={
                                        item.roast ? item.roast : null
                                      }
                                      onChange={(value) =>
                                        this.store.changeSubscribeValue(
                                          index,
                                          'roast',
                                          value.selectedOption
                                        )
                                      }
                                      options={this.store.roastList}
                                      isMulti={false}
                                    />
                                  </Colxx> */}

                                  <Colxx xxs="12" lg="6" className="mb-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.dimensions" />
                                    </p>
                                    <div className="mb-0 d-flex ">
                                      <Colxx xxs="4" className="pl-0 pr-2 ml-0">
                                        <Input
                                          name="width"
                                          value={item.width}
                                          placeholder="Width"
                                          type="number"
                                          min="0"
                                          onChange={(e) =>
                                            this.store.changeSubscribeValue(
                                              index,
                                              'width',
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Colxx>
                                      <Colxx xxs="4" className="pl-1 pr-1 ml-0">
                                        <Input
                                          name="height"
                                          value={item.height}
                                          placeholder="Height"
                                          type="number"
                                          min="0"
                                          onChange={(e) =>
                                            this.store.changeSubscribeValue(
                                              index,
                                              'height',
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Colxx>
                                      <Colxx xxs="4" className="pl-2 ml-0 pr-0">
                                        <Input
                                          name="length"
                                          value={item.length}
                                          placeholder="Length"
                                          type="number"
                                          min="0"
                                          onChange={(e) =>
                                            this.store.changeSubscribeValue(
                                              index,
                                              'length',
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Colxx>
                                    </div>
                                  </Colxx>

                                  <Colxx xxs="12" lg="6" className="mb-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.sku" />
                                      {/* <span className="req"> *</span> */}
                                    </p>
                                    <div className="mb-0">
                                      <Input
                                        name="sku"
                                        value={item.sku ? item.sku : ''}
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeSubscribeValue(
                                            index,
                                            'sku',
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Colxx>
                                </div>
                              ))}
                            </div>
                          )}

                          {this.store.is_subscribe && (
                            <Colxx xxs="12" lg="12" className="mb-3">
                              <Button
                                color="primary"
                                className="btn-shadow float-right"
                                size="lg"
                                onClick={() => this.store.addSubscribe()}
                              >
                                New Subscribe
                              </Button>
                            </Colxx>
                          )}

                          {!this.store.is_subscribe && (
                            <Colxx xxs="12" lg="6" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.price" />
                                <span className="req"> *</span>
                              </p>
                              <div className="mb-0">
                                <Input
                                  name="price"
                                  value={
                                    this.store.price ? this.store.price : ''
                                  }
                                  type="number"
                                  min="0"
                                  onChange={(e) =>
                                    this.store.changePrice(e.target.value)
                                  }
                                />
                              </div>
                              <span className="req-validation ">
                                {this.store.price_validation
                                  ? this.store.price_validation.msg
                                    ? this.store.price_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          )}

                          {!this.store.is_subscribe && (
                            <Colxx xxs="12" lg="6" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.weight" />
                                <span className="req"> *</span>
                              </p>
                              <div className="mb-0">
                                <Input
                                  name="weight"
                                  value={this.store.weight}
                                  type="number"
                                  min="0"
                                  onChange={(e) =>
                                    this.store.changeWeight(e.target.value)
                                  }
                                />
                              </div>
                              <span className="req-validation ">
                                {this.store.weight_validation
                                  ? this.store.weight_validation.msg
                                    ? this.store.weight_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          )}
                          {!this.store.is_subscribe && (
                            <>
                              {' '}
                              <Colxx xxs="12" lg="6" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.dimensions" />
                                </p>
                                <div className="mb-0 d-flex ">
                                  <Colxx xxs="4" className="pl-0 pr-2 ml-0">
                                    <Input
                                      name="width"
                                      value={this.store.width}
                                      placeholder="Width"
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        this.store.changeWidth(e.target.value)
                                      }
                                    />
                                  </Colxx>
                                  <Colxx xxs="4" className="pl-1 pr-1 ml-0">
                                    <Input
                                      name="height"
                                      value={this.store.height}
                                      placeholder="Height"
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        this.store.changeHeight(e.target.value)
                                      }
                                    />
                                  </Colxx>
                                  <Colxx xxs="4" className="pl-2 ml-0 pr-0">
                                    <Input
                                      name="length"
                                      value={this.store.length}
                                      placeholder="Length"
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        this.store.changeLength(e.target.value)
                                      }
                                    />
                                  </Colxx>
                                </div>
                                <span className="req-validation ">
                                  {this.store.width_validation
                                    ? this.store.width_validation.msg
                                      ? this.store.width_validation.msg
                                      : null
                                    : null}
                                </span>
                                <br />
                                <span className="req-validation ">
                                  {this.store.height_validation
                                    ? this.store.height_validation.msg
                                      ? this.store.height_validation.msg
                                      : null
                                    : null}
                                </span>
                                <br />
                                <span className="req-validation ">
                                  {this.store.length_validation
                                    ? this.store.length_validation.msg
                                      ? this.store.length_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                            </>
                          )}
                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.category" />
                              <span className="req"> *</span>
                            </p>

                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={
                                this.store.category ? this.store.category : null
                              }
                              onChange={(value) =>
                                this.store.setCategory(value)
                              }
                              options={this.store.categoriesList}
                              isMulti={false}
                            />
                            <span className="req-validation ">
                              {this.store.category_validation
                                ? this.store.category_validation.msg
                                  ? this.store.category_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.brand" />
                              <span className="req"> *</span>
                            </p>

                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={this.store.brand ? this.store.brand : null}
                              onChange={(value) => this.store.setBrand(value)}
                              options={this.store.brandsList}
                              isMulti={false}
                            />
                            <span className="req-validation ">
                              {this.store.brand_validation
                                ? this.store.brand_validation.msg
                                  ? this.store.brand_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.tag" />
                              <span className="req"> *</span>
                            </p>
                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={this.store.tags}
                              onChange={(value) => this.store.setTag(value)}
                              options={this.store.tagsList}
                              isMulti={true}
                            />
                            <span className="req-validation ">
                              {this.store.tag_validation
                                ? this.store.tag_validation.msg
                                  ? this.store.tag_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          {!this.store.is_subscribe && (
                            <Colxx xxs="12" lg="6" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.sku" />
                                <span className="req"> *</span>
                              </p>
                              <div className="mb-0">
                                <Input
                                  name="name"
                                  value={this.store.sku ? this.store.sku : ''}
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeSku(e.target.value)
                                  }
                                />
                              </div>
                              <span className="req-validation ">
                                {this.store.sku_validation
                                  ? this.store.sku_validation.msg
                                    ? this.store.sku_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          )}
                          <Colxx xxs="12" lg="6" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.make" />
                              <span className="req"> *</span>
                            </p>

                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={this.store.brews}
                              onChange={(value) => this.store.setBrew(value)}
                              options={this.store.brewsList}
                              isMulti={true}
                            />
                            <span className="req-validation ">
                              {this.store.brew_validation
                                ? this.store.brew_validation.msg
                                  ? this.store.brew_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          {this.store.is_subscribe && (
                            <Colxx xxs="12" lg="6" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                Roast
                                <span className="req"> *</span>
                              </p>

                              <SelectInput
                                className="react-select"
                                classNamePrefix="react-select"
                                value={this.store.selectedRoast}
                                onChange={(value) => this.store.setRoast(value)}
                                options={this.store.roastList}
                                isMulti={true}
                              />
                              <span className="req-validation ">
                                {this.store.roast_validation
                                  ? this.store.roast_validation.msg
                                    ? this.store.roast_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          )}

                          <Colxx xxs="12" lg="6">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-3">
                              <Input
                                name="address"
                                value={
                                  this.store.enDescription
                                    ? this.store.enDescription
                                    : ''
                                }
                                type="textarea"
                                onChange={(e) =>
                                  this.store.changeEnDescription(e.target.value)
                                }
                              />
                              <span className="req-validation ">
                                {this.store.en_description_validation
                                  ? this.store.en_description_validation.msg
                                    ? this.store.en_description_validation.msg
                                    : null
                                  : null}
                              </span>
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="6">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description-ar" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-3">
                              <Input
                                className="ar"
                                name="description_ar"
                                value={
                                  this.store.arDescription
                                    ? this.store.arDescription
                                    : ''
                                }
                                type="textarea"
                                onChange={(e) =>
                                  this.store.changeArDescription(e.target.value)
                                }
                              />
                              <span className="req-validation ">
                                {this.store.ar_description_validation
                                  ? this.store.ar_description_validation.msg
                                    ? this.store.ar_description_validation.msg
                                    : null
                                  : null}
                              </span>
                            </div>
                          </Colxx>
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default AddProduct
