import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class FeaturetteStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFileEn = null

  @observable
  imageFileAr = null

  @observable
  titleEn = ''

  @observable
  titleAr = ''

  @observable
  descriptionEn = ''

  @observable
  descriptionAr = ''

  @observable
  en_imageFile_validation = null

  @observable
  ar_imageFile_validation = null

  @observable
  en_title_validation = null

  @observable
  ar_title_validation = null

  @observable
  en_description_validation = null

  @observable
  ar_description_validation = null

  @observable
  type_validation = null

  @observable
  item_validation = null

  @observable
  brand_validation = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  name = ''

  @observable
  password = null

  @observable
  email = null

  @observable
  btnDisable = false

  @observable
  typeList = [
    { value: 1, label: 'category' },
    { value: 2, label: 'product' },
    { value: 3, label: 'event' },
    { value: 4, label: 'blog' },
    { value: 5, label: 'memory' },
    { value: 6, label: 'brand' },
    { value: 7, label: 'subscribe' },
  ]

  @observable
  selectedType = null

  @observable
  itemList = []

  @observable
  productsList = []

  @observable
  subscribesList = []

  @observable
  eventsList = []

  @observable
  blogsList = []

  @observable
  brandsList = []

  @observable
  memoriesList = []

  @observable
  categoriesList = []

  @observable
  selectedItem = null

  @observable
  selectedBrand = null

  @observable
  response = null

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.post('featurette', body, 'list', history)
    if (value) {
      this.data = value.data.items
      await this.getDependencies(history)
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const dependencies = await axiFetch.get(
      'featurette',
      null,
      'dependencies/get',
      history
    )

    if (dependencies) {
      console.log(dependencies.data.Product)
      if (dependencies.data.Product) {
        await dependencies.data.Product.forEach((item) => {
          this.productsList.push({ value: item.id, label: item.name })
        })
      }

      if (dependencies.data.Brand) {
        await dependencies.data.Brand.forEach((item) => {
          this.brandsList.push({ value: item.id, label: item.name_en })
        })
      }

      if (dependencies.data.Category) {
        await dependencies.data.Category.forEach((item) => {
          this.categoriesList.push({ value: item.id, label: item.name_en })
        })
      }

      if (dependencies.data.Blog) {
        await dependencies.data.Blog.forEach((item) => {
          this.blogsList.push({ value: item.id, label: item.title })
        })
      }

      if (dependencies.data.Memory) {
        await dependencies.data.Memory.forEach((item) => {
          this.memoriesList.push({ value: item.id, label: item.title })
        })
      }
      if (dependencies.data.Event) {
        await dependencies.data.Event.forEach((item) => {
          this.eventsList.push({ value: item.id, label: item.title })
        })
      }

      if (dependencies.data.Subscribe) {
        await dependencies.data.Subscribe.forEach((item) => {
          this.subscribesList.push({ value: item.id, label: item.name })
        })
      }
    }
   
  }

  @action
  async validation(history) {
    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.descriptionEn,
      require: true,
      type: 'string',
      min: 20,
    })

    this.ar_description_validation = await checkInput({
      name: 'Arabic Description',
      value: this.descriptionAr,
      require: true,
      type: 'string',
      min: 20,
    })
    this.en_title_validation = await checkInput({
      name: 'English Title',
      value: this.titleEn,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_title_validation = await checkInput({
      name: 'Arabic Title',
      value: this.titleAr,
      require: true,
      type: 'string',
      min: 3,
    })
    this.en_imageFile_validation = await checkInput({
      name: 'English Image',
      value: this.imageFileEn,
      require: true,
    })

    this.ar_imageFile_validation = await checkInput({
      name: 'Arabic Image',
      value: this.imageFileAr,
      require: true,
    })

    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    this.item_validation = await checkInput({
      name: 'Item',
      value: this.selectedItem,
      require: true,
    })

    if (this.selectedType && this.selectedType.label === 'product') {
      this.brand_validation = await checkInput({
        name: 'Brand',
        value: this.selectedBrand,
        require: true,
      })
    }

    if (this.selectedType && this.selectedType.label === 'product') {
      this.type_validation.res &&
        this.brand_validation.res &&
        this.item_validation.res &&
        this.ar_imageFile_validation.res &&
        this.en_imageFile_validation.res &&
        this.en_title_validation.res &&
        this.ar_title_validation.res &&
        this.en_description_validation.res &&
        this.ar_description_validation.res &&
        this.handleSaveNewFeaturette(history)
    } else {
      this.type_validation.res &&
        this.item_validation.res &&
        this.ar_imageFile_validation.res &&
        this.en_imageFile_validation.res &&
        this.en_title_validation.res &&
        this.ar_title_validation.res &&
        this.en_description_validation.res &&
        this.ar_description_validation.res &&
        this.handleSaveNewFeaturette(history)
    }
  }

  @action
  handleSaveNewFeaturette = (history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      target: this.selectedType.label,
      value: this.selectedItem.value,
      description_en: this.descriptionEn,
      description_ar: this.descriptionAr,
      title_en: this.titleEn,
      title_ar: this.titleAr,
      image_en: this.imageFileEn,
      image_ar: this.imageFileAr,
    }

    if (this.selectedType.label === 'product') {
      data.brand_id = this.selectedBrand.value
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    axios
      .post(`${servicePath}/featurette/add`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        history.push(`/app/slider/featurette/${data.data.id}`)
        this.toggleModal()
        this.reset()
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
        this.btnDisable = false
      })
  }

  @action
  getProduct = async (history) => {
    this.itemList = []
    this.itemList = this.productsList
  }

  @action
  getSubscribe = async (history) => {
    this.itemList = []
    this.itemList = this.subscribesList}

  @action
  getBrand = async (history) => {
    this.itemList = []
    this.itemList = this.brandsList
  }

  @action
  getCategory = async (history) => {
    this.itemList = []
    this.itemList = this.categoriesList
  }

  @action
  getBlog = async (history) => {
    this.itemList = []
    this.itemList = this.blogsList
  }

  @action
  getEvent = async (history) => {
    this.itemList = []
    this.itemList = this.eventsList
    // if (!this.eventsList.length) {
    //   const body = {
    //     order: { id: -1 },
    //     searchOn: null,
    //     search: null,
    //     limit: 12,
    //     offset: 0,
    //     all: true,
    //   }
    //   const event = await axiFetch.post(
    //     'blog',
    //     { ...body, filter: { type: 'event' } },
    //     'list',
    //     history
    //   )
    //   if (event) {
    //     await event.data.items.forEach((item) => {
    //       this.eventsList.push({ value: item.id, label: item.title })
    //     })
    //   }
    //   this.itemList = this.eventsList
    // } else {
    //   this.itemList = this.eventsList
    // }
  }

  @action
  getMemory = async (history) => {
    this.itemList = []
    this.itemList = this.memoriesList
    // if (!this.memoriesList.length) {
    //   const body = {
    //     order: { id: -1 },
    //     searchOn: null,
    //     search: null,
    //     limit: 12,
    //     offset: 0,
    //     all: true,
    //   }
    //   const memory = await axiFetch.post(
    //     'blog',
    //     { ...body, filter: { type: 'memory' } },
    //     'list',
    //     history
    //   )
    //   if (memory) {
    //     await memory.data.items.forEach((item) => {
    //       this.memoriesList.push({ value: item.id, label: item.title })
    //     })
    //   }
    //   this.itemList = this.memoriesList
    // } else {
    //   this.itemList = this.memoriesList
    // }
  }

  @action
  async setType(data, history) {
    this.selectedType = data.selectedOption
    this.brand_validation = null
    switch (this.selectedType.label) {
      case 'product':
        // this.getBrand(history)
        this.getProduct(history)
        break
      case 'brand':
        this.getBrand(history)
        break
      case 'category':
        this.getCategory(history)
        break
      case 'blog':
        this.getBlog(history)
        break
      case 'event':
        this.getEvent(history)
        break
      case 'memory':
        this.getMemory(history)
        break
        case 'subscribe':
        this.getSubscribe(history)
        break

      default:
        break
    }
  }

  @action
  setItem(data) {
    this.selectedItem = data.selectedOption
  }

  @action
  setBrand(data) {
    this.selectedBrand = data.selectedOption
  }
  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.imageFileEn = null

    this.imageFileAr = null

    this.titleEn = ''

    this.titleAr = ''

    this.descriptionEn = ''

    this.descriptionAr = ''

    this.en_imageFile_validation = null

    this.ar_imageFile_validation = null

    this.en_title_validation = null

    this.ar_title_validation = null

    this.en_description_validation = null

    this.ar_description_validation = null

    this.item_validation = null

    this.brand_validation = null

    this.type_validation = null

    this.selectedItem = null

    this.selectedType = null

    this.selectedBrand = null

    this.openModal = false
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileEn(file) {
    this.imageFileEn = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeImageFileAr = (file) => {
    this.imageFileAr = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeTitleEn = (value) => {
    this.titleEn = value
  }

  @action
  changeTitleAr = (value) => {
    this.titleAr = value
  }

  @action
  changeDescriptionEn = (value) => {
    this.descriptionEn = value
  }

  @action
  changeDescriptionAr = (value) => {
    this.descriptionAr = value
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFileEn = null

    this.imageFileAr = null

    this.titleEn = ''

    this.titleAr = ''

    this.descriptionEn = ''

    this.descriptionAr = ''

    this.en_imageFile_validation = null

    this.ar_imageFile_validation = null

    this.en_title_validation = null

    this.ar_title_validation = null

    this.en_description_validation = null

    this.ar_description_validation = null

    this.item_validation = null

    this.brand_validation = null

    this.type_validation = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.openModal = false

    this.name = null

    this.password = null

    this.email = null

    this.btnDisable = false

    this.typeList = [
      { value: 1, label: 'category' },
      { value: 2, label: 'product' },
      { value: 3, label: 'event' },
      { value: 4, label: 'blog' },
      { value: 5, label: 'memory' },
      { value: 6, label: 'brand' },
      { value: 7, label: 'subscribe' },
    ]

    this.selectedType = null

    this.itemList = null

    this.productsList = []

    this.subscribesList = []

    this.eventsList = []

    this.blogsList = []

    this.brandsList = []

    this.memoriesList = []

    this.categoriesList = []

    this.selectedItem = null

    this.selectedBrand = null
  }
}
