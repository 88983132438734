import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import IntlMessages from '../../util/IntlMessages'
import { Nav, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'

@inject('stores')
@observer
class Sidebar extends Component {
  @observable
  store = this.props.stores.menu
  constructor(props) {
    super(props)
    this.state = { selectedParentMenu: '', viewingParentMenu: '', type: null }
  }
  handleWindowResize = (event) => {
    if (event && !event.isTrusted) {
      return
    }
    const { containerClassnames } = this.store
    let nextClasses = this.getMenuClassesForResize(containerClassnames)
    this.store.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.store.selectedMenuHasSubItems
    )
  }

  handleDocumentClick = (e) => {
    const container = this.getContainer()
    let isMenuClick = false
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('menu-button') ||
        e.target.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.parentElement.classList.contains(
          'menu-button-mobile'
        ))
    ) {
      isMenuClick = true
    }
    if (container.contains(e.target) || container === e.target || isMenuClick) {
      return
    }
    this.toggle(e)
    this.setState({
      viewingParentMenu: '',
    })
  }

  getMenuClassesForResize = (classes) => {
    const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.store
    let nextClasses = classes.split(' ').filter((x) => x !== '')
    const windowWidth = window.innerWidth
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile')
    } else if (windowWidth < subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x) => x !== 'menu-mobile')
      if (
        nextClasses.includes('menu-default') &&
        !nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses.push('menu-sub-hidden')
      }
    } else {
      nextClasses = nextClasses.filter((x) => x !== 'menu-mobile')
      if (
        nextClasses.includes('menu-default') &&
        nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = nextClasses.filter((x) => x !== 'menu-sub-hidden')
      }
    }
    return nextClasses
  }

  getContainer = () => {
    return ReactDOM.findDOMNode(this)
  }

  toggle = () => {
    const { containerClassnames, menuClickCount } = this.store
    const currentClasses = containerClassnames
      ? containerClassnames.split(' ').filter((x) => x !== '')
      : ''

    if (currentClasses.includes('menu-sub-hidden') && menuClickCount === 3) {
      this.store.setContainerClassnames(
        2,
        containerClassnames,
        this.store.selectedMenuHasSubItems
      )
    } else if (
      currentClasses.includes('menu-hidden') ||
      currentClasses.includes('menu-mobile')
    ) {
      this.store.setContainerClassnames(
        0,
        containerClassnames,
        this.store.selectedMenuHasSubItems
      )
    }
  }

  handleProps = () => {
    this.addEvents()
  }

  addEvents = () => {
    ;['click', 'touchstart'].forEach((event) =>
      document.addEventListener(event, this.handleDocumentClick, true)
    )
  }
  removeEvents = () => {
    ;['click', 'touchstart'].forEach((event) =>
      document.removeEventListener(event, this.handleDocumentClick, true)
    )
  }
  setSelectedLiActive = () => {
    const oldli = document.querySelector('.sub-menu  li.active')
    if (oldli != null) {
      oldli.classList.remove('active')
    }

    /* set selected parent menu */
    const selectedlink = document.querySelector('.sub-menu  a.active')
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add('active')
      this.setState({
        selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
          'data-parent'
        ),
      })
    } else {
      var selectedParentNoSubItem = document.querySelector(
        '.main-menu  li a.active'
      )
      if (selectedParentNoSubItem !== null) {
        this.setState({
          selectedParentMenu: selectedParentNoSubItem.getAttribute('data-flag'),
        })
      } else if (this.state.selectedParentMenu === '') {
        this.setState({
          selectedParentMenu: 'dashboards',
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSelectedLiActive()
      this.toggle()
      window.scrollTo(0, 0)
    }
    this.handleProps()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
    this.handleProps()
    this.setSelectedLiActive()
    let type = window.localStorage.getItem('admin-coffee-type')
    this.setState({ type })
  }

  componentWillUnmount() {
    this.removeEvents()
    window.removeEventListener('resize', this.handleWindowResize)
  }

  changeDefaultMenuType = (e, containerClassnames) => {
    e.preventDefault()
    let nextClasses = this.getMenuClassesForResize(containerClassnames)
    this.store.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.store.selectedMenuHasSubItems
    )
  }

  openSubMenu(e, selectedParent) {
    e.preventDefault()
    this.store.changeSelectedMenuHasSubItems(true)

    const { containerClassnames, menuClickCount } = this.store
    const currentClasses = containerClassnames
      ? containerClassnames.split(' ').filter((x) => x !== '')
      : ''

    if (!currentClasses.includes('menu-mobile')) {
      if (
        currentClasses.includes('menu-sub-hidden') &&
        (menuClickCount === 2 || menuClickCount === 0)
      ) {
        this.store.setContainerClassnames(3, containerClassnames, true)
      } else if (
        currentClasses.includes('menu-hidden') &&
        (menuClickCount === 1 || menuClickCount === 3)
      ) {
        this.store.setContainerClassnames(2, containerClassnames, true)
      } else if (
        currentClasses.includes('menu-default') &&
        !currentClasses.includes('menu-sub-hidden') &&
        (menuClickCount === 1 || menuClickCount === 3)
      ) {
        this.store.setContainerClassnames(0, containerClassnames, true)
      }
    } else {
      this.store.addContainerClassname(
        'sub-show-temporary',
        containerClassnames
      )
    }
    this.setState({
      viewingParentMenu: selectedParent,
    })
  }

  changeSelectedParentHasNoSubmenu(e, menu) {
    this.setState({
      viewingParentMenu: menu,
    })
    this.store.changeSelectedMenuHasSubItems(false)
    this.toggle()
  }
  render() {
    return (
      <div className="sidebar">
        <div className="main-menu">
          <div className="scroll">
            <PerfectScrollbar
              option={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <Nav vertical className="list-unstyled">
                {/*
                  If menu item has no sub items.
                  Note: If you want use hasn't sub menu item  : Set the menu default type to menu-sub-hidden*/}

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'dashboards' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'dashboards',
                  })}
                >
                  <NavLink
                    to="/app/dashboards/default"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'dashboards')
                    }
                  >
                    <i className=" iconsminds-home-4" />{' '}
                    <IntlMessages id="menu.dashboards" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'product' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'product',
                  })}
                >
                  <NavLink
                    to="/app/product"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'product')
                    }
                  >
                    <i className="iconsminds-coffee" />{' '}
                    <IntlMessages id="menu.product" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'order' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'order',
                  })}
                >
                  <NavLink
                    to="/app/order"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'order')
                    }
                  >
                    <i className="iconsminds-shopping-cart" />{' '}
                    <IntlMessages id="menu.order" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'subscribeOrder' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'subscribeOrder',
                  })}
                >
                  <NavLink
                    to="/app/subscribeOrder"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'subscribeOrder')
                    }
                  >
                    <i className="iconsminds-shopping-cart" />{' '}
                    <IntlMessages id="menu.subscribeOrder" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'tag' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'tag',
                  })}
                >
                  <NavLink
                    to="/app/tag"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'tag')
                    }
                  >
                    <i className="iconsminds-tag" />{' '}
                    <IntlMessages id="menu.tag" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'wholesale' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'wholesale',
                  })}
                >
                  <NavLink
                    to="/app/wholesale"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'wholesale')
                    }
                  >
                    <i className="iconsminds-gaugage" />{' '}
                    <IntlMessages id="menu.wholesale" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'calculation' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'calculation',
                  })}
                >
                  <NavLink
                    to="/app/calculation"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'calculation')
                    }
                  >
                    <i className="iconsminds-calculator" />{' '}
                    <IntlMessages id="menu.calculation" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'brew' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'brew',
                  })}
                >
                  <NavLink
                    to="/app/brew"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'brew')
                    }
                  >
                    <i className="iconsminds-chopsticks" />{' '}
                    <IntlMessages id="menu.brew" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'roast' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'roast',
                  })}
                >
                  <NavLink
                    to="/app/roast"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'roast')
                    }
                  >
                    <i className="iconsminds-chopsticks" />{' '}
                    <IntlMessages id="menu.roast" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'category' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'category',
                  })}
                >
                  <NavLink
                    to="/app/category"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'category')
                    }
                  >
                    <i className="iconsminds-shop-4" />{' '}
                    <IntlMessages id="menu.category" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'blog' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'blog',
                  })}
                >
                  <NavLink
                    to="/app/blog"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'blog')
                    }
                  >
                    <i className="iconsminds-newspaper" />{' '}
                    <IntlMessages id="menu.blog" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'socialNetwork' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'socialNetwork',
                  })}
                >
                  <NavLink
                    to="/app/social"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'socialNetwork')
                    }
                  >
                    <i className="iconsminds-rss" />{' '}
                    <IntlMessages id="menu.social" />
                  </NavLink>
                </NavItem>


                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'deliveryTime' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'deliveryTime',
                  })}
                >
                  <NavLink
                    to="/app/deliveryTime"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'deliveryTime')
                    }
                  >
                    <i className="iconsminds-clock" />{' '}
                    <IntlMessages id="menu.deliveryTime" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'coupon' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'coupon',
                  })}
                >
                  <NavLink
                    to="/app/coupon"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'coupon')
                    }
                  >
                    <i className="iconsminds-qr-code" />{' '}
                    <IntlMessages id="menu.coupon" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'area' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'area',
                  })}
                >
                  <NavLink
                    to="/app/area"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'area')
                    }
                  >
                    <i className="iconsminds-location-2" />{' '}
                    <IntlMessages id="menu.area" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'slider' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'slider',
                  })}
                >
                  <NavLink
                    to="/app/slider"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'slider')
                    }
                  >
                    <i className="iconsminds-magic-wand" />{' '}
                    <IntlMessages id="menu.slider" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'brand' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'brand',
                  })}
                >
                  <NavLink
                    to="/app/brand"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'brand')
                    }
                  >
                    <i className="iconsminds-crown-2" />{' '}
                    <IntlMessages id="menu.brand" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'customers' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'customers',
                  })}
                >
                  <NavLink
                    to="/app/customers"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'customers')
                    }
                  >
                    <i className="iconsminds-conference" />{' '}
                    <IntlMessages id="menu.customers" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'contactUs' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'contactUs',
                  })}
                >
                  <NavLink
                    to="/app/contactUs"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'contactUs')
                    }
                  >
                    <i className="iconsminds-support" />{' '}
                    <IntlMessages id="menu.contactUs" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'careers' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'careers',
                  })}
                >
                  <NavLink
                    to="/app/careers"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'careers')
                    }
                  >
                    <i className="iconsminds-handshake" />{' '}
                    <IntlMessages id="menu.careers" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'faqCategory' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'faqCategory',
                  })}
                >
                  <NavLink
                    to="/app/faqCategory"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'faqCategory')
                    }
                  >
                    <i className="iconsminds-shop-4" />{' '}
                    <IntlMessages id="menu.faqCategory" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'faq' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'faq',
                  })}
                >
                  <NavLink
                    to="/app/faq"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'faq')
                    }
                  >
                    <i className="iconsminds-speach-bubbles" />{' '}
                    <IntlMessages id="menu.faq" />
                  </NavLink>
                </NavItem>

               

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'homePage' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'homePage',
                  })}
                >
                  <NavLink
                    to="/app/homePage"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'homePage')
                    }
                  >
                    <i className="iconsminds-cursor-click" />{' '}
                    <IntlMessages id="menu.homePage" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'event' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'event',
                  })}
                >
                  <NavLink
                    to="/app/event"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'event')
                    }
                  >
                    <i className="iconsminds-atom" />{' '}
                    <IntlMessages id="menu.event" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'recipes' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'recipes',
                  })}
                >
                  <NavLink
                    to="/app/recipes"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'recipes')
                    }
                  >
                    <i className="iconsminds-chopsticks" />{' '}
                    <IntlMessages id="menu.recipes" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'memory' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'memory',
                  })}
                >
                  <NavLink
                    to="/app/memory"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'memory')
                    }
                  >
                    <i className="iconsminds-coffee-bean" />{' '}
                    <IntlMessages id="menu.memory" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'ourStory' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'ourStory',
                  })}
                >
                  <NavLink
                    to="/app/ourStory"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'ourStory')
                    }
                  >
                    <i className="iconsminds-open-book" />{' '}
                    <IntlMessages id="menu.ourStory" />
                  </NavLink>
                </NavItem>

                {this.state.type === 'superadmin' && (
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'user' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'user',
                    })}
                  >
                    <NavLink
                      to="/app/user"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'user')
                      }
                    >
                      <i className="iconsminds-administrator" />{' '}
                      <IntlMessages id="menu.user" />
                    </NavLink>
                  </NavItem>
                )}

                {this.state.type === 'superadmin' && (
                  <>
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'menu' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'menu',
                    })}
                  >
                    <NavLink
                      to="/app/menu"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'menu')
                      }
                    >
                      <i className="iconsminds-receipt-4" />{' '}
                      <IntlMessages id="menu.menu" />
                    </NavLink>
                  </NavItem>
                  <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'termsOfServices' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'termsOfServices',
                  })}
                >
                  <NavLink
                    to="/app/termsOfServices"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'termsOfServices')
                    }
                  >
                    <i className="iconsminds-handshake" />{' '}
                    <IntlMessages id="menu.termsOfServices" />
                  </NavLink>
                </NavItem>
                </>
                )}

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'profile' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'profile',
                  })}
                >
                  <NavLink
                    to="/app/profile"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'profile')
                    }
                  >
                    <i className="iconsminds-profile" />{' '}
                    <IntlMessages id="menu.profile" />
                  </NavLink>
                </NavItem>
              </Nav>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    )
  }
}

export default Sidebar
