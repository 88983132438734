import React, { Component } from "react";
import { Colxx } from "../CustomBootstrap";
import { Collapse } from "reactstrap";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";

@inject("stores")
@observer
class DisplayOption extends Component {
  @observable
  setting = this.props.stores.setting;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Colxx xxs="12">
        <Collapse
          isOpen={this.state.displayOptionsIsOpen}
          className="d-md-block"
          id="displayOptions"
        >
          <span className="mr-3 mb-2 d-flex d-inline-block float-md-left">
            <div
              className={`mr-2 view-icon ${
                this.setting.displayMode === "thumblist" ? "active" : ""
              }`}
              onClick={() => this.setting.changeDisplayMode("thumblist")}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                <path
                  className="view-icon-svg"
                  d="M17.5,3H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z"
                />
                <path
                  className="view-icon-svg"
                  d="M3,2V3H1V2H3m.12-1H.88A.87.87,0,0,0,0,1.88V3.12A.87.87,0,0,0,.88,4H3.12A.87.87,0,0,0,4,3.12V1.88A.87.87,0,0,0,3.12,1Z"
                />
                <path
                  className="view-icon-svg"
                  d="M3,9v1H1V9H3m.12-1H.88A.87.87,0,0,0,0,8.88v1.24A.87.87,0,0,0,.88,11H3.12A.87.87,0,0,0,4,10.12V8.88A.87.87,0,0,0,3.12,8Z"
                />
                <path
                  className="view-icon-svg"
                  d="M3,16v1H1V16H3m.12-1H.88a.87.87,0,0,0-.88.88v1.24A.87.87,0,0,0,.88,18H3.12A.87.87,0,0,0,4,17.12V15.88A.87.87,0,0,0,3.12,15Z"
                />
                <path
                  className="view-icon-svg"
                  d="M17.5,10H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z"
                />
                <path
                  className="view-icon-svg"
                  d="M17.5,17H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z"
                />
              </svg>
            </div>
            <div
              className={`mr-2 view-icon ${
                this.setting.displayMode === "imagelist" ? "active" : ""
              }`}
              onClick={() => this.setting.changeDisplayMode("imagelist")}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                <path
                  className="view-icon-svg"
                  d="M7,2V8H1V2H7m.12-1H.88A.87.87,0,0,0,0,1.88V8.12A.87.87,0,0,0,.88,9H7.12A.87.87,0,0,0,8,8.12V1.88A.87.87,0,0,0,7.12,1Z"
                />
                <path
                  className="view-icon-svg"
                  d="M17,2V8H11V2h6m.12-1H10.88a.87.87,0,0,0-.88.88V8.12a.87.87,0,0,0,.88.88h6.24A.87.87,0,0,0,18,8.12V1.88A.87.87,0,0,0,17.12,1Z"
                />
                <path
                  className="view-icon-svg"
                  d="M7,12v6H1V12H7m.12-1H.88a.87.87,0,0,0-.88.88v6.24A.87.87,0,0,0,.88,19H7.12A.87.87,0,0,0,8,18.12V11.88A.87.87,0,0,0,7.12,11Z"
                />
                <path
                  className="view-icon-svg"
                  d="M17,12v6H11V12h6m.12-1H10.88a.87.87,0,0,0-.88.88v6.24a.87.87,0,0,0,.88.88h6.24a.87.87,0,0,0,.88-.88V11.88a.87.87,0,0,0-.88-.88Z"
                />
              </svg>
            </div>
          </span>
        </Collapse>
      </Colxx>
    );
  }
}

export default DisplayOption;
