import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  // Label,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'
// import { Thumb } from '../Thumb'
import SelectInput from '../select'
import ImageUploader from '../Uploader/Uploader'
class ProductModal extends Component {
  constructor(props) {
    super(props)
    this.state = { getImage: false }
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeImageFile_en(data)
    } else {
      this.setState({ getImage: false })
    }
    // this.props.handleSave()
  }

  changeArabicImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeImageFile_ar(data)
    } else {
      this.setState({ getImage: false })
    }
    this.props.handleSave()
  }
  render() {
    const {
      openModal,
      toggleModal,
      // imageFile_en,
      // imageFile_ar,
      // changeImageFile_en,
      // changeImageFile_ar,
      en_imageFile_validation,
      ar_imageFile_validation,
      product_validation,
      // handleSave,
      btnDisable,
      selectedItem,
      itemList,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader
          toggle={toggleModal}
          className="d-flex justify-content-center align-items-center"
        >
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody className="p-2">
          <Colxx xxs="12" className=" mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.product" />
              <span className="req"> *</span>
            </p>
            <div className="mb-0">
              <SelectInput
                className="react-select"
                classNamePrefix="react-select"
                value={selectedItem ? selectedItem : ''}
                onChange={(value) => this.props.setItem(value)}
                options={itemList}
                isMulti={false}
              ></SelectInput>
            </div>
            <span className="req-validation">
              {' '}
              {product_validation
                ? product_validation.msg
                  ? product_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.enImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.VendorModal = instance)}
              images={this.state.imageFile}
              ratio="3:1"
              size={{ width: 1920, height: 640 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeImageFile(data)
              }}
            />
            <span className="req-validation">
              {' '}
              {en_imageFile_validation
                ? en_imageFile_validation.msg
                  ? en_imageFile_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
          <Colxx xxs="12" className="mb-3">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.arImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.featureteModal = instance)}
              images={this.state.imageFile}
              ratio="3:1"
              size={{ width: 1920, height: 640 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeArabicImageFile(data)
              }}
            />

            <span className="req-validation">
              {' '}
              {ar_imageFile_validation
                ? ar_imageFile_validation.msg
                  ? ar_imageFile_validation.msg
                  : null
                : null}
            </span>
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => this.handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ProductModal
