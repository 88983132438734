import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class CakeStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  cakeDetail = null

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = ['name_en', 'name_ar']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  brewList = []

  @observable
  categoryList = []

  @observable
  brew = { value: 0, label: 'All' }

  @observable
  category = { value: 0, label: 'All' }

  @action
  async handleRequest(history) {
    this.isLoading = true
    await this.getData(history)
    await this.getDependencies(history)
    this.isLoading = false
  }

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
    }
    const value = await axiFetch.post('product', body, 'list', history)
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    }
    //  await this.getDependencies(history)
  }

  @action
  async getDependencies(history) {
    this.isLoading = true
    await this.getCategory(history)
    await this.getBrew(history)
    this.isLoading = false
  }

  @action
  async getBrew(history) {
    const body = {
      order: this.order,
      searchOn: null,
      search: null,
      // limit: 12,
      offset: 0,
      all: true,
    }

    await axios
      .post(`${servicePath}/brew/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.brewList.push({ value: 0, label: 'All' })
        data.data.items.map((brew) =>
          this.brewList.push({
            value: brew.id,
            label: brew.name,
          })
        )
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async getCategory(history) {
    const body = {
      order: this.order,
      searchOn: null,
      search: null,
      limit: 12,
      offset: 0,
      all: true,
    }

    await axios
      .post(`${servicePath}/category/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.categoryList.push({ value: 0, label: 'All' })
        data.data.items.map((category) =>
          this.categoryList.push({
            value: category.id,
            label: category.name,
          })
        )
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }
  @action
  async handleKeyPress(e, history) {
    if (e.key === 'Enter') {
      this.currentPage = 1
      this.offset = 0
      this.getData(history)
    }
  }

  @action
  handleChangeSearch(value) {
    this.search = value
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeEnName = (name) => {
    this.cakeDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.cakeDetail.name_ar = name
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }
  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  setCategory(value) {
    this.category = value.selectedOption
    if (this.category.label !== 'All') {
      this.filter = {
        brew_type: this.brew
          ? this.brew.label !== 'All'
            ? this.brew.value
            : null
          : null,
        category_id: this.category.value,
      }
    } else {
      this.filter = {
        brew_type: this.brew
          ? this.brew.label !== 'All'
            ? this.brew.value
            : null
          : null,
        category_id: null,
      }
    }
    this.currentPage = 1
    this.offset = 0
    // this.filter = {bakery_id :this.bakery ?  this.bakery.value: null , category_id : this.category.value }
    this.getData()
  }

  @action
  setBrew(value) {
    this.brew = value.selectedOption
    if (this.brew.label !== 'All') {
      this.filter = {
        brew_type: this.brew.value,
        category_id: this.category
          ? this.category.label !== 'All'
            ? this.category.value
            : null
          : null,
      }
    } else {
      this.filter = {
        brew_type: null,
        category_id: this.category
          ? this.category.label !== 'All'
            ? this.category.value
            : null
          : null,
      }
    }
    this.currentPage = 1
    this.offset = 0
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.cakeDetail = null

    this.currentPage = 1

    this.imageFile = null

    this.search = ''

    this.searchOn = ['name_en', 'name_ar']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.brewList = []

    this.categoryList = []

    this.brew = { value: 0, label: 'All' }

    this.category = { value: 0, label: 'All' }
  }
}
