import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, NavLink, NavItem, Nav } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import DisplayOption from '../../../components/DisolayOption'
import CardImageList from '../../../components/Card/cardImageList'
import FeaturetteModal from '../../../components/Modal/featuretteModal'
import Pagination from '../../../components/List/Pagination'
import classnames from 'classnames'
import ProductBanner from '../../../components/Modal/productBanner'
import { ToastContainer, toast } from 'react-toastify'

@inject('stores')
@observer
class FeaturetteList extends Component {
  @observable
  store = this.props.stores.featurette

  @observable
  banner = this.props.stores.banner

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {
      activeFirstTab: '1',
    }
  }
  componentDidMount() {
    this.store.getData(this.props.history)
    this.banner.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  toggleTab = (id) => {
    this.setState({ activeFirstTab: id })
    if (id === '2') {
      this.banner.getDependencies(this.props.history)
    }
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (!this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.slider" />}
                  match={this.props.match}
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.state.activeFirstTab === '1'
                      ? this.store.changeOpenModal()
                      : this.banner.changeOpenModal()
                  }
                >
                  <IntlMessages id="pages.add-new" />
                </Button>
                {this.state.activeFirstTab === '1' ? (
                  <FeaturetteModal
                    openModal={this.store.openModal}
                    toggleModal={() => this.store.toggleModal()}
                    imageFile_en={this.store.imageFileEn}
                    imageFile_ar={this.store.imageFileAr}
                    changeImageFile_en={(file) =>
                      this.store.changeImageFileEn(file)
                    }
                    changeImageFile_ar={(file) =>
                      this.store.changeImageFileAr(file)
                    }
                    title_en={this.store.titleEn}
                    title_ar={this.store.titleAr}
                    changeTitle_en={(data) => this.store.changeTitleEn(data)}
                    changeTitle_ar={(data) => this.store.changeTitleAr(data)}
                    description_en={this.store.descriptionEn}
                    description_ar={this.store.descriptionAr}
                    changeDescription_en={(data) =>
                      this.store.changeDescriptionEn(data)
                    }
                    changeDescription_ar={(data) =>
                      this.store.changeDescriptionAr(data)
                    }
                    handleSave={() => this.store.validation(this.props.history)}
                    btnDisable={this.store.btnDisable}
                    en_title_validation={this.store.en_title_validation}
                    ar_title_validation={this.store.ar_title_validation}
                    en_imageFile_validation={this.store.en_imageFile_validation}
                    ar_imageFile_validation={this.store.ar_imageFile_validation}
                    en_description_validation={
                      this.store.en_description_validation
                    }
                    ar_description_validation={
                      this.store.ar_description_validation
                    }
                    type_validation={this.store.type_validation}
                    item_validation={this.store.item_validation}
                    brand_validation={this.store.brand_validation}
                    selectedType={this.store.selectedType}
                    typeList={this.store.typeList}
                    setType={(data) => this.store.setType(data)}
                    selectedItem={this.store.selectedItem}
                    selectedBrand={this.store.selectedBrand}
                    brandsList={this.store.brandsList}
                    itemList={this.store.itemList}
                    setItem={(data) => this.store.setItem(data)}
                    setBrand={(data) => this.store.setBrand(data)}
                  ></FeaturetteModal>
                ) : (
                  <ProductBanner
                    openModal={this.banner.openModal}
                    toggleModal={() => this.banner.toggleModal()}
                    imageFile_en={this.banner.imageFileEn}
                    imageFile_ar={this.banner.imageFileAr}
                    changeImageFile_en={(file) =>
                      this.banner.changeImageFileEn(file)
                    }
                    changeImageFile_ar={(file) =>
                      this.banner.changeImageFileAr(file)
                    }
                    handleSave={() =>
                      this.banner.validation(this.props.history)
                    }
                    btnDisable={this.banner.btnDisable}
                    en_imageFile_validation={
                      this.banner.en_imageFile_validation
                    }
                    ar_imageFile_validation={
                      this.banner.ar_imageFile_validation
                    }
                    product_validation={this.banner.product_validation}
                    selectedItem={this.banner.selectedProduct}
                    itemList={this.banner.productsList}
                    setItem={(data) => this.banner.setProduct(data)}
                  />
                )}
                <Row>
                  <DisplayOption />
                </Row>
                <Colxx xs="12" className="p-0">
                  <Nav tabs className="separator-tabs m-0 mb-5 ">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeFirstTab === '1',
                          // "nav-link": true
                        })}
                        onClick={() => {
                          this.toggleTab('1')
                        }}
                        to="#"
                      >
                        <IntlMessages id="pages.featurette" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeFirstTab === '2',
                          // "nav-link": true
                        })}
                        onClick={() => this.toggleTab('2')}
                        to="#"
                      >
                        <IntlMessages id="pages.productBanner" />
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Colxx>
                {/* <Separator className="mb-5" /> */}
              </Colxx>
            </Row>
            {this.state.activeFirstTab === '1' ? (
              <>
                <Row>
                  <Colxx xxs="12">
                    {this.setting.displayMode === 'thumblist' ? (
                      <Row>
                        {this.store.data
                          ? this.store.data.map((featurette) => (
                              <CardThumbList
                                key={featurette.id}
                                data={featurette}
                                path="slider/featurette"
                              />
                            ))
                          : null}
                      </Row>
                    ) : (
                      <Row>
                        {this.store.data
                          ? this.store.data.map((featurette) => (
                              <CardImageList
                                key={featurette.id}
                                data={featurette}
                                path="slider/featurette"
                              />
                            ))
                          : null}
                      </Row>
                    )}
                  </Colxx>
                </Row>
                {this.store.pagination > 1 ? (
                  <Pagination
                    totalPage={this.store.pagination}
                    numberLimit={this.store.limit}
                    onChangePage={(e) => this.store.changePage(e)}
                    currentPage={this.store.currentPage}
                  />
                ) : null}{' '}
              </>
            ) : (
              <>
                <Row>
                  <Colxx xxs="12">
                    <Row>
                      {this.banner.data
                        ? this.banner.data.map((banner, index) => (
                            <CardImageList
                              key={index}
                              data={banner}
                              path="slider/banner"
                              imageRadius={8}
                              imageHeight="36%"
                                imageTop="32%"
                            />
                          ))
                        : null}
                    </Row>
                  </Colxx>
                </Row>
                {this.store.pagination > 1 ? (
                  <Pagination
                    totalPage={this.store.pagination}
                    numberLimit={this.store.limit}
                    onChangePage={(e) => this.store.changePage(e)}
                    currentPage={this.store.currentPage}
                  />
                ) : null}
              </>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

export default FeaturetteList
