import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Card, Label } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import DisplayOption from '../../../components/DisolayOption'
import CardImageList from '../../../components/Card/cardImageList'
// import ModalContainer from "../../../components/Modal";
import SelectInput from '../../../components/select'
import Pagination from '../../../components/List/Pagination'

@inject('stores')
@observer
class ProductList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
  }
  @observable
  store = this.props.stores.product

  @observable
  setting = this.props.stores.setting

  async componentDidMount() {
    await this.store.handleRequest(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="d-flex align-items-center">
                <Colxx xxs="8">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.product" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx xxs="4" className="float-righte">
                  <Button
                    color="primary"
                    className="btn-shadow float-right mb-3"
                    size="lg"
                    onClick={() =>
                      this.props.history.push('/app/product/addProduct')
                    }
                  >
                    <IntlMessages id="pages.add-new" />
                  </Button>
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <DisplayOption />
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row className="mb-4">
              <Colxx>
                <Card
                  className="p-2 pb-4 pt-4 d-flex  flex-row"
                  style={{ overflow: 'unset' }}
                >
                  <Colxx xl="4">
                    <Label>Category</Label>{' '}
                    <SelectInput
                      className="react-select"
                      classNamePrefix="react-select"
                      value={this.store.category}
                      onChange={(value) => this.store.setCategory(value)}
                      options={this.store.categoryList}
                      isMulti={false}
                    />
                  </Colxx>
                  <Colxx xl="4">
                    <Label>Brewing Method</Label>{' '}
                    <SelectInput
                      className="react-select"
                      classNamePrefix="react-select"
                      value={this.store.brew}
                      onChange={(value) => this.store.setBrew(value)}
                      options={this.store.brewList}
                      isMulti={false}
                    />
                  </Colxx>
                  <Colxx xl="4">
                    <Label>Search</Label> <br />{' '}
                    <div className="search-sm d-inline-block  align-top w-100">
                      <input
                        className="w-100 "
                        type="text"
                        name="keyword"
                        placeholder={'Search'}
                        value={this.store.search}
                        onChange={(e) =>
                          this.store.handleChangeSearch(e.target.value)
                        }
                        onKeyPress={(event) =>
                          this.store.handleKeyPress(event, this.props.history)
                        }
                      />
                    </div>
                  </Colxx>
                </Card>
              </Colxx>
            </Row>
            {this.store.isLoading ? (
              <div className="loading"></div>
            ) : (
              <>
                <Row>
                  <Colxx xxs="12">
                    {this.setting.displayMode === 'thumblist' ? (
                      <Row>
                        {this.store.data
                          ? this.store.data.map((product) => (
                              <CardThumbList
                              opacity={!product.is_available? 0.5: 1}
                                key={product.id}
                                data={product}
                                path="product/detail"
                              />
                            ))
                          : null}
                      </Row>
                    ) : (
                      <Row>
                        {this.store.data
                          ? this.store.data.map((product) => (
                              <CardImageList
                                opacity={!product.is_available? 0.5: 1}
                                key={product.id}
                                data={product}
                                path="product/detail"
                                // imageRadius={8}
                                // imageWidth="100%"
                                // imageLeft="0"
                                // imageTop={0}
                                // paddingTop="200%"
                                // xl="2"
                                // lg="2"
                                // sm='4'
                              />
                            ))
                          : null}
                      </Row>
                    )}
                  </Colxx>
                </Row>
                {this.store.pagination > 1 ? (
                  <Pagination
                    totalPage={this.store.pagination}
                    numberLimit={this.store.limit}
                    onChangePage={(e) => this.store.changePage(e)}
                    currentPage={this.store.currentPage}
                  />
                ) : null}
              </>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

export default ProductList
