import React, { Component, Fragment } from 'react'
import { Row, Card, CardBody, CardText, Button } from 'reactstrap'
import CouponModal from '../../../components/Modal/couponModal'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import Pagination from '../../../components/List/Pagination'
import moment from 'moment'

@inject('stores')
@observer
class CouponList extends Component {
  @observable
  store = this.props.stores.coupon
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.coupon" />}
              match={this.props.match}
            />
            <Button
              color="primary"
              className="btn-shadow float-right"
              size="lg"
              onClick={() => this.store.changeOpenModal()}
            >
              <IntlMessages id="pages.add-new" />
            </Button>
            <CouponModal
              openModal={this.store.openModal}
              toggleModal={() => this.store.toggleModal()}
              unit={this.store.unit}
              amount={this.store.amount}
              usage={this.store.usage}
              startDate={this.store.startDate}
              endDate={this.store.endDate}
              changeUsage={(data) => this.store.changeUsage(data)}
              changeUnit={(data) => this.store.changeUnit(data)}
              changeAmount={(data) => this.store.changeAmount(data)}
              changeStartDate={(data) => this.store.changeStartDate(data)}
              changeEndDate={(data) => this.store.changeEndDate(data)}
              btnDisable={this.store.btnDisable}
              handleSave={() => this.store.validation(this.props.history)}
              target="coupon"
              amount_validation={this.store.amount_validation}
              usage_validation={this.store.usage_validation}
              startDate_validation={this.store.startDate_validation}
              endDate_validation={this.store.endDate_validation}
              code={this.store.code}
              code_validation={this.store.code_validation}
              changeCode={(data) => this.store.changeCode(data)}
            ></CouponModal>
            <Row className="col-12 justify-content-end p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0">
                <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      value={this.store.search}
                      placeholder={`Search`}
                      onChange={(e) =>
                        this.store.hendleChangeKey(e.target.value)
                      }
                      onKeyPress={(event) => this.store.handleKeyPress(event)}
                    />
                  </div>
                </div>{' '}
              </div>{' '}
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="pl-0 d-xl-block d-md-none d-none">
                <div
                  className="col-12 p-2 d-flex align-items-center mb-3"
                  style={{ borderBottom: '1px solid gray' }}
                >
                  <div className="col-12 d-flex align-items-center">
                    <p className="col-1 p-2 m-0 text-muted font-weight-bold text-center">
                      Amount
                    </p>
                    <p className="col-3 p-2 m-0 text-muted font-weight-bold text-center">
                      Coupon Code
                    </p>

                    <p className="col-2 p-2 m-0 text-muted font-weight-bold text-center">
                      Start date
                    </p>

                    <p className="col-2 p-2 m-0 text-muted font-weight-bold text-center">
                      End date
                    </p>

                    <p className="col-1 p-2 m-0 text-muted font-weight-bold text-center">
                      Usage
                    </p>
                    <p className="col-2 p-2 m-0 text-muted font-weight-bold text-center">
                      Remaining
                    </p>
                  </div>
                </div>
              </Colxx>
              <Colxx xxs="12 pl-0 d-flex flex-wrap">
                {this.store.data.map((item, index) => (
                  <div
                    className="col-xl-12  col-md-4 col-sm-12 col-12"
                    key={index}
                  >
                    <Card className="col-xl-12  pt-3 pb-3 pl-0 pr-0 pointer mb-3">
                      <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                        <div className="col-xl-12 col-md-12 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap">
                          <CardText className="col-xl-1 col-md-12  p-2 m-0  font-weight-bold truncate text-xl-center text-md-left ">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Amount :{' '}
                            </span>
                            {`${Number(item.amount)} ${item.unit}`}
                          </CardText>
                          <CardText className="col-xl-3 col-md-12  p-2 m-0  font-weight-bold truncate text-xl-center text-md-left ">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Coupon Code :{' '}
                            </span>
                            {item.code}
                          </CardText>
                          <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Start date :{' '}
                            </span>
                            {moment(item.start).utc().format('DD/MM/YYYY')}
                          </CardText>
                          <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              End date :{' '}
                            </span>
                            {item.end
                              ? moment(item.end).utc().format('DD/MM/YYYY')
                              : 'Limitless'}
                          </CardText>
                          <CardText className="col-xl-1 col-md-12 p-2 m-0 font-weight-bold truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              usage :{' '}
                            </span>
                            {item.usage}
                          </CardText>

                          <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Remaining :{' '}
                            </span>
                            {item.remaining}
                          </CardText>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </Colxx>
              {this.store.pagination > 1 ? (
                <Pagination
                  totalPage={this.store.pagination}
                  numberLimit={this.store.limit}
                  onChangePage={(e) =>
                    this.store.changePage(e, this.props.history)
                  }
                  currentPage={this.store.currentPage}
                />
              ) : null}
            </Row>
          </>
        )}
      </Fragment>
    )
  }
}

export default CouponList
