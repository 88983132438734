import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'

export default class BlogStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  search = ''

  @observable
  searchOn = ['title_en']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  ar_name_validation = null

  @observable
  btnDisable = false

  @observable
  enName = ''

  @observable
  arName = ''

  @observable
  content_en = ''

  @observable
  content_ar = ''

  @observable
  coverImageFile = null

  @observable
  thumbImageFile = null

  @observable
  tags = null

  @observable
  tagsList = []

  @observable
  enSummary = ''

  @observable
  arSummary = ''

  @observable
  coverImage_validation = null

  @observable
  content_validation = null

  @observable
  thumbImage_validation = null

  @observable
  en_name_validation = null

  @observable
  tag_validation = null

  @observable
  summary_validation = null

  @observable
  response = null

  @action
  handleChangeEnContent = (data) => {
    this.content_en = data
  }

  @action
  handleChangeArContent = (data) => {
    this.content_ar = data
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeCoverImageFile(file) {
    if (file) {
      this.coverImageFile = this.base64ToBlob(file, 'image/jpg')
    } else {
      this.coverImageFile = null
    }
  }

  @action
  async changeThumbImageFile(file) {
    if (file) {
      this.thumbImageFile = this.base64ToBlob(file, 'image/jpg')
    } else {
      this.thumbImageFile = null
    }
  }


  @action
  changeEnName = (value) => {
    this.enName = value
  }

  @action
  changeArName = (value) => {
    this.arName = value
  }

  @action
  setTag(value) {
    this.tags = value.selectedOption
  }

  @action
  changeArSummary = (value) => {
    this.arSummary = value
  }

  @action
  changeEnSummary = (value) => {
    this.enSummary = value
  }

  @action
  async getDependencies(history) {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    // const value=  await axiFetch.post("category", body, "list", history);
    // const brew=  await axiFetch.post("brew", body, "list", history);
    const tag = await axiFetch.post('tag', body, 'list', history)

    if (tag) {
      await tag.data.items.map((tag) =>
        this.tagsList.push({
          value: tag.id,
          label: tag.name_en,
        })
      )
    }

    this.isLoading = true
  }

  @action
  getData = async (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }

    axios
      .post(`${servicePath}/blog/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data.items
        this.pagination =
          parseInt(data.data.pagination.total / this.limit) +
          (data.data.pagination.total % this.limit ? 1 : 0)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Title',
      value: this.enName,
      require: true,
      type: 'string',
      min: 8,
    })
    // this.tag_validation = await checkInput({
    //   name: "Tag",
    //   value: this.tags,
    //   require: true,
    // });

    this.summary_validation = await checkInput({
      name: 'English Summary',
      value: this.enSummary,
      require: true,
      type: 'string',
      min: 3,
    })

    this.content_validation = await checkInput({
      name: 'English Content',
      value: this.content_en,
      require: true,
      type: 'string',
      min: 20,
    })
    this.coverImage_validation = await checkInput({
      name: 'Cover Image',
      value: this.coverImageFile,
      require: true,
    })

    this.thumbImage_validation = await checkInput({
      name: 'Thumb Image',
      value: this.thumbImageFile,
      require: true,
    })
    this.coverImage_validation.res &&
      this.thumbImage_validation.res &&
      // this.tag_validation.res &&
      this.en_name_validation.res &&
      this.summary_validation.res &&
      this.content_validation.res &&
      this.handleSaveNewPost(history)
  }

  @action
  handleSaveNewPost = (history) => {
    this.isLoading = false
    this.btnDisable = true

    const tagsId = []

    this.tags.forEach((item) => {
      tagsId.push(item.value)
    })
    let data = {
      title_en: this.enName,
      title_ar: this.arName,
      summary_en: this.enSummary,
      summary_ar: this.arSummary,
      tags: JSON.stringify(tagsId),
      thumb: this.thumbImageFile,
      cover: this.coverImageFile,
      content_en: this.content_en,
      content_ar: this.content_ar,
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/blog/add`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        this.reset()
        history.push(`/app/blog/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.btnDisable = false
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = ''
    this.newEnName = ''
    this.imageFile = null
    this.en_name_validation = null
    this.ar_name_validation = null
    this.imageFile_validation = null
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.search = ''

    this.searchOn = ['title_en']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.ar_name_validation = null

    this.btnDisable = false

    this.enName = ''

    this.arName = ''

    this.content_en = ''

    this.content_ar = ''

    this.coverImageFile = null

    this.thumbImageFile = null

    this.tags = null

    this.tagsList = []

    this.enSummary = ''

    this.arSummary = ''

    this.coverImage_validation = null

    this.content_validation = null

    this.thumbImage_validation = null

    this.en_name_validation = null

    this.tag_validation = null

    this.summary_validation = null
  }
}
