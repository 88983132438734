import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardText,
  CardImg,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import DeleteModal from '../../../components/Modal/deleteModal'
import ImageGalleryCard from '../../../components/imageGalleryCard/index'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import ItemModal from '../../../components/Modal/ItemModal'
import CollectionModal from '../../../components/Modal/collectionModal'
import { ToastContainer, toast } from 'react-toastify'
import Uploader from '../../../components/Uploader/index'
// import SelectInput from '../../../components/select/index'
// import ImageUploader from '../../../components/Uploader/Uploader'

@inject('stores')
@observer
class ProductDetail extends Component {
  @observable
  store = this.props.stores.productDetail
  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, available: true }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getProductDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }
  handleHasDiscount = (target, value) => {
    this.setState({ discount: value })
  }

  handleIsAvailable = (target, value) => {
    this.setState({ available: value })
  }

  setData = async (value, target, isMulti) => {
    await this.setState({ data: value, target, isMulti })
    this.store.changeOpenItemModal()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (!this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />

        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            {this.store.openProductModal ? (
              <CollectionModal
                openModal={this.store.openProductModal}
                data={this.store.products}
                loading={this.store.isLoadingProduct}
                toggleModal={() => this.store.toggleProductModal()}
                pagination={this.store.paginationProduct}
                limit={this.store.limitProduct}
                currentPage={this.store.currentPageProduct}
                changePage={(e) =>
                  this.store.changePage(e, this.state.id, this.props.history)
                }
                target="product"
                selectedItems={this.store.productList}
                addItems={(data) => this.store.addItemToSelected(data)}
              ></CollectionModal>
            ) : null}
            {this.store.openItemModal ? (
              <ItemModal
                openModal={this.store.openItemModal}
                toggleModal={() => this.store.toggleItemModal()}
                target={this.state.target}
                data={this.state.data}
                addItems={(data, target) => {
                  this.store.setItems(data, target)
                }}
                isMulti={this.state.isMulti}
              ></ItemModal>
            ) : null}
            <Colxx xxs="12">
              <Colxx
                xxs="12"
                className="d-flex flex-wrap align-items-center mb-3"
              >
                <Colxx xxs="12" sm="4" md="6">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.product" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx
                  xxs="12"
                  sm="8"
                  md="6"
                  className="d-flex  justify-content-sm-end justify-content-center "
                >
                  <Button
                    color="primary"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() =>
                      this.store.validation(this.state.id, this.props.history)
                    }
                  >
                    <IntlMessages id="pages.save" />
                  </Button>

                  <Button
                    color="btn-outline-secondary"
                    className="btn-shadow float-right ml-2"
                    size="lg"
                    onClick={() => this.store.changeOpenDeleteModal()}
                  >
                    <IntlMessages id="pages.delete" />
                  </Button>
                  <DeleteModal
                    target={
                      this.store.productDetail
                        ? this.store.productDetail.name_en
                        : null
                    }
                    openModal={this.store.openDeleteModal}
                    toggleModal={() => this.store.toggleDeleteModal()}
                    action={() =>
                      this.store.deleteProduct(
                        this.state.id,
                        this.props.history
                      )
                    }
                  />
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <CardTitle></CardTitle>
                  <Row>
                    <Colxx
                      xxs="12"
                      md="6"
                      lg="4"
                      className="mb-4 d-flex flex-column align-item-center"
                    >
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          {this.store.showUploader ? (
                            <>
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploader()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Add New Image</strong>
                                </Label>
                              </Button>
                              <ImageGalleryCard
                                imageSize={{
                                  imageWidth: '80%',
                                  imageLeft: '10%',
                                  imageTop: 5,
                                }}
                                data={
                                  this.store.productDetail &&
                                  this.store.productDetail.images
                                }
                                deleteImage={(id) =>
                                  this.store.handleDeleteImage(
                                    this.state.id,
                                    id,
                                    this.props.history
                                  )
                                }
                                changeOrderImages={(arr) =>
                                  this.store.changeOrderImages(arr)
                                }
                              />
                            </>
                          ) : (
                            <Uploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              ratio="1:1"
                              size={{ width: 1000, height: 1000 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploader()
                              }
                              changeImageFile={(data) => {
                                this.store.changeImageFile(
                                  data,
                                  this.state.id,
                                  this.props.history
                                )
                              }}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx xxs="12" md="6" lg="4" className="mb-4 ">
                      <Card className="mb-2 h-100">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.available" />
                            </p>

                            <div className="d-flex align-items-center col-12 p-0">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse"
                                name="available"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.is_available
                                    : false
                                }
                                onChange={() => this.store.handleIsAvailable()}
                              />
                            </div>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.name_en
                                    : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.name_ar
                                    : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              Is Subscribe
                            </p>
                            <div className="mb-0">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse"
                                name={'is_default'}
                                value={this.store.productDetail.is_subscribe}
                                onChange={() =>
                                  this.store.changeSubscribeSwitch()
                                }
                                // onBlur={handleBlur}
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          {this.store.productDetail.is_subscribe
                            ? this.store.productDetail.subscribeValues.map(
                                (item, index) => (
                                  <div
                                    className="col-12 border p-0 pt-3 d-flex flex-wrap mb-3 position-relative"
                                    key={index}
                                    style={{ borderRadius: 8 }}
                                  >
                                    <Button
                                          onClick={() =>
                                            this.store.removeSubscribe(index)
                                          }
                                          color="primary"
                                          style={{
                                            position: 'absolute',
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                            padding: '0',
                                            right: '5px',
                                            top: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            zIndex: 1,
                                          }}
                                        >
                                          <i
                                            style={{ fontSize: '20px' }}
                                            className="iconsminds-close"
                                          />
                                        </Button>
                                    <Colxx xxs="12" lg="12" className="mb-3">
                                      <p className="text-muted text-small mb-2">
                                        <IntlMessages id="pages.price" />
                                        <span className="req"> *</span>
                                      </p>
                                      <div className="mb-0">
                                        <Input
                                          name="price"
                                          value={item.price ? item.price : ''}
                                          type="number"
                                          min="0"
                                          onChange={(e) =>
                                            this.store.changeSubscribeValue(
                                              index,
                                              'price',
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Colxx>
                                    <Colxx xxs="12" lg="12" className="mb-3">
                                      <p className="text-muted text-small mb-2">
                                        <IntlMessages id="pages.weight" />
                                        <span className="req"> *</span>
                                      </p>
                                      <div className="mb-0">
                                        <Input
                                          name="weight"
                                          value={item.weight ? item.weight : ''}
                                          type="number"
                                          min="0"
                                          onChange={(e) =>
                                            this.store.changeSubscribeValue(
                                              index,
                                              'weight',
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Colxx>

                                    {/* <Colxx xxs="12" lg="12" className="mb-3">
                                      <p className="text-muted text-small mb-2">
                                        Interval
                                        <span className="req"> *</span>
                                      </p>

                                      <SelectInput
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        value={
                                          item.interval ? item.interval : null
                                        }
                                        onChange={(value) =>
                                          this.store.changeSubscribeValue(
                                            index,
                                            'interval',
                                            value.selectedOption
                                          )
                                        }
                                        options={this.store.intervalList}
                                        isMulti={false}
                                      />
                                    </Colxx> */}
                                    <Colxx xxs="12" lg="12" className="mb-3">
                                      <p className="text-muted text-small mb-2">
                                        <IntlMessages id="pages.dimensions" />
                                      </p>
                                      <div className="mb-0 d-flex ">
                                        <Colxx
                                          xxs="4"
                                          className="pl-0 pr-2 ml-0"
                                        >
                                          <Input
                                            name="width"
                                            value={item.dimension_x}
                                            type="number"
                                            min="0"
                                            onChange={(e) =>
                                              this.store.changeSubscribeValue(
                                                index,
                                                'dimension_x',
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Colxx>
                                        <Colxx
                                          xxs="4"
                                          className="pl-1 pr-1 ml-0"
                                        >
                                          <Input
                                            name="height"
                                            value={item.dimension_y}
                                            type="number"
                                            min="0"
                                            onChange={(e) =>
                                              this.store.changeSubscribeValue(
                                                index,
                                                'dimension_y',
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Colxx>
                                        <Colxx
                                          xxs="4"
                                          className="pl-2 ml-0 pr-0"
                                        >
                                          <Input
                                            name="length"
                                            value={item.dimension_z}
                                            type="number"
                                            min="0"
                                            onChange={(e) =>
                                              this.store.changeSubscribeValue(
                                                index,
                                                'dimension_z',
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Colxx>
                                      </div>
                                    </Colxx>

                                    <Colxx xxs="12" lg="12" className="mb-3">
                                      <p className="text-muted text-small mb-2">
                                        <IntlMessages id="pages.sku" />
                                        {/* <span className="req"> *</span> */}
                                      </p>
                                      <div className="mb-0">
                                        <Input
                                          name="sku"
                                          value={item.sku ? item.sku : ''}
                                          type="text"
                                          onChange={(e) =>
                                            this.store.changeSubscribeValue(
                                              index,
                                              'sku',
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Colxx>
                                  </div>
                                )
                              )
                            : null}

                          {this.store.productDetail.is_subscribe && (
                            <div
                              className="mb-3 col-12"
                              style={{ height: '50px' }}
                            >
                              <Button
                                color="primary"
                                className="btn-shadow float-right col-12 mb-3"
                                size="lg"
                                onClick={() => this.store.addSubscribe()}
                              >
                                New Subscribe
                              </Button>
                            </div>
                          )}
                          {!this.store.productDetail.is_subscribe ? (
                            <>
                              {' '}
                              <Colxx xxs="12" lg="12" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.price" />
                                  <span className="req"> *</span>
                                </p>
                                <div className="mb-0">
                                  <Input
                                    name="price"
                                    value={
                                      this.store.productDetail
                                        ? this.store.productDetail.price
                                        : ''
                                    }
                                    type="number"
                                    min="0"
                                    onChange={(e) =>
                                      this.store.changePrice(e.target.value)
                                    }
                                  />
                                </div>
                                <span className="req-validation ">
                                  {this.store.price_validation
                                    ? this.store.price_validation.msg
                                      ? this.store.price_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                              <Colxx xxs="12" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.discount" />
                                </p>

                                <div className="d-flex align-items-center col-12 p-0">
                                  <FormikSwitch
                                    className="custom-switch custom-switch-primary-inverse"
                                    name="discount"
                                    value={this.store.discount}
                                    onChange={(e) =>
                                      this.store.changeDiscount(
                                        !this.store.discount
                                      )
                                    }
                                  />
                                </div>
                              </Colxx>
                              {this.store.discount ? (
                                <Colxx xxs="12" lg="12" className="mb-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.oldPrice" />
                                    <span className="req"> *</span>
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="oldPrice"
                                      value={
                                        this.store.productDetail
                                          ? this.store.productDetail.old_price
                                          : ''
                                      }
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        this.store.changeOldPrice(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <span className="req-validation ">
                                    {this.store.price_validation
                                      ? this.store.price_validation.msg
                                        ? this.store.price_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </Colxx>
                              ) : null}
                              <Colxx xxs="12" lg="12" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.weight" />
                                  <span className="req"> *</span>
                                </p>
                                <div className="mb-0">
                                  <Input
                                    name="weight"
                                    value={
                                      this.store.productDetail
                                        ? this.store.productDetail.weight
                                        : ''
                                    }
                                    type="number"
                                    min="0"
                                    onChange={(e) =>
                                      this.store.changeWeight(e.target.value)
                                    }
                                  />
                                </div>
                                <span className="req-validation ">
                                  {this.store.weight_validation
                                    ? this.store.weight_validation.msg
                                      ? this.store.weight_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                              <Colxx xxs="12" lg="12" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.sku" />
                                  <span className="req"> *</span>
                                </p>
                                <div className="mb-0">
                                  <Input
                                    name="name"
                                    value={
                                      this.store.productDetail
                                        ? this.store.productDetail.sku
                                        : ''
                                    }
                                    type="text"
                                    onChange={(e) =>
                                      this.store.changeSku(e.target.value)
                                    }
                                  />
                                </div>
                                <span className="req-validation ">
                                  {this.store.sku_validation
                                    ? this.store.sku_validation.msg
                                      ? this.store.sku_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                              <Colxx xxs="12" lg="12" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.dimensions" />
                                </p>
                                <div className="mb-0 d-flex ">
                                  <Colxx xxs="4" className="pl-0 pr-2 ml-0">
                                    <Input
                                      name="width"
                                      value={
                                        this.store.productDetail.dimension_x
                                      }
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        this.store.changeWidth(e.target.value)
                                      }
                                    />
                                    <span className="req-validation ">
                                      {this.store.width_validation
                                        ? this.store.width_validation.msg
                                          ? this.store.width_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                  <Colxx xxs="4" className="pl-1 pr-1 ml-0">
                                    <Input
                                      name="height"
                                      value={
                                        this.store.productDetail.dimension_y
                                      }
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        this.store.changeHeight(e.target.value)
                                      }
                                    />
                                    <span className="req-validation ">
                                      {this.store.height_validation
                                        ? this.store.height_validation.msg
                                          ? this.store.height_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                  <Colxx xxs="4" className="pl-2 ml-0 pr-0">
                                    <Input
                                      name="length"
                                      value={
                                        this.store.productDetail.dimension_z
                                      }
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        this.store.changeLength(e.target.value)
                                      }
                                    />
                                    <span className="req-validation ">
                                      {this.store.length_validation
                                        ? this.store.length_validation.msg
                                          ? this.store.length_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                </div>
                              </Colxx>
                            </>
                          ) : null}

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.tag" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.productDetail.tags.map(
                                (item, index) => (
                                  <Card
                                    key={index}
                                    className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                    style={{
                                      width: 'max-content',
                                      maxWidth: '100%',
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ height: 40 }}
                                    >
                                      <CardText className="text-small pl-2 pr-2 m-0">
                                        {item.name_en}
                                      </CardText>
                                      <i
                                        className="iconsminds-close remove-icon"
                                        onClick={() =>
                                          this.store.removeTag(item)
                                        }
                                      ></i>
                                    </div>
                                  </Card>
                                )
                              )}
                              <Button
                                color="primary"
                                className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                onClick={() => {
                                  this.setData(this.store.tagsList, 'tag', true)
                                }}
                                style={{ height: 40, width: 70 }}
                              >
                                {' '}
                                <IntlMessages id="pages.add" />
                                <i className="iconsminds-add" />
                              </Button>
                            </div>
                            <span className="req-validation ">
                              {this.store.tag_validation
                                ? this.store.tag_validation.msg
                                  ? this.store.tag_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.brand" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.brand && this.store.brand && (
                                <Card className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1">
                                  <div className=" d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            this.store.brand.image
                                              ? this.store.brand.image.url
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText
                                      className="text-small pl-2 pr-2 m-0 d-flex "
                                      style={{ flex: 1 }}
                                    >
                                      {this.store.brand.name_en}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon "
                                      onClick={() =>
                                        this.store.removeBrand(this.store.barnd)
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              )}
                              {this.store.productDetail && !this.store.brand ? (
                                <Button
                                  color="primary"
                                  className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                  onClick={() => {
                                    this.setData(
                                      this.store.brandsList,
                                      'brand',
                                      false
                                    )
                                  }}
                                  style={{ height: 40, width: 70 }}
                                >
                                  {' '}
                                  <IntlMessages id="pages.add" />
                                  <i className="iconsminds-add" />
                                </Button>
                              ) : null}
                            </div>
                            <span className="req-validation ">
                              {this.store.brand_validation
                                ? this.store.brand_validation.msg
                                  ? this.store.brand_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.category" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.productDetail &&
                                this.store.productDetail.category && (
                                  <Card className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1">
                                    <div className=" d-flex align-items-center">
                                      <div
                                        className=" p-0 m-0"
                                        style={{ width: '40px' }}
                                      >
                                        <div
                                          className="position-relative col-12 m-0"
                                          style={{ paddingTop: '100%' }}
                                        >
                                          <CardImg
                                            src={
                                              this.store.productDetail.category
                                                .image
                                                ? this.store.productDetail
                                                    .category.image
                                                : '/assets/img/noimage.png'
                                            }
                                            style={{ borderRadius: '50%' }}
                                          ></CardImg>
                                        </div>
                                      </div>
                                      <CardText
                                        className="text-small pl-2 pr-2 m-0 d-flex "
                                        style={{ flex: 1 }}
                                      >
                                        {
                                          this.store.productDetail.category
                                            .name_en
                                        }
                                      </CardText>
                                      <i
                                        className="iconsminds-close remove-icon "
                                        onClick={() =>
                                          this.store.removeCategory(
                                            this.store.productDetail.category
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </Card>
                                )}
                              {this.store.productDetail &&
                              !this.store.productDetail.category ? (
                                <Button
                                  color="primary"
                                  className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                  onClick={() => {
                                    this.setData(
                                      this.store.categoriesList,
                                      'category',
                                      false
                                    )
                                  }}
                                  style={{ height: 40, width: 70 }}
                                >
                                  {' '}
                                  <IntlMessages id="pages.add" />
                                  <i className="iconsminds-add" />
                                </Button>
                              ) : null}
                            </div>
                            <span className="req-validation ">
                              {this.store.category_validation
                                ? this.store.category_validation.msg
                                  ? this.store.category_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.make" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.productDetail.brew_types.map(
                                (item, index) => (
                                  <Card
                                    key={index}
                                    className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                    style={{
                                      width: 'max-content',
                                      maxWidth: '100%',
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div
                                        className=" p-0 m-0"
                                        style={{ width: '40px' }}
                                      >
                                        <div
                                          className="position-relative col-12 m-0"
                                          style={{ paddingTop: '100%' }}
                                        >
                                          <CardImg
                                            src={
                                              item.image
                                                ? item.image.url
                                                : '/assets/img/noimage.png'
                                            }
                                            style={{ borderRadius: '50%' }}
                                          ></CardImg>
                                        </div>
                                      </div>
                                      <CardText className="text-small pl-2 pr-2 m-0">
                                        {item.name_en}
                                      </CardText>
                                      <i
                                        className="iconsminds-close remove-icon"
                                        onClick={() =>
                                          this.store.removeBrew(item)
                                        }
                                      ></i>
                                    </div>
                                  </Card>
                                )
                              )}
                              <Button
                                color="primary"
                                className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                onClick={() => {
                                  this.setData(
                                    this.store.brewsList,
                                    'brew',
                                    true
                                  )
                                }}
                                style={{ height: 40, width: 70 }}
                              >
                                {' '}
                                <IntlMessages id="pages.add" />
                                <i className="iconsminds-add" />
                              </Button>
                            </div>
                            <span className="req-validation ">
                              {this.store.brew_validation
                                ? this.store.brew_validation.msg
                                  ? this.store.brew_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          {this.store.productDetail.is_subscribe && (
                            <>
                              {' '}
                              <Colxx xxs="12" lg="12" className="mb-3">
                                <p className="text-muted text-small mb-2">
                                  Roast
                                  <span className="req"> *</span>
                                </p>
                                <div
                                  className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                                  style={{ border: '1px solid #ddd' }}
                                >
                                  {this.store.productDetail.roast_types.map(
                                    (item, index) => (
                                      <Card
                                        key={index}
                                        className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                        style={{
                                          width: 'max-content',
                                          maxWidth: '100%',
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <div
                                            className=" p-0 m-0"
                                            style={{ width: '40px' }}
                                          >
                                            <div
                                              className="position-relative col-12 m-0"
                                              style={{ paddingTop: '100%' }}
                                            >
                                              <CardImg
                                                src={
                                                  item.image
                                                    ? item.image.url
                                                    : '/assets/img/noimage.png'
                                                }
                                                style={{ borderRadius: '50%' }}
                                              ></CardImg>
                                            </div>
                                          </div>
                                          <CardText className="text-small pl-2 pr-2 m-0">
                                            {item.name_en}
                                          </CardText>
                                          <i
                                            className="iconsminds-close remove-icon"
                                            onClick={() =>
                                              this.store.removeRoast(item)
                                            }
                                          ></i>
                                        </div>
                                      </Card>
                                    )
                                  )}
                                  <Button
                                    color="primary"
                                    className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                    onClick={() => {
                                      this.setData(
                                        this.store.roastList,
                                        'roast',
                                        true
                                      )
                                    }}
                                    style={{ height: 40, width: 70 }}
                                  >
                                    {' '}
                                    <IntlMessages id="pages.add" />
                                    <i className="iconsminds-add" />
                                  </Button>
                                </div>
                                <span className="req-validation ">
                                  {this.store.roast_validation
                                    ? this.store.roast_validation.msg
                                      ? this.store.roast_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                            </>
                          )}

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description" />
                            </p>
                            <p className="mb-3">
                              <Input
                                name="description"
                                value={
                                  this.store.productDetail.description_en
                                    ? this.store.productDetail.description_en
                                    : ''
                                }
                                type="textarea"
                                onChange={(e) =>
                                  this.store.changeEnDescription(e.target.value)
                                }
                              />
                            </p>
                          </Colxx>

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description-ar" />
                            </p>
                            <p className="mb-3">
                              <Input
                                className="ar"
                                name="description_ar"
                                value={
                                  this.store.productDetail.description_ar
                                    ? this.store.productDetail.description_ar
                                    : ''
                                }
                                type="textarea"
                                onChange={(e) =>
                                  this.store.changeArDescription(e.target.value)
                                }
                              />
                            </p>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>

                    <Colxx
                      xxs="12"
                      md="12"
                      lg="4"
                      className="mb-4"
                      style={{ height: 'max-content' }}
                    >
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Similar Product`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx
                            xxs="12"
                            className="p-0 m-0 mb-3 d-flex flex-wrap"
                          >
                            {this.store.productList
                              ? this.store.productList.map((item) => (
                                  <a
                                    key={item.id}
                                    href={`/app/product/detail/${item.id}`}
                                    className="p-1 col-6"
                                  >
                                    <Card className="col-12 m-0 p-0">
                                      <div
                                        className="position-relative col-12"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <p
                                          className="pointer m-0 position-absolute text-truncate"
                                          onClick={() => {
                                            this.props.history.push(
                                              `/app/product/detail/${item.id}`
                                            )
                                          }}
                                          style={{
                                            width: '90%',
                                            height: '35px',
                                            borderRadius: '0.75rem',
                                            padding: '8px',
                                            right: '5%',
                                            top: '33%',
                                            zIndex: 1,
                                            color: 'white',
                                            backgroundColor:
                                              'rgba(0, 0, 0, 0.75',
                                          }}
                                        >
                                          {item.name}
                                        </p>
                                        <CardImg
                                          src={
                                            item.images
                                              ? item.images.url
                                              : '/assets/img/noimage.png'
                                          }
                                        ></CardImg>
                                      </div>
                                    </Card>
                                  </a>
                                ))
                              : null}
                          </Colxx>
                          <Button
                            color="primary"
                            size="lg"
                            className="pointer mb-4 col-12"
                            style={{}}
                            onClick={() =>
                              this.store.getProduct(
                                this.state.id,
                                this.props.history
                              )
                            }
                          >
                            <Label
                              className="btn btn-default no-padding text-capitalize"
                              style={{
                                padding: '0px',
                                marginBottom: '0px',
                              }}
                            >
                              <strong>Add or remove similar items</strong>{' '}
                            </Label>
                          </Button>
                          <Colxx xxs="12" lg="12" className="mb-3"></Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default ProductDetail
