import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HomePageList from './list';
import HomePageDetail from './detail';


const HomePage = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={HomePageList} />
            <Route path={`${match.url}/detail/:id`} component={HomePageDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default HomePage;