import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
// import { checkInput } from "../constants/validation";
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class BannerDetailStore {
  @observable
  bannerDetail = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 'all'

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  imageFileEn = null

  @observable
  imageFileAr = null

  @observable
  itemList = null

  @observable
  productsList = []

  @observable
  selectedProduct = null

  @observable
  response = null

  @observable
  showUploaderEnglish = true

  @observable
  showUploaderArabic = true

  @action
  handleImageUploaderEnglish = () => {
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  handleImageUploaderArabic = () => {
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderEnglish(file) {
    this.imageFileEn = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderEnglish = !this.showUploaderEnglish
  }


  @action
  async changeImageFileUploderArabic(file) {
    this.imageFileAr = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  async getBannerDetail(id, history) {
    this.isLoading = true
    this.imageFile = null
    const value = await axiFetch.get('banner', null, id, history)
    if (value) {
      this.bannerDetail = value.data

      await this.getDependencies(history)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const body = {
      order: { id: -1 },
      searchOn: null,
      search: null,
      limit: 12,
      offset: 0,
      all: true,
    }

    const product = await axiFetch.post('product', body, 'list', history)

    if (product) {
      await product.data.items.forEach((item) =>
        this.productsList.push({ value: item.id, label: item.name })
      )
      product.data.items.forEach((item) => {
        if (item.id === this.bannerDetail.target_id) {
          this.selectedProduct = { value: item.id, label: item.name }
        }
      })
    }
  }

  @action
  deleteBanner = (id, history) => {
    this.isLoading = true
    axios
      .get(`${servicePath}/banner/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.reset()
        this.openDeleteModal = false
        history.push('/app/slider')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  async handleUpdateBanner(id, history) {
    this.isLoading = true
    let data = {
      target: 'product',
      target_id: this.selectedProduct.value,
      is_active: this.bannerDetail.is_active,
    }

    if (this.imageFileEn) {
      data.image_en = this.imageFileEn
    }

    if (this.imageFileAr) {
      data.image_ar = this.imageFileAr
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    axios
      .post(`${servicePath}/banner/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(() => {
        this.reset()
        this.getBannerDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFileEn = (file) => {
    this.imageFileEn = file
  }

  @action
  changeImageFileAr = (file) => {
    this.imageFileAr = file
  }

  @action
  setProduct(data) {
    this.selectedProduct = data.selectedOption
  }

  @action
  handleIsSupported() {
    this.bannerDetail.is_active = this.bannerDetail.is_active ? 0 : 1
  }

  @action
  reset() {
    this.bannerDetail = null

    this.search = ''

    this.searchOn = null

    this.limit = 'all'

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.openDeleteModal = false

    this.imageFileEn = null

    this.imageFileAr = null

    this.itemList = null

    this.productsList = []

    this.selectedProduct = null

    this.showUploaderEnglish = true

    this.showUploaderArabic = true
  }
}
