import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
// import axiFetch from "../config/fetch";

export default class BrewStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  categoryDetail = null

  @observable
  search = ''

  @observable
  searchOn = ['name_en']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  openDeleteModal = false

  @observable
  imageFile = null

  @observable
  newArName = ''

  @observable
  newEnName = ''

  @observable
  imageFile_validation = null

  @observable
  ar_name_validation = null

  @observable
  en_name_validation = null

  @observable
  btnDisable = false

  @observable
  response = null

  @action
  getData = async (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }

    axios
      .post(`${servicePath}/brew/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data.items
        this.pagination =
          parseInt(data.data.pagination.total / this.limit) +
          (data.data.pagination.total % this.limit ? 1 : 0)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })
    this.imageFile_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation &&
      this.handleSaveNewBrew(history)
  }

  @action
  handleSaveNewBrew = (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
      icon: this.imageFile,
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/brew/add`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        this.reset()
        history.push(`/app/brew/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  changeNewEnName = (value) => {
    this.newEnName = value
  }

  @action
  changeNewArName = (value) => {
    this.newArName = value
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = ''
    this.newEnName = ''
    this.imageFile = null
    this.en_name_validation = null
    this.ar_name_validation = null
    this.imageFile_validation = null
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file) {
    this.imageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.categoryDetail = null

    this.search = ''

    this.searchOn = ['name_en']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.openDeleteModal = false

    this.imageFile = null

    this.newArName = ''

    this.newEnName = ''

    this.imageFile_validation = null

    this.ar_name_validation = null

    this.en_name_validation = null

    this.btnDisable = false
  }
}
