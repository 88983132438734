import { observable, action } from 'mobx'
// import axios from "axios";
// import { servicePath, checkUnauthorized } from "../constants/defaultValues";
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import moment from '../../node_modules/moment/moment'

export default class CouponStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  btnDisable = false

  @observable
  currentPage = 1

  @observable
  bakeryDetail = null

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = ['code']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  code = null

  @observable
  unit = 'kd'

  @observable
  usage = 1

  @observable
  amount = 10

  @observable
  startDate = null

  @observable
  endDate = null

  @observable
  code_validation = null

  @observable
  amount_validation = null

  @observable
  usage_validation = null

  @observable
  startDate_validation = null

  @observable
  endDate_validation = null

  @action
  changeUnit = (value) => {
    this.unit = value
  }

  @action
  changeCount = (value) => {
    this.count = value
  }

  @action
  changeAmount = (value) => {
    this.amount = value
  }

  @action
  changeAmountKd = (value) => {
    this.amount_kd = value
  }

  @action
  changeUsage = (value) => {
    this.usage = value
  }

  @action
  changeStartDate = (value) => {
    this.startDate = value
  }

  @action
  changeEndDate = (value) => {
    this.endDate = value
  }

  @action
  changeCode = (value) => {
    this.code = value
  }

  @action
  async validation(history) {
    if (this.endDate && this.startDate && this.endDate <= this.startDate) {
      this.endDate_validation = {
        res: false,
        msg: "'End Date' Should Be Bigger Than 'Start Date'",
      }
      return
    }

    this.amount_validation = await checkInput({
      name: 'Amount',
      value: this.amount,
      require: true,
      type: 'number',
      min: 1,
      maxValue: this.unit === '%' && 100,
    })

    this.startDate_validation = await checkInput({
      name: 'Start Date',
      value: this.startDate,
      require: true,
    })

    this.endDate_validation = await checkInput({
      name: 'End Date',
      value: this.endDate,
      require: true,
    })

    this.usage_validation = await checkInput({
      name: 'Usage',
      value: this.usage,
      require: true,
      type: 'number',
      min: 1,
    })

    if (this.code) {
      this.code_validation = await checkInput({
        name: 'Code',
        value: this.code,
        min: 4,
        max: 12,
      })
      this.usage_validation.res &&
        this.amount_validation.res &&
        this.startDate_validation.res &&
        this.endDate_validation.res &&
        this.code_validation.res &&
        this.handleSaveNewCoupon(history)
    } else {
      this.usage_validation.res &&
        this.amount_validation.res &&
        this.startDate_validation.res &&
        this.endDate_validation.res &&
        this.handleSaveNewCoupon(history)
    }
  }

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.post('coupon', body, 'list', history)
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    }
  }

  @action
  async handleSaveNewCoupon(history) {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      code: this.code ? this.code : undefined,
      unit: this.unit,
      usage: this.usage,
      amount: this.amount,
      start:
        this.startDate === ''
          ? null
          : moment(this.startDate).format('YYYY-MM-DD'),
      end:
        this.endDate === '' ? null : moment(this.endDate).format('YYYY-MM-DD'),
    }

    const value = await axiFetch.post('coupon', data, 'add', history)
    if (value) {
      this.reset()
      this.toggleModal()
      this.reset()
      this.getData(history)
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.amount = 10
    this.code = null
    this.usage = 1
    this.unit = 'kd'
    this.endDate = null
    this.startDate = null
    this.amount_validation = null
    this.usage_validation = null
    this.startDate_validation = null
    this.endDate_validation = null
    this.code_validation = null
  }

  @action
  changePage(e, history) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData(history)
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.btnDisable = false

    this.currentPage = 1

    this.imageFile = null

    this.search = ''

    this.searchOn = ['code']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.openModal = false

    this.code = null

    this.unit = 'kd'

    this.usage = 1

    this.amount = 10

    this.startDate = null

    this.endDate = null

    this.code_validation = null

    this.amount_validation = null

    this.usage_validation = null

    this.startDate_validation = null

    this.endDate_validation = null
  }
}
