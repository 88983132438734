import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class BannerStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFileEn = null

  @observable
  imageFileAr = null

  @observable
  en_imageFile_validation = null

  @observable
  ar_imageFile_validation = null

  @observable
  product_validation = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  btnDisable = false

  @observable
  productsList = []

  @observable
  selectedProduct = null

  @observable
  response = null

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.post('banner', body, 'list', history)
    if (value) {
      this.data = value.data.items
      // await this.getDependencies(history);
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    if (!this.productsList.length) {
      const body = {
        order: { id: -1 },
        searchOn: null,
        search: null,
        limit: 12,
        offset: 0,
        all: true,
      }

      const product = await axiFetch.post('product', body, 'list', history)

      if (product) {
        await product.data.items.forEach((item) => {
          this.productsList.push({ value: item.id, label: item.name })
        })
      }
    }
  }

  @action
  async validation(history) {
    this.en_imageFile_validation = await checkInput({
      name: 'English Image',
      value: this.imageFileEn,
      require: true,
    })

    this.ar_imageFile_validation = await checkInput({
      name: 'Arabic Image',
      value: this.imageFileAr,
      require: true,
    })

    this.product_validation = await checkInput({
      name: 'Product',
      value: this.selectedProduct,
      require: true,
    })

    this.ar_imageFile_validation.res &&
      this.en_imageFile_validation.res &&
      this.product_validation.res &&
      this.handleSaveNewBanner(history)
  }

  @action
  handleSaveNewBanner = (history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      target: 'product',
      target_id: this.selectedProduct.value,
      image_en: this.imageFileEn,
      image_ar: this.imageFileAr,
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    axios
      .post(`${servicePath}/banner/add`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.toggleModal()
        this.reset()
        history.push(`/app/slider/banner/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  setProduct(data) {
    this.selectedProduct = data.selectedOption
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false

    this.imageFileEn = null

    this.imageFileAr = null

    this.en_imageFile_validation = null

    this.ar_imageFile_validation = null

    this.product_validation = null
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileEn(file) {
    this.imageFileEn = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeImageFileAr = (file) => {
    this.imageFileAr = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFileEn = null

    this.imageFileAr = null

    this.en_imageFile_validation = null

    this.ar_imageFile_validation = null

    this.product_validation = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.openModal = false

    this.btnDisable = false

    this.productsList = []

    this.selectedProduct = []
  }
}
