import axios from "axios";
import api from "../api";
import {checkUnauthorized } from "../../constants/defaultValues";


export default new class {
  /**
   * Get Data from Server By Axios Methods
   *
   * @param {string} resource resource name in url section
   * @param {object} header header object
   * @param {object} body body object
   * @param {number} timeout timeout by MS . Defualt is 0
   *
   * @returns {object | Array} by model of data u request
   *
   * @example
   *
   * fetch.get("url",{header},{body},3000)
   */
  get(resource, body, id, history ) {
    const header = {
      headers: { "Content-Type": "application/json;charset=UTF-8" }
    };
    return this._detailRequestMaker(resource, "get", header, body, id, history );
  }

  

  /**
   * Post Data to Server By Axios Methods
   *
   * @param {string} resource resource name in url section
   * @param {object} header header object
   * @param {object} body body object
   * @param {number} timeout timeout by MS . Defualt is 0
   *
   * @returns {object | list} by model of data u request
   *
   * @example
   *
   * fetch.post("url",{header},{body},3000)
   */
  post(resource, body, type, history) {
    const header = {
      headers: { "Content-Type": "application/json;charset=UTF-8" }
    };
    return this._requestMaker(resource, "post", header, body, type, history);
  }





  /**
   * Put Data to Server By Axios Methods
   *
   * @param {string} resource resource name in url section
   * @param {object} header header object
   * @param {object} body body object
   * @param {number} timeout timeout by MS . Defualt is 0
   *
   * @returns {object | list} by model of data u request
   *
   * @example
   *
   * fetch.put("url",{header},{body},3000)
   */
  put(resource, body, id) {
    const header = {
      headers: { "Content-Type": "application/json;charset=UTF-8" }
    };
    return this._updateRequestMaker(resource, "post", header, body, id);
  }




  /**
   * Delete Data from Server By Axios Methods
   *
   * @param {string} resource resource name in url section
   * @param {object} header header object
   * @param {object} body body object
   * @param {number} timeout timeout by MS . Defualt is 0
   *
   * @returns {object | list} by model of data u request
   *
   * @example
   *
   * fetch.del("url",{header},{body},3000)
   */
  del(resource, body, id, type, history) {
    const header = {
      headers: { "Content-Type": "application/json;charset=UTF-8" }
    };
    return this._deleteRequestMaker(
      resource,
      "get",
      header,
      body,
      id,
      type,
      history
    );
  }

  async _requestMaker(url, method, header, body, type, history) {
    try {
      let res = await axios.request(
        this._configrator(
          api.appAPI(url, method, type),
          method,
          header,
          body,
        )
      );
      return res.data;
    } catch (e) {
      this._handeError(e, history);
      return null;
    }
  }

  async _deleteRequestMaker(url, method, header, body, id, type, history) {
    try {
      let res = await axios.request(
        this._configrator(
          api.deleteAPI(url, method, id, type),
          method,
          header,
          body,
        )
      );
      return res.data;
    } catch (e) {
      this._handeError(e, history);
      return null;
    }
  }


  async _detailRequestMaker(url, method, header, body, id, history) {
    try {
      let res = await axios.request(
        this._configrator(
          api.detailAPI(url, id),
          method,
          header,
          body,
        )
      );
      return res.data;
    } catch (e) {
      this._handeError(e, history);
      return null;
    }
  }


  async _updateRequestMaker(url, method, header, body, id, history) {
    try {
      let res = await axios.request(
        this._configrator(
          api.detailAPI(url, id),
          method,
          header,
          body,
        )
      );
      return res.data;
    } catch (e) {
      this._handeError(e, history);
      return null;
    }
  }

  _configrator(url, method, header, body, timeout) {
    let config = {
      withCredentials: true
    };

    // `url` is the server URL that will be used for the request
    config.url = url;

    // `method` is the request method to be used when making the request
    config.method = method;

    // `headers` are custom headers to be sent
    if (header) {
      config.headers = header;
    }

    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
    // When no `transformRequest` is set, must be of one of the following types:
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - Browser only: FormData, File, Blob
    // - Node only: Stream, Buffer
    if (body) {
      config.data = body;
    } else {
      config.data = {};
    }

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    // default is `0` (no timeout)
    if (timeout) {
      config.timeout = timeout;
    }

    // `responseType` indicates the type of data that the server will respond with
    // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    // config.responseType = "application/json"; // TEMP Disabled

    // `responseEncoding` indicates encoding to use for decoding responses
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    config.responseEncoding = "utf8";
    return config;
  }

  /**
   * Error Handelling
   * @param {object} error Just give me th error
   */
  _handeError(error, history) {
    this._logging(error, history)
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      this._logging(error.data );
      this._logging(error.status);
      this._logging(error.headers);
      this._logging(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      this._logging(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      this._logging(error.message);
    }
    this._logging(error.config);
  }

  /**
   * Error Printer
   * @param {object} error
   */
  _logging(error, history) {
    console.log(error);
    if(history)checkUnauthorized(error, history);
    // if(error &&error.status===403){
    //   history.push('./login')
    // }
  }
}();
