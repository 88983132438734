import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
// import { checkInput } from "../constants/validation";

export default class DeliveryTimeStore {
  @observable
  data = null

  @observable
  link = null

  @observable
  validationMsg = null

  @observable
  isLoading = false

  @observable
  response = null

  @observable
  startTimeList = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 },
    { label: "16", value: 16 },
    { label: "17", value: 17 },
    { label: "18", value: 18 },
    { label: "19", value: 19 },
    { label: "20", value: 20 },
    { label: "21", value: 21 },
    { label: "22", value: 22 },
    { label: "23", value: 23 },
  ]

  endTimeList = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 },
    { label: "16", value: 16 },
    { label: "17", value: 17 },
    { label: "18", value: 18 },
    { label: "19", value: 19 },
    { label: "20", value: 20 },
    { label: "21", value: 21 },
    { label: "22", value: 22 },
    { label: "23", value: 23 },
    { label: "24", value: 24 },
  ]

  @observable
  daysList = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
  ]

  @action
  validation = (history) => {
    this.handleSaveSocial(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = false

    axios
      .get(`${servicePath}/delivery`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data
        this.data.forEach((item) => {
          if (
            item.field === 'delivery_start'
          ) {
            item.options = this.startTimeList
            this.startTimeList.forEach(time=>{
              if(item.value===time.label){
                item.value=time
              }
            })
          }
          if (
            item.field === 'delivery_from_today'
          ) {
            item.options = this.daysList
            this.daysList.forEach(time=>{
              if(item.value===time.label){
                item.value=time
              }
            })
          }

          if (
            item.field === 'delivery_end'
          ) {
            item.options = this.endTimeList
            this.endTimeList.forEach(time=>{
              if(item.value===time.label){
                item.value=time
              }
            })
          }
        })
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  changeDeliveryValue = (target, value) => {
    console.log(target, value)
    this.data.forEach((item) => {
      if (item.field === target) {
        item.value = value.selectedOption
      }
    })
  }

  @action
  handleSaveSocial = async (history) => {
    this.isLoading = false
    const field = []
    await this.data.forEach((item) => {
      field.push({ field: item.field, value: Number(item.value.label), type: 'delivery' })
    })
    const data = { fields: field }

    axios
      .post(`${servicePath}/delivery/set`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  reset = () => {
    this.data = null

    this.link = null

    this.validationMsg = null

    this.isLoading = false
  }
}
