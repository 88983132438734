import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
// import { checkInput } from "../constants/validation";

export default class SocialStore {
  @observable
  data = null

  @observable
  isLoading = false

  @observable
  response = null

  // @action
  // validation = (history) => {
  //   this.handleSaveSocial(history)
  // }

  @action
  getData = async (history) => {
    this.isLoading = false

    axios
      .get(`${servicePath}/terms/get`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  handleChangeEnContent = (data) => {
    this.data.en = data
  }

  @action
  handleChangeArContent = (data) => {
    this.data.ar = data
  }

  

  @action
  handleSaveTerms = async (history) => {
    this.isLoading = false


    axios
      .post(`${servicePath}/terms`, {en:this.data?.en|| '', ar:this.data?.ar|| ''}, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.response = { msg: 'Item has been updated successfully', type: 'success' }
        this.isLoading = true
        this.response = null
        // this.reset()
        // this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  reset = () => {
    this.data = null;

    this.isLoading = false
  }
}
