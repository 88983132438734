import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'

export default class UserStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  name = ''

  @observable
  email = ''

  @observable
  btnDisable = false

  @observable
  name_validation = null

  @observable
  email_validation = null

  @observable
  response = null

  @action
  getData = (history) => {
    this.isLoading = false
    const body = {
      // order: this.order,
      // searchOn: this.searchOn,
      // search: this.search,
      // limit: this.limit,
      // offset: this.offset,
    }
    axios
      .post(`${servicePath}/admin/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = true
      })
  }

  @action
  async validation(history) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.name,
      require: true,
      type: 'string',
      min: 3,
    })
    this.email_validation = await checkInput({
      name: 'Email',
      value: this.email,
      require: true,
      type: 'email',
    })

    this.email_validation.res &&
      this.name_validation.res &&
      this.handleSaveNewAdmin(history)
  }

  @action
  handleSaveNewAdmin = (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      name: this.name,
      email: this.email,
    }

    axios
      .post(`${servicePath}/admin/add`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        this.reset()
        history.push(`/app/user/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.btnDisable = false
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.name = ''
    this.email = ''
    this.btnDisable = false
    this.name_validation = null
    this.email_validation = null
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeName = (value) => {
    this.name = value
  }

  @action
  changePassword = (value) => {
    this.password = value
  }

  @action
  changeEmail = (value) => {
    this.email = value
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.name = ''

    this.email = ''

    this.btnDisable = false

    this.name_validation = null

    this.email_validation = null
  }
}
