import React, { Component } from 'react'
import { Card, CardText, CardBody } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { Colxx } from '../CustomBootstrap'

class CardThumbList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { data, path, opacity = 1 } = this.props
    // to={`/app/${path}/${data.id}`}
    return (
      <Colxx xxs="12" md="6" style={{ opacity: opacity }}>
        <div className="d-flex col-12">
          <Card className="d-flex flex-row mb-3 col-12 p-0 pr-2">
            {data.images || data.image || path === 'user/detail' ? (
              <img
                alt={data.name}
                src={
                  path === 'product/detail'
                    ? data.images
                      ? data.images.url
                      : '/assets/img/noimage.png'
                    : data.image
                    ? data.image.url
                    : '/assets/img/noimage.png'
                }
                className="list-thumbnail responsive border-0"
              />
            ) : null}

            {data.name && path !== 'customers/detail' ? (
              <div className="p-0 pl-3 d-flex flex-grow-1 min-width-zero">
                <div className="card-body p-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <NavLink
                    to={`/app/${path}/${data.id}`}
                    className="w-100 w-sm-100 d-flex flex-wrap align-items-center justify-content-between"
                  >
                    <p className="list-item-heading m-0 truncate">{`${data.name}`}</p>
                    {data.price ? (
                      <p className="list-item-heading m-0 pr-2 font-weight-bold truncate">{`${data.price} KD`}</p>
                    ) : data.is_subscribe ? (
                      <p className="list-item-heading m-0 font-weight-bold pr-2 truncate">
                        Is Subscribe
                      </p>
                    ) : null}
                  </NavLink>
                </div>
              </div>
            ) : null}

            {path === 'collection/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{` ${data.name_ar}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{`${
                    data.in_homepage ? 'YES' : 'No'
                  }`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'customers/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.email}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{`${
                    data.phonenumber ? data.phonenumber : ''
                  }`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'users/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-3 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name}`}</CardText>
                  <CardText className="col-xl-7 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.email}`}</CardText>
                  <CardText
                    className="col-xl-2 col-md-12 m-xl-0 mb-md-2 text-center truncate text-white pt-1 pb-1"
                    style={{
                      backgroundColor: data.type === 'admin' ? 'green' : 'red',
                      borderRadius: '0.75rem',
                    }}
                  >{`${data.type}`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'tag/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_ar}`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'area/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-5 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-5 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_ar}`}</CardText>
                  <CardText className="col-xl-2 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.posting_price} KD`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'faq/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-12 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.title_en}`}</CardText>
                  {/* <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_ar}`}</CardText> */}
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'category/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_ar}`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'faqCategory/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-6 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name_ar}`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}
          </Card>
        </div>
      </Colxx>
    )
  }
}

export default CardThumbList
