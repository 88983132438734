import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Row, Button, Card, CardBody, Input } from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import { ToastContainer, toast } from "react-toastify";


@inject("stores")
@observer
class SocialNetworkList extends Component {
  @observable
  store = this.props.stores.social;

  constructor(props) {
    super(props);
    this.state = { perPhoneNumber: "+965-" };
  }
  componentDidMount() {
    this.store.getData();
  }

  componentWillUnmount() {
    this.store.reset();
  }


  notify = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      default:
        break;
    }
  };

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type);
      }
    }
    return (
      <Fragment>
         <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="d-flex align-items-center">
                <Colxx xxs="8">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.social" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx xxs="4" className="float-right">
                  <Button
                    color="primary"
                    className="btn-shadow float-right mb-3"
                    size="lg"
                    onClick={() => this.store.validation(this.props.history)}
                  >
                    <IntlMessages id="pages.save" />
                  </Button>
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" xl="9">
                <Card>
                  <CardBody>
                    <Colxx
                      xxs="12"
                      className="d-flex align-items-center flex-wrap p-0"
                    >
                      {this.store.link
                        ? this.store.data.map((item, index)=> (
                            <Colxx
                            key={index}
                              xxs="12"
                              lg="12"
                              className="mb-3 d-flex align-items-center flex-wrap p-0"
                            >
                              <p
                                className="m-0 text-muted"
                                style={{ fontSize: 24 }}
                              >
                                <i
                                  className={`iconsminds-${
                                    item.name === "whatsapp"
                                      ? "phone-3"
                                      : item.name
                                  }`}
                                />
                              </p>

                              <p className="mb-0 col-11 d-flex">
                                {item.name === "phone" ? (
                                  <Input
                                    value={`+965-`}
                                    type="text"
                                    style={{ width: "63px" }}
                                  ></Input>
                                ) : null}
                                <Input
                                  name="instagram"
                                  value={
                                    item.name === "phone"
                                      ? item.text
                                      : item.text
                                  }
                                  type="text"
                                  onChange={e =>
                                    this.store.changeSocialAddress(
                                      item.name,
                                      e.target.value
                                    )
                                  }
                                />
                              </p>
                              <span className="req-validation col-10 pt-2 pl-5">
                                {item.name === "phone"
                                  ? this.store.validationMsg
                                    ? this.store.validationMsg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          ))
                        : null}
                    </Colxx>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </>
        )}
      </Fragment>
    );
  }
}

export default SocialNetworkList;
