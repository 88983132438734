import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  // Input,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  CardText,
  CardImg,
} from 'reactstrap'

import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
// import { Thumb } from "../../../components/Thumb";
import classnames from 'classnames'

@inject('stores')
@observer
class CustomerDetail extends Component {
  @observable
  store = this.props.stores.customerDetail

  @observable
  setting = this.props.stores.setting

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, activeFirstTab: '1' }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getCustomerDetail(path)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  toggleTab = (id) => {
    this.setState({ activeFirstTab: id })
    // this.store.getCustomerDetail(this.state.id, this.props.history);
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.customers" />}
              match={this.props.match}
            />
            <Colxx xs="12" className="p-0">
              <Nav tabs className="separator-tabs m-0 mb-5 ">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeFirstTab === '1',
                      // "nav-link": true
                    })}
                    onClick={() => {
                      this.toggleTab('1')
                    }}
                    to="#"
                  >
                    <IntlMessages id="pages.details" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeFirstTab === '2',

                      // "nav-link": true
                    })}
                    onClick={() => this.toggleTab('2')}
                    to="#"
                  >
                    {this.store.customerDetail
                      ? ` (${this.store.customerDetail.orders.length}) `
                      : '(0)'}
                    <IntlMessages id="pages.orders" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeFirstTab === '3',

                      // "nav-link": true
                    })}
                    onClick={() => this.toggleTab('3')}
                    to="#"
                  >
                    {this.store.customerDetail
                      ? ` (${this.store.customerDetail.address.length}) `
                      : '(0)'}
                    <IntlMessages id="pages.addresses" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Colxx>
            {/* <Separator className="mb-5" /> */}
          </Colxx>
          {this.store.isLoading ? (
            <div className="loading"></div>
          ) : this.state.activeFirstTab === '1' ? (
            <Colxx xxs="12">
              <Colxx xxs="12">
                <Row className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: '25px' }}
                  >
                    <Row>
                      <Colxx xxs="12" sm="8" lg="6" className="mb-4">
                        <Card className="mb-2">
                          <CardBody>
                            <CardTitle className="mb-3">
                              <span className="font-weight-bold text-uppercase">
                                {`Detail`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />

                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.userName" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-3">
                                {this.store.customerDetail
                                  ? this.store.customerDetail.name
                                  : '---'}
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.email" />
                              </p>
                              <p className="mb-3">
                                {this.store.customerDetail
                                  ? this.store.customerDetail.email
                                  : '---'}
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.phoneNumber" />
                              </p>
                              <p className="mb-3">
                                {this.store.customerDetail
                                  ? this.store.customerDetail.phonenumber
                                    ? this.store.customerDetail.phonenumber
                                    : '---'
                                  : '---'}
                              </p>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Row>
              </Colxx>
            </Colxx>
          ) : this.state.activeFirstTab === '2' ? (
            <Row className="col-12">
              <Colxx xxs="12" className="pl-0 d-xl-block d-md-none">
                <div
                  className="col-12 p-2 d-flex align-items-center mb-3"
                  style={{ borderBottom: '1px solid gray' }}
                >
                  <div className="col-1">
                    <p className="col-12 p-2 m-0 text-muted text-small text-center">
                      Image
                    </p>
                  </div>

                  <div className="col-11 d-flex align-items-center">
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Customer
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Payment Type
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Id
                    </p>

                    <p className="col-1 p-2 m-0 text-muted text-small text-center">
                      Order Date
                    </p>

                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Delivery Date
                    </p>

                    <p className="col-1 p-2 m-0 text-muted text-small text-center">
                      price (KD)
                    </p>
                    {/* <p className="col-1 p-2 m-0 text-muted text-small text-center">
                  Status
                </p> */}
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Delivery Status
                    </p>
                  </div>
                </div>
              </Colxx>
              {this.store.customerDetail
                ? this.store.customerDetail.orders.map((item) => (
                    <Colxx xxs="12" className=" mb-3 pl-0">
                      <NavLink
                        href={`/app/order/detail/${item.summary.id}`}
                        className="p-0"
                      >
                        <Card className="col-12 pl-0 pr-0 pointer">
                          <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                            <div className="position-relative col-xl-1 col-md-4 col-sm-12">
                              <div
                                className="col-12 position-relative"
                                style={{ paddingTop: '100%' }}
                              >
                                <CardImg
                                  src={item.items[0].images[0].url}
                                  className=" p-2"
                                  style={{}}
                                ></CardImg>
                              </div>
                            </div>
                            <div className="col-xl-11 col-md-8 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap">
                              <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Customer :{' '}
                                </span>
                                {item.summary.customer.name}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Payment Type :{' '}
                                </span>
                                {item.summary.payment_type}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Id :{' '}
                                </span>
                                {item.summary.code}
                              </CardText>
                              <CardText className="col-xl-1 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Order Date :{' '}
                                </span>
                                {item.summary.date.split(' ')[0]}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Delivery Date :{' '}
                                </span>
                                {item.summary.delivery_date.split(' ')[4]}
                              </CardText>
                              <CardText className="col-xl-1 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Total price (KD) :{' '}
                                </span>
                                {item.summary.total_price}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small  text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Delivery status :{' '}
                                </span>
                                <Button
                                  color={
                                    item.summary.delivery_status === 'delivered'
                                      ? 'success'
                                      : 'danger'
                                  }
                                  size="sm"
                                >
                                  {item.summary.delivery_status}
                                </Button>
                              </CardText>
                            </div>
                          </CardBody>
                        </Card>
                      </NavLink>
                    </Colxx>
                  ))
                : null}
            </Row>
          ) : (
            <>
              <Row className="col-12">
                <Colxx xxs="12" className="pl-0 d-xl-block d-md-none">
                  <div
                    className="col-12 p-2 d-flex align-items-center mb-3"
                    style={{ borderBottom: '1px solid gray' }}
                  >
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Title
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Area
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Block
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Street
                    </p>
                    <p className="col-2 p-2 m-0 text-muted text-small text-center">
                      Avenue
                    </p>

                    <p className="col-1 p-2 m-0 text-muted text-small text-center">
                      Building
                    </p>

                    <p className="col-1 p-2 m-0 text-muted text-small text-center">
                      Floor
                    </p>
                  </div>
                </Colxx>
                {this.store.customerDetail
                  ? this.store.customerDetail.address.map((item) => (
                      <Colxx xxs="12" className=" mb-3 pl-0">
                        <Card className="col-12 pl-0 pr-0  pointer">
                          <CardBody className="d-flex col-12 pl-0 pr-0 pt-3 pb-3 align-items-center flex-wrap">
                            {/* <div className="position-relative col-xl-1 col-md-4 col-sm-12"> */}
                            <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Title :{' '}
                              </span>
                              {item.name}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Area :{' '}
                              </span>
                              {item?.area?.name_en}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Block :{' '}
                              </span>
                              {item.block}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Street :{' '}
                              </span>
                              {item.street}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Avenue :{' '}
                              </span>
                              {item.avenue}
                            </CardText>
                            <CardText className="col-xl-1 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Building :{' '}
                              </span>
                              {item.building}
                            </CardText>
                            <CardText className="col-xl-1 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Floor :{' '}
                              </span>
                              {item.floor}
                            </CardText>
                            {/* </div> */}
                          </CardBody>
                        </Card>
                      </Colxx>
                    ))
                  : null}
              </Row>
            </>
          )}
        </Row>
      </Fragment>
    )
  }
}

export default CustomerDetail
