import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Card,
  CardBody,
  Col,
  CardText,
  ButtonGroup,
  Button,
} from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import { ToastContainer, toast } from 'react-toastify'

@inject('stores')
@observer
class OrderDetail extends Component {
  @observable
  store = this.props.stores.subscribeOrder
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getOrderDetail(path, this.props.history)
  }
  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        <Row>
          <Colxx xxs="12" className="d-flex align-items-center">
            <Colxx xxs="8">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.subscribeOrder" />}
                match={this.props.match}
              />
            </Colxx>
            <Colxx xxs="4" className="float-right">
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => window.print()}
              >
                <IntlMessages id="pages.print" />
              </Button>
            </Colxx>
          </Colxx>
          <Colxx xxs="12">
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row></Row>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : this.store.orderDetail ? (
          <>
            <div className="col-12 div-print">
              <Colxx xxs="12">
                <Card>
                  <CardBody>
                    <Colxx xxs="12">
                      <div
                        className="d-flex flex-wrap align-items-center p-3 h-100"
                        style={{ border: '1px solid gray' }}
                      >
                        <Col className="p-2 col-xl-4 col-md-4 col-sm-12 col-12">
                          <Card
                            className="col-12  h-100"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <CardBody className="p-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Id :{' '}
                                </span>
                                {this.store.orderDetail.code}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Order Date :{' '}
                                </span>
                                {moment(
                                  this.store.orderDetail.createdAt
                                ).format('YYYY/MM/DD')}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  SKU :{' '}
                                </span>
                                {this.store.orderDetail.sku}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Status :{' '}
                                </span>
                                {this.store.orderDetail.is_active
                                  ? 'Active'
                                  : 'Stoped'}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Payment Method :{' '}
                                </span>
                                {this.store.orderDetail.payment_type}
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col className="p-2 col-xl-4 col-md-4 col-sm-12 col-12">
                          <Card
                            className="col-12  h-100 pointer"
                            style={{ backgroundColor: '#eee' }}
                            onClick={() =>
                              (window.location.href = `/app/product/detail/${this.store.orderDetail?.extra_detail?.product?.id}`)
                            }
                          >
                            <CardBody className="p-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Product Name :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail?.extra_detail?.product
                                    ?.name
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Total Items :{' '}
                                </span>{' '}
                                {this.store.orderDetail.count}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Interval :{' '}
                                </span>{' '}
                                {this.store.orderDetail.interval}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Price :{' '}
                                </span>{' '}
                                {`${this.store.orderDetail.price} ${this.store.orderDetail.currency}`}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Shipping Price :{' '}
                                </span>{' '}
                                {`${this.store.orderDetail.shipping_price} ${this.store.orderDetail.currency}`}
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                        
                        <Col className="p-2 col-xl-4 col-md-4 col-sm-12 col-12">
                          <Card
                            className="col-12  h-100"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <CardBody className="p-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Customer :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.extra_detail.customer
                                    .name
                                }
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Delivery Time :{' '}
                                </span>{' '}
                                {this.store.orderDetail.delivery_hour}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Address :{' '}
                                </span>{' '}
                                {/* {this.store.orderDetail.delivery_hour} */}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Phone Number :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.extra_detail.customer
                                    .phonenumber
                                }
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Email :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.extra_detail.customer
                                    .email
                                }
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      </div>
                      <div
                        className="d-flex align-items-center p-3 flex-wrap"
                        style={{ border: '1px solid gray' }}
                      >
                        <Colxx
                          xxs="12"
                          className="p-0 d-xl-block d-md-none d-none"
                        >
                          <div
                            className="col-12 p-2 d-flex align-items-center mb-3"
                            style={{ borderBottom: '1px solid gray' }}
                          >
                            <div className="col-1">
                              <p className="col-12 p-2 m-0 text-muted text-small text-center">
                                Row
                              </p>
                            </div>

                            <div className="col-10 d-flex align-items-center">
                              <p className="col-3 p-2 m-0 text-muted text-small text-center">
                                Payment Status
                              </p>
                              <p className="col-3 p-2 m-0 text-muted text-small text-center">
                                Payment Date
                              </p>
                              <p className="col-6 p-2 m-0 text-muted text-small text-center">
                                Delivery Status
                              </p>
                            </div>
                          </div>
                        </Colxx>
                        {this.store.orderDetail.subscribe_payments.map(
                          (item, index) => (
                            <Colxx xxs="12" className="pl-0 pr-0" key={index}>
                              <Card className="col-12 pl-0 pr-0 pointer mb-3">
                                <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                                  <div className="position-relative col-xl-1 d-none d-md-none d-xl-inline col-md-4 col-sm-12">
                                    <CardText className="col-xl-12 col-md-12   p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Row :{' '}
                                      </span>
                                      {index + 1}
                                    </CardText>
                                  </div>
                                  <div className="col-xl-10 col-md-8 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap">
                                    <CardText className="col-xl-3 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Payment Status :{' '}
                                      </span>
                                      {item.successPayment
                                        ? 'Success'
                                        : 'Failed'}
                                    </CardText>
                                    <CardText className="col-xl-3 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Payment Date :{' '}
                                      </span>
                                      {moment(item.paymentAt).format(
                                        'YYYY/MM/DD hh:mm:ss a'
                                      )}
                                    </CardText>
                                    <Colxx className="col-xl-6 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                      <ButtonGroup className="d-flex justify-content-center">
                                        <Button
                                          onClick={() =>
                                            this.store.changeStatus(
                                              'preparing',
                                              this.state.id
                                            )
                                          }
                                          color={
                                            item.delivery_status === 'preparing'
                                              ? 'light'
                                              : 'danger'
                                          }
                                        >
                                          <IntlMessages id="pages.preparing" />
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            this.store.changeStatus(
                                              'sent',
                                              this.state.id
                                            )
                                          }
                                          color={
                                            item.delivery_status === 'sent'
                                              ? 'light'
                                              : 'danger'
                                          }
                                        >
                                          <IntlMessages id="pages.inTransit" />
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            this.store.changeStatus(
                                              'delivered',
                                              this.state.id
                                            )
                                          }
                                          color={
                                            item.delivery_status === 'delivered'
                                              ? 'light'
                                              : 'danger'
                                          }
                                        >
                                          <IntlMessages id="pages.completed" />
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            this.store.changeStatus(
                                              'cancelled',
                                              this.state.id
                                            )
                                          }
                                          color={
                                            item.delivery_status === 'cancelled'
                                              ? 'light'
                                              : 'danger'
                                          }
                                          disabled={
                                            item.delivery_status === 'delivered'
                                          }
                                        >
                                          <IntlMessages id="pages.cancelled" />
                                        </Button>
                                      </ButtonGroup>
                                    </Colxx>
                                  </div>
                                </CardBody>
                              </Card>
                              {/* </NavLink> */}
                            </Colxx>
                          )
                        )}
                      </div>
                      {/* <div
                        className="d-flex align-items-center p-3 flex-wrap"
                        style={{ border: '1px solid gray' }}
                      >
                        <Colxx xxs="12" className="p-0 ">
                          <NavLink>
                            <Card className="col-12 pl-0 pr-0 pointer">
                              <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                                <div
                                  className="col-12 d-flex align-items-center flex-wrap p-2"
                                  style={{ borderBottom: '1px solid gray' }}
                                >
                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    Sub Total :{' '}
                                    {`${this.store.orderDetail.subtotal} KD`}
                                  </CardText>
                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    Coupon :{' '}
                                    {`${
                                      this.store.orderDetail.coupon
                                        ? this.store.orderDetail.coupon
                                        : 0
                                    } KD`}
                                  </CardText>

                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    Shipping :{' '}
                                    {`${this.store.orderDetail.delivery} KD`}
                                  </CardText>
                                </div>
                                <CardText className="col-12 text-one font-weight-bold text-center text-muted m-0 p-3">
                                  Total :{' '}
                                  {`${this.store.orderDetail.total_price.value} ${this.store.orderDetail.extra_detail.currency}`}
                                </CardText>
                              </CardBody>
                            </Card>
                          </NavLink>
                        </Colxx>
                      </div> */}
                    </Colxx>
                  </CardBody>
                </Card>
              </Colxx>
            </div>
          </>
        ) : null}
      </Fragment>
    )
  }
}

export default OrderDetail
