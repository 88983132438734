import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Card, CardBody } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { ToastContainer, toast } from 'react-toastify'
import SelectInput from '../../../components/select/index'

@inject('stores')
@observer
class DeliveryTimeList extends Component {
  @observable
  store = this.props.stores.delivery

  constructor(props) {
    super(props)
    this.state = { perPhoneNumber: '+965-' }
  }
  componentDidMount() {
    this.store.getData()
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="d-flex align-items-center">
                <Colxx xxs="8">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.deliveryTime" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx xxs="4" className="float-right">
                  <Button
                    color="primary"
                    className="btn-shadow float-right mb-3"
                    size="lg"
                    onClick={() => this.store.validation(this.props.history)}
                  >
                    <IntlMessages id="pages.save" />
                  </Button>
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" xl="6">
                <Card style={{ overflow: 'unset' }}>
                  <CardBody style={{ overflow: 'unset' }}>
                      {this.store.data?.map((item, index) => (
                        <Colxx
                          key={index}
                          xxs="12"
                          lg="12"
                          className="mb-3 p-0"
                        >
                          <p className="text-muted text-small mb-2">
                            {item.label}
                            <span className="req"> *</span>
                          </p>
                          <div className="mb-0" style={{width:250}}>
                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={item.value}
                              onChange={(value) => this.store.changeDeliveryValue(item.field,value)}
                              options={item.options}
                              isMulti={false}
                            ></SelectInput>
                          </div>
                          <span className="req-validation ">
                            {this.store.price_validation
                              ? this.store.price_validation.msg
                                ? this.store.price_validation.msg
                                : null
                              : null}
                          </span>
                        </Colxx>
                      ))}
                    {/* </Colxx> */}
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </>
        )}
      </Fragment>
    )
  }
}

export default DeliveryTimeList
