import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'

export default class DynamicMenuStore {
  @observable
  data = null

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  name_en = ''

  @observable
  name_ar = ''

  @observable
  url = null

  @observable
  urlList = []

  @observable
  parent = null

  @observable
  parentList = []

  @observable
  btnDisable = false

  @observable
  name_en_validation = null

  @observable
  name_ar_validation = null

  @observable
  url_validation = null

  @observable
  response = null

  @action
  getData = (history) => {
    this.isLoading = false
    const body = {}
    axios
      .post(`${servicePath}/menu/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        const menu = []
        await data.data.items.forEach((item) => {
          this.parentList.push({ value: item.id, label: item.title_en })
          if (!item.parent) {
            menu.push(item)
          }
        })
        this.data = menu
        await this.getLink(history)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = true
      })
  }

  @action
  getLink = (history) => {
    axios
      .get(`${servicePath}/menu/linkList`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        await data.data.items.forEach((item, index) => {
          this.urlList.push({ value: index, label: item })
        })
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async validation(history) {
    this.name_en_validation = await checkInput({
      name: 'English Title',
      value: this.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.name_ar_validation = await checkInput({
      name: 'Arabic Title',
      value: this.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })
    this.url_validation = await checkInput({
      name: 'Link',
      value: this.url,
      require: true,
    })

    this.name_en_validation.res &&
      this.name_ar_validation.res &&
      this.url_validation.res &&
      this.handleSaveNewMenu(history)
  }

  @action
  handleSaveNewMenu = (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      title_en: this.name_en,
      title_ar: this.name_ar,
      link: this.url.label,
      order: 0,
    }

    if (this.parent) {
      data.parent = this.parent.value
    }

    axios
      .post(`${servicePath}/menu/add`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        this.reset()
        history.push(`/app/menu/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.btnDisable = false
      })
  }

  @action
  changeOrder = async (data, history) => {
    this.isLoading = false

    const menu = []
    await data.forEach((item, index) => {
      menu.push({ id: item.id, order: index })
    })

    axios
      .post(`${servicePath}/menu/reorder`, menu, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async(data) => {
       await this.reset()
        this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.name_en = ''
    this.name_ar = ''
    this.url = null
    this.parent = null
    this.btnDisable = false
    this.name_en_validation = null
    this.name_ar_validation = null
  }

  @action
  changeNameEn = (value) => {
    this.name_en = value
  }

  @action
  changeNameAr = (value) => {
    this.name_ar = value
  }

  @action
  setUrl = (value) => {
    this.url = value.selectedOption
  }

  @action
  setParent = (value) => {
    this.parent = value.selectedOption
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 10
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.isLoading = false

    this.openModal = false

    this.name_en = ''

    this.name_ar = ''

    this.url = null

    this.urlList = []

    this.parent = null

    this.parentList = []

    this.btnDisable = false

    this.name_en_validation = null

    this.name_ar_validation = null

    this.url_validation = null

    this.response = null
  }
}
