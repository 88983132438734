import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class AddProductStore {
  @observable
  isLoading = false

  @observable
  arName = ''

  @observable
  enName = ''

  @observable
  price = ''

  @observable
  enDescription = ''

  @observable
  arDescription = ''

  @observable
  imageFile = null

  @observable
  width = ''

  @observable
  height = ''

  @observable
  length = ''

  @observable
  weight = ''

  @observable
  sku = ''

  @observable
  category = null

  @observable
  categoryId = null

  @observable
  categoriesList = []

  @observable
  brewsList = []

  @observable
  brews = null

  @observable
  tags = null

  @observable
  tagsList = []

  @observable
  brandsList = []

  @observable
  brand = null

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  ar_description_validation = null

  @observable
  en_description_validation = null

  @observable
  sku_validation = null

  @observable
  tag_validation = null

  @observable
  price_validation = null

  @observable
  category_validation = null

  @observable
  brand_validation = null

  @observable
  height_validation = null

  @observable
  length_validation = null

  @observable
  width_validation = null

  @observable
  weight_validation = null

  @observable
  image_validation = null

  @observable
  brew_validation = null

  @observable
  response = null

  @observable
  is_subscribe = false

  @observable
  subscribes = [
    {
      weight: 0,
      // interval: null,
      price: 0,
      sku: '',
      width: '',
      height: '',
      length: '',
    },
  ]

  @observable
  subscribeValue = {
    weight: 0,
    // interval: null,
    price: 0,
    sku: '',
    width: '',
    height: '',
    length: '',
  }

  @observable
  intervalList = []

  @observable
  roastList = []

  @observable
  selectedRoast = null

  @observable
  roast_validation = null

  @action
  changeSubscribeSwitch = () => {
    this.is_subscribe = !this.is_subscribe
  }

  @action
  addSubscribe = () => {
    this.subscribes.push({ ...this.subscribeValue })
  }

  @action
  removeSubscribe = (order) => {
    let data = [...this.subscribes]

    data.splice(order, 1)

    this.subscribes = data
  }

  @action
  changeSubscribeValue = (index, target, value) => {
    this.subscribes[index][target] = value
  }

  @action
  async validation(history) {
    if (this.is_subscribe) {
      for (const item of this.subscribes) {
        for (var key in item) {
          if ((key==="weight" || key==="price") && !item[key]) {
            this.response = {
              msg: 'Please fill all required field',
              type: 'error',
            }
            setTimeout(() => {
              this.response = null
            }, 1000)

            return
          }
        }
      }
    }
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.enName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.arName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.enDescription,
      require: true,
      type: 'string',
      min: 20,
    })
    this.ar_description_validation = await checkInput({
      name: 'Arabic Description',
      value: this.arDescription,
      require: true,
      type: 'string',
      min: 20,
    })
    this.brand_validation = await checkInput({
      name: 'Brand',
      value: this.brand,
      require: true,
    })

    this.category_validation = await checkInput({
      name: 'Category',
      value: this.category,
      require: true,
    })
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.price,
      require: true,
      type: 'number',
      min: 1,
    })
    this.width_validation = await checkInput({
      name: 'Width',
      value: this.width,
      require: true,
      type: 'number',
      min: 1,
    })
    this.height_validation = await checkInput({
      name: 'Height',
      value: this.height,
      require: true,
      type: 'number',
      min: 1,
    })
    this.length_validation = await checkInput({
      name: 'Length',
      value: this.length,
      require: true,
      type: 'number',
      min: 1,
    })
    this.weight_validation = await checkInput({
      name: 'Weigth',
      value: this.weight,
      require: true,
      type: 'number',
      min: 1,
    })
    this.tag_validation = await checkInput({
      name: 'Tag',
      value: this.tags,
      require: true,
    })

    this.sku_validation = await checkInput({
      name: 'SKU',
      value: this.sku,
      require: true,
      min: 6,
    })

    this.brew_validation = await checkInput({
      name: 'Brewing Method',
      value: this.brews,
      require: true,
    })

    this.roast_validation = await checkInput({
      name: 'Roast',
      value: this.selectedRoast,
      require: true,
    })

    this.image_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })

    if (this.is_subscribe) {
      this.en_name_validation.res &&
        this.ar_name_validation.res &&
        this.ar_description_validation.res &&
        this.en_description_validation.res &&
        this.brand_validation.res &&
        this.category_validation.res &&
        this.tag_validation.res &&
        this.brew_validation.res &&
        this.roast_validation.res &&
        this.image_validation.res &&
        this.handleSaveProduct(history)
    } else {
      this.en_name_validation.res &&
        this.ar_name_validation.res &&
        this.ar_description_validation.res &&
        this.en_description_validation.res &&
        this.brand_validation.res &&
        this.category_validation.res &&
        this.price_validation.res &&
        // this.width_validation.res &&
        // this.height_validation.res &&
        // this.length_validation.res &&
        this.weight_validation.res &&
        this.tag_validation.res &&
        this.sku_validation.res &&
        this.brew_validation.res &&
        this.image_validation.res &&
        this.handleSaveProduct(history)
    }
  }

  @action
  async getDependencies(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      all: true,
    }
    const value = await axiFetch.post('category', body, 'list', history)
    const brew = await axiFetch.post('brew', body, 'list', history)
    const tag = await axiFetch.post('tag', body, 'list', history)
    const brand = await axiFetch.post('brand', body, 'list', history)
    const roast = await axiFetch.post('roast', body, 'list', history)
    // const interval = await axiFetch.get(
    //   'subscribe',
    //   null,
    //   'intervalList',
    //   history
    // )

    if (value) {
      this.data = value.data
      await value.data.items.map((category) =>
        this.categoriesList.push({
          value: category.id,
          label: category.name_en,
        })
      )
    }

    if (tag) {
      await tag.data.items.map((tag) =>
        this.tagsList.push({
          value: tag.id,
          label: tag.name_en,
        })
      )
    }

    if (brand) {
      await brand.data.items.map((brand) =>
        this.brandsList.push({
          value: brand.id,
          label: brand.name_en,
        })
      )
    }

    if (brew) {
      await brew.data.items.map((brew) =>
        this.brewsList.push({
          value: brew.id,
          label: brew.name_en,
        })
      )

      if (roast) {
        await roast.data.items.map((roast) =>
          this.roastList.push({
            value: roast.id,
            label: roast.name_en,
          })
        )
      }

      // if (interval) {
      //   for (var key in interval.data) {
      //     this.intervalList.push({ value: key, label: interval.data[key] })
      //   }
      // }

      this.isLoading = false
    }
  }

  @action
  changeEnName(value) {
    this.enName = value
  }

  @action
  changeTags(value) {
    this.tags = value
  }

  @action
  changeArName(value) {
    this.arName = value
  }

  @action
  changeEnDescription(value) {
    this.enDescription = value
  }

  @action
  changeArDescription(value) {
    this.arDescription = value
  }

  @action
  changePrice(value) {
    this.price = value
  }

  @action
  changeWeight(value) {
    this.weight = value
  }

  @action
  changeWidth(value) {
    this.width = value
  }

  @action
  changeHeight(value) {
    this.height = value
  }

  @action
  changeLength(value) {
    this.length = value
  }

  @action
  changeSku(value) {
    this.sku = value
  }

  @action
  setCategory(value) {
    this.category = value.selectedOption
  }

  @action
  setBrew(value) {
    this.brews = value.selectedOption
  }

  @action
  setRoast(value) {
    this.selectedRoast = value.selectedOption
  }

  @action
  setTag(value) {
    this.tags = value.selectedOption
  }

  @action
  setBrand(value) {
    this.brand = value.selectedOption
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file) {
    if (file) {
      this.imageFile = this.base64ToBlob(file, 'image/jpg')
    } else {
      this.imageFile = null
    }
  }

  @action
  async handleSaveProduct(history) {
    this.isLoading = true
    const brewsId = []

    const tagsId = []

    const roastId = []

    this.brews.forEach((item) => {
      brewsId.push(item.value)
    })

    this.tags.forEach((item) => {
      tagsId.push(item.value)
    })

    if (this.is_subscribe) {
      for (const roast of this.selectedRoast) {
        roastId.push(roast.value)
      }
    }
    const data = {
      name_en: this.enName,
      name_ar: this.arName,
      description_en: this.enDescription,
      description_ar: this.arDescription,
      image: this.imageFile,
      category_id: this.category.value,
      brew_types: JSON.stringify(brewsId),
      tags: JSON.stringify(tagsId),
      brand_id: this.brand.value,
      is_subscribe: this.is_subscribe ? 1 : 0,
    }
    if (this.is_subscribe) {
      let values = [...this.subscribes]
      for (let item of values) {
        // item.interval = item.interval.value
        item.width && (item.dimension_x = item.width)
        item.height &&  (item.dimension_y = item.height)
        item.length && (item.dimension_z = item.length)
        delete item.width
        delete item.height
        delete item.length
        !item.sku && delete item.sku
        // item.roast_type = item.roast.value
        // delete item.roast
      }
      data.subscribeValues = JSON.stringify(values)
      data.roast_types = JSON.stringify(roastId)
    } else {
      data.price = this.price
      data.sku = this.sku
      data.weight = this.weight
      data.dimension_x = this.width
      data.dimension_y = this.height
      data.dimension_z = this.length
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/product/add`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((res) => res.data)
      .then(async (data) => {
         this.resetData()
        history.push(`/app/product/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  generateSkuNumber = () => {
    this.sku = Math.floor(Math.random() * 1000000 + 100000)
  }

  @action
  resetData() {
    this.isLoading = false

    this.arName = ''

    this.enName = ''

    this.price = ''

    this.enDescription = ''

    this.arDescription = ''

    this.imageFile = null

    this.width = ''

    this.height = ''

    this.length = ''

    this.weight = ''

    this.sku = ''

    this.category = null

    this.categoryId = null

    this.categoriesList = []

    this.brewsList = []

    this.brews = null

    this.tags = null

    this.tagsList = []

    this.brandsList = []

    this.brand = null

    this.en_name_validation = null

    this.ar_name_validation = null

    this.ar_description_validation = null

    this.en_description_validation = null

    this.sku_validation = null

    this.tag_validation = null

    this.price_validation = null

    this.category_validation = null

    this.brand_validation = null

    this.height_validation = null

    this.length_validation = null

    this.width_validation = null

    this.weight_validation = null

    this.image_validation = null

    this.brew_validation = null

    this.response = null

    this.is_subscribe = false

    this.subscribes = [
      {
        weight: 0,
        // interval: null,
        price: 0,
        sku: '',
        width: '',
        height: '',
        length: '',
      },
    ]

    this.subscribeValue = {
      weight: 0,
      // interval: null,
      price: 0,
      sku: '',
      width: '',
      height: '',
      length: '',
    }

    this.intervalList = []

    this.roastList = []

    this.selectedRoast = null

    this.roast_validation = null
  }
}
