import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import IntlMessages from "../../util/IntlMessages";

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { openModal, target, toggleModal, action } = this.props;
    return (
      <Modal isOpen={openModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.delete" />
        </ModalHeader>
        <ModalBody>
          {`Are you sure you want to delete "${target}" ? `}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={action}
          >
            {`Yes delete it `}
          </Button>{" "}
          <Button   className="btn-outline-secondary btn-lg btn-shadow" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteModal;
