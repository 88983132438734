import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Fragment } from "react";
import { Row, Card, Button } from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import CustomCakeModal from "../../../components/Modal/customCakeModal";
import CustomItem from "../../../components/customItem";

@inject("stores")
@observer
class CustomCake extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  @observable
  store = this.props.stores.customCake;
  componentDidMount() {
    this.store.getBakerySupport();
  }
  componentWillUnmount() {
    this.store.reset();
  }
  render() {
    return (
      <Fragment>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            {this.store.supportList && this.store.target ? (
              <CustomCakeModal
                openModal={this.store.openCustomCakeModal}
                target={this.store.target}
                toggleModal={() => this.store.toggleCustomCakeModal()}
                action={id => this.store.handleCustomItem(id)}
                cancelAction={() => {
                  this.store.cancelCustomItem();
                }}
                data={this.store.supportList}
              />
            ) : null}
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.product" />}
                match={this.props.match}
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => this.store.handleSave()}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Card className="col-12 p-2" style={{ height: "100%" }}>
              {this.store.supportListArray
                ? this.store.supportListArray.map(item => (
                    <CustomItem
                      name={item.name}
                      array={this.store.supportList[item.name]}
                      handleOpenModal={name =>
                        this.store.changeCustomCakeModal(name)
                      }
                      changePrice={(target, name, value) =>
                        this.store.handleChangePrice(target, name, value)
                      }

                      changeIsSupport={(target, name, value) =>
                        this.store.handleChangeIsSupport(target, name, value)
                      }
                    />
                  ))
                : null}
            </Card>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default CustomCake;
