import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'
import { FormikSwitch } from '../FormValidations/FormikFields'

class PriceModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      openModal,
      toggleModal,
      price,
      is_default,
      changePrice,
      changeIsDefault,
      handleSave,
      btnDisable,
      price_validation,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.set-default-posting-price" />
        </ModalHeader>
        <ModalBody className="p-2">
          <Colxx xxs="12" className="mb-3 ">
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.price" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="price"
                  value={price ? price : ''}
                  type="text"
                  onChange={(e) => changePrice(e.target.value)}
                />
              </div>
              <span className="req-validation">
                {' '}
                {price_validation
                  ? price_validation.msg
                    ? price_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>

            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.isDefault" />
              </p>
              <div className="d-flex align-items-center col-12 p-0">
                <FormikSwitch
                  className="custom-switch custom-switch-primary-inverse"
                  name={'is_default'}
                  value={is_default ? true : false}
                  onChange={() => changeIsDefault()}
                  // onBlur={handleBlur}
                />
              </div>
            </Colxx>
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default PriceModal
