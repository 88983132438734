import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'

export default class ProfileStore {
  @observable
  data = null

  @observable
  isLoading = false

  @observable
  response = null

  @observable
  imageFile = null

  @observable
  name_validation = null

  @observable
  about_en_validation = null

  @action
  getData = (history) => {
    this.isLoading = false

    axios
      .get(`${servicePath}/admin/profile/me`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  changeEnName = (value) => {
    this.data.name = value
  }

  @action
  changeEnAbout = (value) => {
    this.data.about_en = value
  }

  @action
  changeArAbout = (value) => {
    this.data.about_ar = value
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  async validation(history) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.data.name,
      require: true,
      type: 'string',
      min: 3,
    })
    this.about_en_validation = await checkInput({
      name: 'About Me English',
      value: this.data.about_en,
      require: true,
      type: 'string',
      min: 8,
    })

    this.about_en_validation.res &&
      this.name_validation.res &&
      this.handleUpdateProfile(history)
  }

  @action
  handleUpdateProfile = (history) => {
    this.isLoading = false
    const data = {
      name: this.data.name,
      about_en: this.data.about_en,
      about_ar: this.data.about_ar,
    }
    if (this.imageFile) {
      data.profile_img = this.imageFile
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/admin/update`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.reset()
        this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  reset() {
    this.data = null

    this.isLoading = false

    this.response = null

    this.imageFile = null

    this.name_validation = null

    this.about_en_validation = null
  }
}
