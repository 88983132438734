import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Card, CardBody, Button, CardTitle } from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import { NavLink } from 'react-router-dom'

@inject('stores')
@observer
class WholesaleDetail extends Component {
  @observable
  store = this.props.stores.wholesale
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getWholesaleDetail(path, this.props.history)
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.wholesale" />}
              match={this.props.match}
            />
            <Button
              color="primary"
              className="btn-shadow float-right"
              size="lg"
              onClick={() => window.print()}
            >
              <IntlMessages id="pages.print" />
            </Button>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row></Row>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : this.store.wholesaleDetail ? (
          <>
            <div className="col-12">
              <Colxx xxs="12">
                <div className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: '25px' }}
                  >
                    <div>
                      <Colxx
                        xxs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="6"
                        className="mb-8"
                      >
                        <Card className="mb-2">
                          <CardBody>
                            <CardTitle className="mb-3">
                              <span className="font-weight-bold text-uppercase">
                                detail
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.personName" />
                                </p>
                                <p className="mb-3 font-weight-bold">
                                  {this.store.wholesaleDetail.name}
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.phone" />
                                </p>
                                <p className="mb-3 font-weight-bold">
                                  {this.store.wholesaleDetail.phone
                                    ? this.store.wholesaleDetail.phone
                                    : '-'}
                                </p>
                              </Colxx>
                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.product" />
                                </p>
                                <NavLink to={`/app/product/detail/${this.store.wholesaleDetail?.product?.id}`}>
                                <p className="mb-3 font-weight-bold text-info pointer">
                                  {this.store.wholesaleDetail?.product?.name}
                                </p>
                                </NavLink>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.company" />
                                </p>
                                <p className="mb-3 font-weight-bold">
                                  {this.store.wholesaleDetail.title
                                    ? this.store.wholesaleDetail.title
                                    : '-'}
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.consumption" />
                                </p>
                                <p className="mb-3 font-weight-bold">
                                  {this.store.wholesaleDetail
                                    .monthly_consumption
                                    ? this.store.wholesaleDetail
                                        .monthly_consumption
                                    : '-'}
                                </p>
                              </Colxx>
                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.address" />
                                </p>
                                <p className="mb-3 font-weight-bold">
                                  {this.store.wholesaleDetail.address
                                    ? this.store.wholesaleDetail.address
                                    : '-'}
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.message" />
                                </p>
                                <p className="mb-3 font-weight-bold">
                                  {this.store.wholesaleDetail.message
                                    ? this.store.wholesaleDetail.message
                                    : '-'}
                                </p>
                              </Colxx>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </div>
                  </Colxx>
                </div>
              </Colxx>
            </div>
          </>
        ) : null}
      </Fragment>
    )
  }
}

export default WholesaleDetail
