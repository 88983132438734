import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button, Card, CardBody, CardTitle, Input } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import DeleteModal from '../../../components/Modal/deleteModal'
import { ToastContainer, toast } from 'react-toastify'
import SelectInput from '../../../components/select'
import Sort from '../../../components/Sort/index'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'

@inject('stores')
@observer
class MenuDetail extends Component {
  @observable
  store = this.props.stores.dynamicMenuDetail
  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    const { parentList } = this.store
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.menu" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={
                  this.store.menuDetail ? this.store.menuDetail.title_en : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteMenu(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => this.store.handleUpdateMenu(this.state.id)}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx xxs="12">
                  <Colxx xxs="12">
                    <Row className="d-flex justify-content-center">
                      <Colxx
                        xxs="12"
                        lg="12"
                        className="mb-6 border-bottom"
                        style={{ marginBottom: '25px' }}
                      >
                        <Row>
                          <Colxx
                            xxs="12"
                            sm="12"
                            md="6"
                            lg="4"
                            className="mb-8"
                          >
                            <Card
                              className="mb-2"
                              style={{ overflow: 'unset' }}
                            >
                              <CardBody>
                                <CardTitle className="mb-3">
                                  <span className="font-weight-bold text-uppercase">
                                    {`Detail`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                <Colxx
                                  xxs="12"
                                  className="p-0 d-flex flex-wrap"
                                >
                                  <Colxx xxs="12" className="mb-3">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.isActive" />
                                    </p>
                                    <FormikSwitch
                                      className="custom-switch custom-switch-primary-inverse mr-2"
                                      name={'test'}
                                      value={
                                        this.store.menuDetail.is_visible
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        this.store.handleIsVisible()
                                      }
                                      // onBlur={handleBlur}
                                    />
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.title-en" />
                                      <span className="req"> *</span>
                                    </p>
                                    <p className="mb-3">
                                      <Input
                                        name="name"
                                        value={
                                          this.store.menuDetail
                                            ? this.store.menuDetail.title_en
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeNameEn(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </p>
                                    <span className="req-validation ">
                                      {' '}
                                      {this.store.name_en_validation
                                        ? this.store.name_en_validation.msg
                                          ? this.store.name_en_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.title-ar" />
                                      <span className="req"> *</span>
                                    </p>
                                    <p className="mb-3">
                                      <Input
                                        name="name"
                                        value={
                                          this.store.menuDetail
                                            ? this.store.menuDetail.title_ar
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeNameAr(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </p>
                                    <span className="req-validation ">
                                      {' '}
                                      {this.store.name_ar_validation
                                        ? this.store.name_ar_validation.msg
                                          ? this.store.name_ar_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>

                                  <Colxx xxs="12" lg="12">
                                    {' '}
                                    <p className="text-muted text-small mb-2">
                                      {/* <IntlMessages id="menu.category" /> */}
                                      Menu
                                      {/* <span className="req"> *</span> */}
                                    </p>
                                    <div className="mb-3">
                                      <SelectInput
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        value={
                                          this.store.parent
                                            ? this.store.parent
                                            : null
                                        }
                                        onChange={(value) =>
                                          this.store.setParent(value)
                                        }
                                        options={
                                          parentList.length ? parentList : []
                                        }
                                        isMulti={false}
                                      ></SelectInput>
                                    </div>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    {' '}
                                    <p className="text-muted text-small mb-2">
                                      {/* <IntlMessages id="menu.category" /> */}
                                      Link
                                      <span className="req"> *</span>
                                    </p>
                                    <div className="mb-3">
                                      <SelectInput
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        value={
                                          this.store.url ? this.store.url : null
                                        }
                                        onChange={(value) =>
                                          this.store.setUrl(value)
                                        }
                                        options={this.store.urlList}
                                        isMulti={false}
                                      ></SelectInput>
                                    </div>
                                    <span className="req-validation">
                                      {' '}
                                      {this.store.url_validation
                                        ? this.store.url_validation.msg
                                          ? this.store.url_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                </Colxx>
                              </CardBody>
                            </Card>
                          </Colxx>

                          {this.store.children?.length ? (
                            <Colxx
                              xxs="12"
                              sm="12"
                              md="6"
                              lg="6"
                              className="mb-8"
                            >
                              <Card
                                className="mb-2"
                                style={{ overflow: 'unset' }}
                              >
                                <CardBody>
                                  <CardTitle className="mb-3">
                                    <span className="font-weight-bold text-uppercase">
                                      {`SubMenu`}
                                    </span>
                                  </CardTitle>
                                  <Separator className="mb-5" />
                                  <Colxx
                                    xxs="12"
                                    className="p-0 d-flex flex-wrap"
                                  >
                                    <Row>
                                      {this.store.children ? (
                                        <Sort
                                          full={true}
                                          data={this.store.children}
                                          action={(value) =>
                                            this.store.changeOrder(
                                              value,
                                              this.state.id,
                                              this.props.history
                                            )
                                          }
                                        />
                                      ) : null}
                                    </Row>
                                  </Colxx>
                                </CardBody>
                              </Card>
                            </Colxx>
                          ) : null}
                        </Row>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default MenuDetail
