import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
// import axiFetch from "../config/fetch";

export default class FaqStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  search = ''

  @observable
  searchOn = ['title_en']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  en_content_validation = null

  @observable
  en_title_validation = null

  @observable
  category_validation = null

  @observable
  btnDisable = false

  @observable
  title_en = ''

  @observable
  title_ar = ''

  @observable
  content_en = ''

  @observable
  content_ar = ''

  @observable
  selectedCategory = null

  @observable
  categoriesList = []

  @observable
  response = null

  @action
  handleChangeEnContent = (data) => {
    this.content_en = data
  }

  @action
  handleChangeArContent = (data) => {
    this.content_ar = data
  }

  @action
  changeTitle_en = (value) => {
    this.title_en = value
  }

  @action
  changeTitle_ar = (value) => {
    this.title_ar = value
  }

  @action
  setCategory(value) {
    this.selectedCategory = value.selectedOption
  }

  @action
  async getDependencies(history) {
    await this.getFaqCategories(history)
  }

  @action
  getFaqCategories = async (history) => {
    const data = { all: true }
    await axios
      .post(`${servicePath}/faq/category/list`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        data.data.items.map((category) =>
          this.categoriesList.push({
            value: category.id,
            label: category.name_en,
          })
        )
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getData = async (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }

    axios
      .post(`${servicePath}/faq/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.data = data.data.items
        this.pagination =
          parseInt(data.data.pagination.total / this.limit) +
          (data.data.pagination.total % this.limit ? 1 : 0)
        await this.getDependencies(history)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async validation(history) {
    this.en_title_validation = await checkInput({
      name: 'English Title',
      value: this.title_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.category_validation = await checkInput({
      name: 'Category',
      value: this.selectedCategory,
      require: true,
    })
    this.en_content_validation = await checkInput({
      name: 'English content',
      value: this.content_en,
      require: true,
      min: 3,
    })
    this.category_validation.res &&
      this.en_content_validation.res &&
      this.en_title_validation &&
      this.handleSaveNewFaq(history)
  }

  @action
  handleSaveNewFaq = (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      title_en: this.title_en,
      title_ar: this.title_ar,
      content_en: this.content_en,
      content_ar: this.content_ar,
      category_id: this.selectedCategory.value,
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/faq`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.reset()
        this.openModal = false
        this.btnDisable = false
        this.reset()
        history.push(`/app/faq/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.en_content_validation = null
    this.en_title_validation = null
    this.category_validation = null
    this.btnDisable = false
    this.title_en = ''
    this.title_ar = ''
    this.content_en = ''
    this.content_ar = ''
    this.selectedCategory = null
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.search = ''

    this.searchOn = ['title_en']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.en_content_validation = null

    this.en_title_validation = null

    this.category_validation = null

    this.btnDisable = false

    this.title_en = ''

    this.title_ar = ''

    this.content_en = ''

    this.content_ar = ''

    this.selectedCategory = null

    this.categoriesList = []
  }
}
