import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
// import axiFetch from "../config/fetch";

export default class SubscribeOrderStore {
  @observable
  data = null

  @observable
  orderDetail = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  search = ''

  @observable
  response = null

  @action
  changeSearch(value) {
    this.search = value
  }

  @action
  getData = (history) => {
    this.isLoading = false
    const body = {
      offset: this.offset,
      pageSize: this.limit,
      sorted: [],
      filtered: [],
      search: this.search,
      searchOn: ['code'],
    }
    axios
      .post(`${servicePath}/subscribe/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data.items
        this.pagination =
          parseInt(data.data.pagination.total / this.limit) +
          (data.data.pagination.total % this.limit ? 1 : 0)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getOrderDetail = async (id, history) => {
    this.isLoading = false

    axios
      .get(`${servicePath}/subscribe/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.orderDetail = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  changeStatus = (value, id, history) => {
    this.isLoading = false
    const body = { delivery_status: value }
    axios
      .put(`${servicePath}/subscribe/deliveryEdit/${id}`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.getOrderDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.data = null

    this.orderDetail = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.search = ''

    this.response = null
  }
}
