import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'

export default class TagStore {
  @observable
  data = null

  @observable
  tagDetail = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = ['name_en']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  tag_en = ''

  @observable
  tag_ar = ''

  @observable
  btnDisable = false

  @observable
  ar_tag_validation = null

  @observable
  en_tag_validation = null

  @observable
  openDeleteModal = false

  @observable
  response = null

  @action
  getData = (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    axios
      .post(`${servicePath}/tag/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data.items
        this.pagination =
          parseInt(data.data.pagination.total / this.limit) +
          (data.data.pagination.total % this.limit ? 1 : 0)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async validation(history) {
    this.en_tag_validation = await checkInput({
      name: 'English Title',
      value: this.tag_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_tag_validation = await checkInput({
      name: 'Arabic Title',
      value: this.tag_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_tag_validation.res &&
      this.en_tag_validation.res &&
      this.handleSaveNewTag(history)
  }

  @action
  async updaateValidation(id, history) {
    this.en_tag_validation = await checkInput({
      name: 'English Title',
      value: this.tagDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_tag_validation = await checkInput({
      name: 'Arabic Title',
      value: this.tagDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_tag_validation.res &&
      this.en_tag_validation.res &&
      this.handleUpdateTag(id, history)
  }

  @action
  handleSaveNewTag = (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      name_en: this.tag_en,
      name_ar: this.tag_ar,
    }

    axios
      .post(`${servicePath}/tag/add`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.openModal = false
        this.btnDisable = false
        await this.reset()
        history.push(`/app/tag/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.btnDisable = false
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.tag_ar = ''
    this.tag_en = ''
    this.ar_tag_validation = null
    this.en_tag_validation = null
  }

  @action
  changeEnglishTag = (value) => {
    this.tag_en = value
  }

  @action
  changeArabicTag = (value) => {
    this.tag_ar = value
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  getTagDetail = async (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/tag/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.tagDetail = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  handleUpdateTag = async (id, history) => {
    this.isLoading = false
    const body = {
      name_en: this.tagDetail.name_en,
      name_ar: this.tagDetail.name_ar,
    }

    axios
      .post(`${servicePath}/tag/${id}`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        await this.reset()
        this.getTagDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  deleteTag = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/tag/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        this.reset()
        history.push('/app/tag')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.openDeleteModal = false
      })
  }

  @action
  changeEnTag = (value) => {
    this.tagDetail.name_en = value
  }

  @action
  changeArTag = (value) => {
    this.tagDetail.name_ar = value
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.tagDetail = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.search = ''

    this.searchOn = ['name_en']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.tag_en = ''

    this.tag_ar = ''

    this.btnDisable = false

    this.ar_tag_validation = null

    this.en_tag_validation = null

    this.openDeleteModal = false
  }
}
