import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import moment from 'moment'
import { sum } from 'lodash'

export default class CalculationStore {
  @observable
  data = []

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  search = ''

  @observable
  searchOn = ['name_en', 'name_ar']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  startDate = new Date(new Date().setDate(0)).setDate(1)

  @observable
  endDate = new Date()

  @observable
  selectedOrderOption = {
    column: 'name_en',
    label: 'English Name',
  }

  @observable
  orderOptions = [
    { column: 'name_en', label: 'English Name' },
    { column: 'name_en_asc', label: 'English Name (Ascending)' },
    { column: 'normal_order_item_count', label: 'Normal Item Count' },
    {
      column: 'normal_order_item_count_asc',
      label: 'Normal Item Count (Ascending)',
    },
    {
      column: 'normal_order_weight_total_kilograms',
      label: 'Normal Item Weight',
    },
    {
      column: 'normal_order_weight_total_kilograms_asc',
      label: 'Normal Item Weight (Ascending)',
    },
    { column: 'whole_sale_orders_count', label: 'Whole Sale Count' },
    {
      column: 'whole_sale_orders_count_asc',
      label: 'Whole Sale Count (Ascending)',
    },
    { column: 'whole_sale_weight_kilos', label: 'Whole Sale Weight' },
    {
      column: 'whole_sale_weight_kilos_asc',
      label: 'Whole Sale Weight (Ascending)',
    },
    { column: 'sum', label: 'Sum' },
    { column: 'sum_asc', label: 'Sum (Ascending)' },
  ]

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      start_date: moment(this.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.endDate).format('YYYY-MM-DD'),
    }
    await axios
      .post(`${servicePath}/order/sales/quantity`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.data = await data.data
        this.data.forEach((item) => {
          item.sum = sum([
            item.normal_order_weight_total_kilograms,
            item.whole_sale_weight_kilos,
          ])
        })
        this.data = await this.data.slice().sort((a, b) => {
          let x = a.name_en.toLowerCase()
          let y = b.name_en.toLowerCase()
          return y < x ? -1 : y > x ? 1 : 0
        })
        this.isLoading = false
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  async handleKeyPress(e, history) {
    if (e.key === 'Enter') {
      this.currentPage = 1
      this.offset = 0
      this.getData(history)
    }
  }

  @action
  handleChangeSearch(value) {
    this.search = value
  }

  @action
  changeStartDate = (date) => {
    this.startDate = date
  }

  @action
  changeEndDate = (date) => {
    this.endDate = date
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  changeOrderByList = async (column) => {
    this.isLoading = true
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )

    this.data = await this.data.slice().sort((a, b) => {
      if (this.selectedOrderOption.column.includes('name_en')) {
        if (this.selectedOrderOption.column.includes('_asc')) {
          let x = a.name_en.toLowerCase()
          let y = b.name_en.toLowerCase()
          return x < y ? -1 : x > y ? 1 : 0
        } else {
          let x = a.name_en.toLowerCase()
          let y = b.name_en.toLowerCase()
          return y < x ? -1 : y > x ? 1 : 0
        }
      } else {
        if (this.selectedOrderOption.column.includes('_asc')) {
          return (
            a[this.selectedOrderOption.column.split('_asc')[0]] -
            b[this.selectedOrderOption.column.split('_asc')[0]]
          )
        } else {
          return (
            b[this.selectedOrderOption.column] -
            a[this.selectedOrderOption.column]
          )
        }
      }
    })
    this.offset = 0
    this.currentPage = 1
    this.isLoading = false
  }

  @action
  changeOrderBy = async (column) => {
    if (this.data.length) {
      if (this.selectedOrderOption.column === column) {
        let target = column.includes('_asc')
          ? column.split('_asc')[0]
          : `${column}_asc`
        this.selectedOrderOption = await this.orderOptions.find(
          (x) => x.column === target
        )

        this.data = await this.data.slice().sort((a, b) => {
          if (this.selectedOrderOption.column.includes('name_en')) {
            if (this.selectedOrderOption.column.includes('_asc')) {
              let x = a.name_en.toLowerCase()
              let y = b.name_en.toLowerCase()
              return x < y ? -1 : x > y ? 1 : 0
            } else {
              let x = a.name_en.toLowerCase()
              let y = b.name_en.toLowerCase()
              return y < x ? -1 : y > x ? 1 : 0
            }
          } else {
            if (this.selectedOrderOption.column.includes('_asc')) {
              return (
                a[this.selectedOrderOption.column.split('_asc')[0]] -
                b[this.selectedOrderOption.column.split('_asc')[0]]
              )
            } else {
              return (
                b[this.selectedOrderOption.column] -
                a[this.selectedOrderOption.column]
              )
            }
          }
        })
        this.offset = 0
        this.currentPage = 1
        this.isLoading = false
      } else {
        let target = column
        this.selectedOrderOption = await this.orderOptions.find(
          (x) => x.column === target
        )
        this.data = await this.data.slice().sort((a, b) => {
          if (this.selectedOrderOption.column.includes('name_en')) {
            if (this.selectedOrderOption.column.includes('_asc')) {
              let x = a.name_en.toLowerCase()
              let y = b.name_en.toLowerCase()
              return x < y ? -1 : x > y ? 1 : 0
            } else {
              let x = a.name_en.toLowerCase()
              let y = b.name_en.toLowerCase()
              return y < x ? -1 : y > x ? 1 : 0
            }
          } else {
            if (this.selectedOrderOption.column.includes('_asc')) {
              return (
                a[this.selectedOrderOption.column.split('_asc')[0]] -
                b[this.selectedOrderOption.column.split('_asc')[0]]
              )
            } else {
              return (
                b[this.selectedOrderOption.column] -
                a[this.selectedOrderOption.column]
              )
            }
          }
        })
        this.offset = 0
        this.currentPage = 1
        this.isLoading = false
      }
    }
  }

  @action
  reset() {
    this.data = []

    this.pagination = 0

    this.currentPage = 1

    this.search = ''

    this.searchOn = ['name_en', 'name_ar']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.startDate = new Date(new Date().setDate(0)).setDate(1)

    this.endDate = new Date()

    this.selectedOrderOption = {
      column: 'name_en',
      label: 'English Name',
    }

    this.orderOptions = [
      { column: 'name_en', label: 'English Name' },
      { column: 'name_en_asc', label: 'English Name (Ascending)' },
      { column: 'normal_order_item_count', label: 'Normal Item Count' },
      {
        column: 'normal_order_item_count_asc',
        label: 'Normal Item Count (Ascending)',
      },
      {
        column: 'normal_order_weight_total_kilograms',
        label: 'Normal Item Weight',
      },
      {
        column: 'normal_order_weight_total_kilograms_asc',
        label: 'Normal Item Weight (Ascending)',
      },
      { column: 'whole_sale_weight_kilos', label: 'Whole Sale Count' },
      {
        column: 'whole_sale_weight_kilos_asc',
        label: 'Whole Sale Count (Ascending)',
      },
      { column: 'whole_sale_weight_kilos', label: 'Whole Sale Weight' },
      {
        column: 'whole_sale_weight_kilos_asc',
        label: 'Whole Sale Weight (Ascending)',
      },
      { column: 'sum', label: 'Sum' },
      { column: 'sum_asc', label: 'Sum (Ascending)' },
    ]
  }
}
