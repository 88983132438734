import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from "reactstrap";
// import Select from "react-select";
import IntlMessages from "../../util/IntlMessages";
import { Colxx } from "../CustomBootstrap";

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      openModal,
      toggleModal,
      name_en,
      email,
      changeEmail,
      changeEnName,
      handleSave,
      btnDisable,
      name_en_validation,
      email_validation
    } = this.props;
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody className="p-2">
          <Colxx xxs="12" className="mb-3 ">
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.personName" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="name_ar"
                  value={name_en ? name_en : ""}
                  type="text"
                  onChange={e => changeEnName(e.target.value)}
                />
              </p>
              <span className="req-validation">
                {" "}
                {name_en_validation
                  ? name_en_validation.msg
                    ? name_en_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>

            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.email" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="email"
                  value={email ? email : ""}
                  type="text"
                  onChange={e => changeEmail(e.target.value)}
                />
              </p>
              <span className="req-validation">
                {" "}
                {email_validation
                  ? email_validation.msg
                    ? email_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => handleSave()}
          >
            Save
          </Button>{" "}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UserModal;
