import LoginStore from './login.store'
import SettingStore from './setting.store'
import MenuStore from './menu.store'
import ProductStore from './product.store'
import ProductDetailStore from './productDetail.store'
import AddProductStore from './addProduct.store'
import CategoryStore from './category.store'
import SocialStore from './social.store'
import CustomerStore from './customer.store'
import CustomerDetailStore from './customerDetail.store'
import FeaturetteStore from './featurette.store'
import FeaturetteDetailStore from './featuretteDetail.store'
import OrderStore from './order.store'
import SubscribeOrderStore from './subscribeOrder.store'
import TagStore from './tag.store'
import WholesaleStore from './wholesale.store'
import BrewStore from './brew.store'
import BrewDetailStore from './brewDetail.store'
import RoastStore from './roast.store'
import RoastDetailStore from './roastDetail.store'
import BlogStore from './blog.store'
import MemoryStore from './memory.store'
import MemoryDetailStore from './memoryDetail.store'
import BlogDetailStore from './blogDetail.store'
import BrandStore from './brand.store'
import BrandDetailStore from './brandDetail.store'
import ProfileStore from './profile.store'
import CareersStore from './careers.store'
import ContactUsStore from './contactUs.store'
import UserStore from './user.store'
import UserDetailStore from './userDetail.store'
import OurStoryStore from './ourStory.store'
import RecipesStore from './recipes.store'
import RecipesDetailStore from './recipesDetail.store'
import EventStore from './event.store'
import EventDetailStore from './eventDetail.store'
import FaqStore from './faq.store'
import HomePageStore from './homePage.Store'
import DashboardStore from './dashboard.store'
import BannerStore from './banner.store'
import BannerDetailStore from './bannerDetail.store'
import CouponStore from './coupon.store'
import FaqDetailStore from './faqDetail.store'
import AreaStore from './area.store'
// import AuthoreaStore from "./author.store"
import FaqCategoryStore from './faqCategory.store'
import CalculationStore from './calculation.store'
import DynamicMenuStore from './dynamicMenu.store'
import DynamicMenuDetailStore from './dynamicMenuDetail.store'
import TermsStore from './terms.store'
import DeliveryTimeStore from './deliveryTime.store'


const login = new LoginStore()
const setting = new SettingStore()
const menu = new MenuStore()
const product = new ProductStore()
const productDetail = new ProductDetailStore()
const addProduct = new AddProductStore()
const category = new CategoryStore()
const social = new SocialStore()
const customer = new CustomerStore()
const customerDetail = new CustomerDetailStore()
const featurette = new FeaturetteStore()
const featuretteDetail = new FeaturetteDetailStore()
const order = new OrderStore()
const subscribeOrder = new SubscribeOrderStore()
const tag = new TagStore()
const wholesale = new WholesaleStore()
const brew = new BrewStore()
const brewDetail = new BrewDetailStore()
const roast = new RoastStore()
const roastDetail = new RoastDetailStore()
const blog = new BlogStore()
const blogDetail = new BlogDetailStore()
const memory = new MemoryStore()
const memoryDetail = new MemoryDetailStore()
const recipes = new RecipesStore()
const recipesDetail = new RecipesDetailStore()
const event = new EventStore()
const eventDetail = new EventDetailStore()
const brand = new BrandStore()
const brandDetail = new BrandDetailStore()
const profile = new ProfileStore()
const careers = new CareersStore()
const contactUs = new ContactUsStore()
const user = new UserStore()
const userDetail = new UserDetailStore()
const ourStory = new OurStoryStore()
const faq = new FaqStore()
const faqDetail = new FaqDetailStore()
const homePage = new HomePageStore()
const dashboard = new DashboardStore()
const banner = new BannerStore()
const bannerDetail = new BannerDetailStore()
const coupon = new CouponStore()
const area = new AreaStore()
const faqCategory = new FaqCategoryStore()
const calculation = new CalculationStore()
const dynamicMenu = new DynamicMenuStore()
const dynamicMenuDetail = new DynamicMenuDetailStore()
const terms = new TermsStore()
const delivery = new DeliveryTimeStore()

export default {
  login,
  setting,
  menu,
  product,
  productDetail,
  addProduct,
  category,
  social,
  customer,
  customerDetail,
  featurette,
  featuretteDetail,
  order,
  subscribeOrder,
  tag,
  wholesale,
  brew,
  brewDetail,
  roast,
  roastDetail,
  blog,
  blogDetail,
  brand,
  brandDetail,
  profile,
  careers,
  contactUs,
  user,
  ourStory,
  memory,
  memoryDetail,
  recipes,
  recipesDetail,
  event,
  eventDetail,
  faq,
  homePage,
  dashboard,
  banner,
  bannerDetail,
  coupon,
  faqDetail,
  userDetail,
  area,
  faqCategory,
  calculation,
  dynamicMenu,
  dynamicMenuDetail,
  terms,
  delivery
}
