import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
// import axiFetch from "../config/fetch";

export default class CategoryStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  categoryDetail = null

  @observable
  search = ''

  @observable
  searchOn = ['name_en']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  openDeleteModal = false

  @observable
  showType = 'row'

  @observable
  newArName = ''

  @observable
  newEnName = ''

  @observable
  ar_name_validation = null

  @observable
  en_name_validation = null

  @observable
  btnDisable = false

  @observable
  response = null

  @action
  getData = async (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }

    axios
      .post(`${servicePath}/category/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.data = data.data.items
        this.pagination =
          parseInt(data.data.pagination.total / this.limit) +
          (data.data.pagination.total % this.limit ? 1 : 0)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getCategoryDetail = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/category/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.categoryDetail = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = true
      })
  }

  @action
  deleteCategory = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/category/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data)
        this.openDeleteModal = false
        this.reset()
        history.push('/app/category')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.openDeleteModal = false
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.openDeleteModal = false
        this.isLoading = true
        this.response = null
      })
  }

  @action
  async validationUpdate(id,history) {
    this.en_name_validation = await checkInput({
      name: 'English Title',
      value: this.categoryDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Title',
      value: this.categoryDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateCategory(id,history)
  }

  @action
  handleUpdateCategory = (id, history) => {
    this.isLoading = false
    let data = {
      name_en: this.categoryDetail.name_en,
      name_ar: this.categoryDetail.name_ar,
    }

    axios
      .post(`${servicePath}/category/${id}`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(() => {
        this.reset()
        this.getCategoryDetail(id)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Title',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Title',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleSaveNewCategory(history)
  }

  @action
  handleSaveNewCategory = (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
    }

    axios
      .post(`${servicePath}/category/add`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        this.reset()
        history.push(`/app/category/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = ''
    this.newEnName = ''
    this.en_name_validation = null
    this.ar_name_validation = null
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeEnName = (name) => {
    this.categoryDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.categoryDetail.name_ar = name
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.categoryDetail = null

    this.search = ''

    this.searchOn = ['name_en']

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.openDeleteModal = false

    this.showType = 'row'

    this.newArName = ''

    this.newEnName = ''

    this.ar_name_validation = null

    this.en_name_validation = null

    this.btnDisable = false
  }
}
