import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Label,
} from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import { Thumb } from "../../../components/Thumb";
import { ToastContainer, toast } from "react-toastify";

@inject("stores")
@observer
class ProfileList extends Component {
  @observable
  store = this.props.stores.profile;

  @observable
  setting = this.props.stores.setting;

  constructor(props) {
    super(props);
    this.state = { id: null, imageFile: null, activeFirstTab: "1" };
  }
  componentDidMount() {
    this.store.getData(this.props.history);
  }

  componentWillUnmount() {
    this.store.reset();
  }

  notify = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      default:
        break;
    }
  };

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type);
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="d-flex align-items-center">
                <Colxx xxs="8">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.profile" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx xxs="4" className="float-right">
                  <Button
                    color="primary"
                    className="btn-shadow float-right mb-3"
                    size="lg"
                    onClick={() => this.store.validation(this.props.history)}
                  >
                    <IntlMessages id="pages.save" />
                  </Button>
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
              {!this.store.isLoading ? (
                <div className="loading"></div>
              ) : (
                <Colxx xxs="12">
                  <Colxx xxs="12">
                    <Row className="d-flex justify-content-center">
                      <Colxx
                        xxs="12"
                        lg="12"
                        className="mb-6 border-bottom"
                        style={{ marginBottom: "25px" }}
                      >
                        <Row>
                          <Colxx
                            xxs="12"
                            sm="12"
                            md="6"
                            lg="4"
                            className="mb-4"
                          >
                            <Card className="mb-2 ">
                              <CardBody>
                                <CardTitle className="mb-3">
                                  <span className="font-weight-bold text-uppercase">
                                    {`image`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                <Button
                                  color="primary"
                                  size="lg"
                                  className="pointer mb-3 col-12"
                                  style={{}}
                                >
                                  <Label
                                    className="btn btn-default no-padding col-12"
                                    style={{
                                      padding: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <strong>Change Image</strong>
                                    <input
                                      className="col-12 p-0 "
                                      id="image"
                                      name="image"
                                      type="file"
                                      accept="image/x-png,image/jpeg"
                                      onChange={(event) => {
                                        this.store.changeImageFile(
                                          event.currentTarget.files[0]
                                        );
                                      }}
                                      hidden
                                    />
                                  </Label>
                                </Button>
                                <Thumb
                                  key="cat-image"
                                  className="almost-full-width"
                                  file={this.store.imageFile}
                                  src={
                                    this.store.data
                                      ? this.store.data.image
                                        ? this.store.data.image.url
                                        : "/assets/img/noimage.png"
                                      : "/assets/img/noimage.png"
                                  }
                                />
                              </CardBody>
                            </Card>
                          </Colxx>
                          <Colxx
                            xxs="12"
                            sm="12"
                            md="6"
                            lg="4"
                            className="mb-8"
                          >
                            <Card className="mb-2">
                              <CardBody>
                                <CardTitle className="mb-3">
                                  <span className="font-weight-bold text-uppercase">
                                    {`Detail`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                <Colxx
                                  xxs="12"
                                  className="p-0 d-flex flex-wrap"
                                >
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.email" />
                                    </p>
                                    <p className="mb-3">
                                      {this.store.data
                                        ? this.store.data.email
                                        : ""}
                                    </p>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.personName" />
                                      <span className="req"> *</span>
                                    </p>
                                    <p className="mb-3">
                                      <Input
                                        name="name"
                                        value={
                                          this.store.data
                                            ? this.store.data.name
                                            : ""
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeEnName(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </p>
                                    <span className="req-validation ">
                                      {" "}
                                      {this.store.name_validation
                                        ? this.store.name_validation.msg
                                          ? this.store.name_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.about" />
                                      <span className="req"> *</span>
                                    </p>
                                    <p className="mb-3">
                                      <Input
                                        name="name"
                                        style={{
                                          height: 150,
                                          borderRadius: "0.75rem",
                                        }}
                                        value={
                                          this.store.data
                                            ? this.store.data.about_en
                                            : ""
                                        }
                                        type="textarea"
                                        onChange={(e) =>
                                          this.store.changeEnAbout(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </p>
                                    <span className="req-validation ">
                                      {" "}
                                      {this.store.about_en_validation
                                        ? this.store.about_en_validation.msg
                                          ? this.store.about_en_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.about-ar" />
                                    </p>
                                    <p className="mb-3">
                                      <Input
                                        name="name"
                                        className="ar"
                                        style={{
                                          height: 150,
                                          borderRadius: "0.75rem",
                                        }}
                                        value={
                                          this.store.data
                                            ? this.store.data.about_ar
                                            : ""
                                        }
                                        type="textarea"
                                        onChange={(e) =>
                                          this.store.changeArAbout(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </p>
                                  </Colxx>
                                </Colxx>
                              </CardBody>
                            </Card>
                          </Colxx>
                        </Row>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Colxx>
              )}
            </Row>
          </>
        )}
      </Fragment>
    );
  }
}

export default ProfileList;
