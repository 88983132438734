import React, { Component } from 'react'
import {
  Card,
  CardImg,
  CardSubtitle,
  Row,
  CardBody,
  Button,
  CardText,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { Colxx } from '../CustomBootstrap'

class CardImageList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      data,
      path,
      click,
      deleteImage,
      paddingTop,
      xl,
      lg,
      sm,
      imageWidth,
      imageLeft,
      imageTop,
      imageRadius,
      imageHeight,
      opacity=1
    } = this.props
    return (
      <Colxx
        sm={sm ? sm : '6'}
        lg={lg ? lg : '4'}
        xl={xl ? xl : '3'}
        className="mb-3"
        key={data.id}
        style={{opacity:opacity}}
      >
        {path ? (
          <NavLink to={`/app/${path}/${data.id}`}>
            <Card>
              <div
                className="position-relative d-flex justify-content-center align-items-center"
                style={{
                  width: '100%',
                  paddingTop: paddingTop ? paddingTop : '100%',
                }}
              >
                <CardImg
                  top
                  alt={data.name}
                  style={{
                    width: imageWidth ? imageWidth : '100%',
                    height:imageHeight?imageHeight:'100%',
                    left: imageLeft ? imageLeft : 0,
                    borderRadius: imageRadius? imageRadius: 0,
                    top: imageTop ? imageTop : 0,
                  }}
                  src={
                    path === 'product/detail'
                      ? data.images
                        ? data.images.url
                        : '/assets/img/noimage.png'
                      : data.image
                      ? data.image.url
                      : data.image_en
                      ? data.image_en.url
                      : '/assets/img/noimage.png'
                  }
                />
              </div>
              {data.name ? (
                <CardBody className="p-3">
                  <Row className="p-0">
                    <Colxx xxs="12">
                      <CardText className="text-one truncate">{`${data.name}`}</CardText>
                    </Colxx>
                    {data.price ? (
                      <Colxx xxs="12" className="mt-2">
                        <CardText className="text-one">{`${data.price} KD`}</CardText>
                      </Colxx>
                    ) : data.is_subscribe ? (
                      <Colxx xxs="12" className="mt-2">
                        <CardText className="text-one">Is Subscribe</CardText>
                      </Colxx>
                    ) : null}
                  </Row>
                </CardBody>
              ) : null}

              {data.title ? (
                <CardBody className="p-3">
                  <Row className="p-0">
                    <Colxx xxs="12">
                      <CardText className="text-one truncate">{`${data.title}`}</CardText>
                    </Colxx>
                  </Row>
                </CardBody>
              ) : null}
            </Card>
          </NavLink>
        ) : (
          <Card className="pointer" onClick={click}>
            <div className="position-relative">
              <Button
                onClick={() => deleteImage(data.id)}
                color="primary"
                style={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  padding: '0',
                  right: '5px',
                  top: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  zIndex: 1,
                }}
              >
                <i style={{ fontSize: '20px' }} className="iconsminds-close" />
              </Button>
              <div
                className="position-reletive"
                style={{ width: '100%', paddingTop: '75%' }}
              >
                <CardImg
                  top
                  alt={data.name_en}
                  src={
                    path === 'product/detail'
                      ? data.images
                        ? data.images.url
                        : '/assets/img/noimage.png'
                      : data.image
                      ? data.image.url
                      : '/assets/img/noimage.png'
                  }
                />
              </div>
            </div>
            {data.name ? (
              <CardBody className="p-3">
                <Row className="p-0">
                  <Colxx xxs="12">
                    <CardSubtitle>{`${data.name}`}</CardSubtitle>
                  </Colxx>
                </Row>
              </CardBody>
            ) : null}
          </Card>
        )}
      </Colxx>
    )
  }
}

export default CardImageList
