import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
// import { checkInput } from "../constants/validation";

export default class SocialStore {
  @observable
  data = [
    { name: 'instagram', text: '' },
    { name: 'twitter', text: '' },
    { name: 'facebook', text: '' },
    { name: 'skype', text: '' },
    { name: 'email', text: '' },
    { name: 'whatsapp', text: '' },
  ]

  @observable
  link = null

  @observable
  validationMsg = null

  @observable
  isLoading = false

  @observable
  response = null

  @action
  validation = (history) => {
    this.handleSaveSocial(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = false

    axios
      .get(`${servicePath}/link`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.link = data.data
        this.data.forEach((item) => {
          data.data.forEach((link) => {
            if (item.name === link.field) {
              item.text = link.value
            }
          })
        })
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  changeSocialAddress = (target, value) => {
    this.data.forEach((item) => {
      if (item.name === target) {
        item.text = value
      }
    })
  }

  @action
  handleSaveSocial = async (history) => {
    this.isLoading = false
    const field = []
    await this.data.forEach((item) => {
      field.push({ field: item.name, value: item.text, type: 'social' })
    })
    const data = { fields: field }

    axios
      .post(`${servicePath}/link/set`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.getData(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  reset = () => {
    this.data = [
      { name: 'instagram', text: '' },
      { name: 'twitter', text: '' },
      { name: 'facebook', text: '' },
      { name: 'skype', text: '' },
      { name: 'email', text: '' },
      { name: 'whatsapp', text: '' },
      // { name: "phone", text: "" }
    ]

    this.link = null

    this.validationMsg = null

    this.isLoading = false
  }
}
