import React, { Component } from 'react';
import Select from 'react-select';

class SelectInput extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    handleChange = selectedOption => {
       this.props.onChange({ selectedOption });
      };
    
    render() { 
        const {value, options, isMulti} = this.props
        return ( 
            <Select
            className={`react-select ${this.props.className}`}
            classNamePrefix="react-select"
            value={value}
            onChange={this.handleChange}
            options={options}
            isMulti = {isMulti}
          />
         );
    }
}
 
export default SelectInput;