import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DeliveryTimeList from './list';

const DeliveryTime = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={DeliveryTimeList} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default DeliveryTime;