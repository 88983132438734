import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
  Card,
  CardBody,
  CardText,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import IntlMessages from "../../../util/IntlMessages";
import Pagination from "../../../components/List/Pagination";

@inject("stores")
@observer
class CareersList extends Component {
  @observable
  store = this.props.stores.careers;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.store.getData(this.props.history);
  }

  componentWillUnmount() {
    // this.store.reset();
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.store.getData(this.props.history);
    }
  }

  hendleChangeKey(e) {
    this.store.changeSearch(e.target.value.toLowerCase());
  }

  render() {
    // const { messages } = this.props.intl;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Colxx xxs="12" className="d-flex flex-wrap justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.careers" />}
                  match={this.props.match}
                />
              </div>

              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none ">
                <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top ">
                    <input
                  className="col-12"
                  type="text"
                  name="keyword"
                  id="search"
                  value={this.store.search}
                  placeholder={`Search`}
                  onChange={e => this.store.hendleChangeKey(e.target.value)}
                  onKeyPress={event => this.store.handleKeyPress(event)}
                />
                  </div>
                </div>
              </div>
            </Colxx>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="pl-0 d-xl-block d-md-none d-none">
                <div
                  className="col-12 p-3 d-flex align-items-center mb-3"
                  style={{ borderBottom: "1px solid gray" }}
                >
                    <p className="col-3 p-2 m-0 text-muted font-weight-bold text-center">
                    Position
                  </p>
                  <p className="col-3 p-2 m-0 text-muted font-weight-bold text-center">
                    Name
                  </p>

                  <p className="col-3 p-2 m-0 text-muted font-weight-bold text-center">
                    Phone
                  </p>

                  <p className="col-3 p-2 m-0 text-muted font-weight-bold text-center">
                    Date
                  </p>
                </div>
                {/* </div> */}
              </Colxx>
              {this.store.data
                ? this.store.data.map(item => (
                    <Colxx
                    key={item.id}
                      xxs="12"
                      sm="6"
                      md="4"
                      lg="4"
                      xl="12"
                      className=" mb-3 p-0"
                    >
                      <NavLink
                        to={`/app/careers/detail/${item.id}`}
                        className="col-12 d-flex flex-wrap"
                      >
                        <Card className="col-12 pl-0 pr-0 pointer">
                          <CardBody className="d-flex  pl-0 pr-0 align-items-center flex-wrap">
                            <div className="position-relative col-12 d-flex flex-wrap p-xl-0 p-md-3">
                            <CardText className="col-xl-3 col-md-12  p-2 m-0  font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Position :{" "}
                                </span>
                                {`${item.title}`}
                              </CardText>
                              <CardText className="col-xl-3 col-md-12  p-2 m-0  font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Name :{" "}
                                </span>
                                {`${item.name}`}
                              </CardText>
                              <CardText className="col-xl-3 col-md-12 p-2 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  phone:{" "}
                                </span>
                                {item.phone}
                              </CardText>
                              <CardText className="col-xl-3 col-md-12 p-2 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Date :{" "}
                                </span>
                                {item.CDate.split("T")[0]}
                              </CardText>
                              
                            </div>
                          </CardBody>
                        </Card>
                      </NavLink>
                    </Colxx>
                  ))
                : null}
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={e => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    );
  }
}

export default CareersList;
