import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Label,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { Thumb } from '../../../components/Thumb'
import { ToastContainer, toast } from 'react-toastify'
import ImageUploader from '../../../components/Uploader/Uploader'

@inject('stores')
@observer
class OurStoryList extends Component {
  @observable
  store = this.props.stores.ourStory

  @observable
  setting = this.props.stores.setting

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, activeFirstTab: '1', getImage:false }
  }
  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  toggleTab = (id) => {
    this.setState({ activeFirstTab: id })
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFileEnglish = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploderEnglish(data)
    } else {
      this.setState({ getImage: false })
    }
    // this.store.showUploaderArabic &&
    //   !this.store.imageFileAr &&
      this.store.handleUpdateOurStory(this.props.history)
  }

  // changeImageFileArabic = async (data) => {
  //   if (data) {
  //     this.setState({ getImage: false })
  //     await this.store.changeImageFileUploderArabic(data)
  //   } else {
  //     this.setState({ getImage: false })
  //   }
  //   this.store.handleUpdateBanner(this.state.id, this.props.history)
  // }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        <Row>
          <Colxx xxs="12" className="d-flex align-items-center">
            <Colxx xxs="8">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.ourStory" />}
                match={this.props.match}
              />
            </Colxx>
            <Colxx xxs="4" className="float-right">
              <Button
                color="primary"
                className="btn-shadow float-right mb-3"
                size="lg"
                onClick={() => {
                  this.store.showUploaderEnglish
                    ? this.store.handleUpdateOurStory(this.props.history)
                    : this.handleSave()
                }}
              >
                <IntlMessages id="pages.save" />
              </Button>
            </Colxx>
          </Colxx>
          <Colxx xxs="12">
            <Separator className="mb-5" />
          </Colxx>
          {!this.store.isLoading ? (
            <div className="loading"></div>
          ) : (
            <Colxx xxs="12">
              <Colxx xxs="12">
                <Row className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: '25px' }}
                  >
                    <Row>
                      <Colxx xxs="12" lg="6" className="mb-4">
                        <Card className="mb-2 h-100">
                          <CardBody className="p-3">
                            <CardTitle className="mb-3 pt-3 pr-3 pl-3">
                              <span className="font-weight-bold text-uppercase">
                                {`Header Image`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx
                              xxs="12"
                              className="p-0 d-flex flex-wrap"
                            >

{this.store.showUploaderEnglish ? (
                            <>
                              {' '}
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploaderEnglish()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Header Image</strong>
                                </Label>
                              </Button>
                              <Thumb
                                imageSize={{
                                  imageHeight: '85%',
                                  // imageTop: 5,
                                }}
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.imageFileEn}
                                src={
                                  this.store.data
                                    ? this.store.data.cover &&
                                      this.store.data.cover.url
                                    : '/assets/img/noimage.png'
                                }
                              />
                            </>
                          ) : (
                            <ImageUploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              getImage={this.state.getImage}
                              flag={true}
                              ratio="3:2"
                              size={{ width: 1920, height:1280  }}
                              handleImageUploader={() =>
                                this.store.handleImageUploaderEnglish()
                              }
                              changeImageFile={(data) => {
                                this.changeImageFileEnglish(data)
                              }}
                            />
                          )}
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                      <Colxx xxs="12" lg="6" className="mb-4">
                        <Card className="mb-2 h-100">
                          <CardBody className="p-3">
                            <CardTitle className="mb-3 pt-3 pr-3 pl-3">
                              <span className="font-weight-bold text-uppercase">
                                {`Start Detail`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.description" />
                                  {/* <span className="req"> *</span> */}
                                </p>
                                <p className="mb-3">
                                  <Input
                                    name="name"
                                    value={
                                      this.store.data
                                        ? this.store.data.en.start.content
                                        : ''
                                    }
                                    type="textarea"
                                    style={{
                                      minHeight: 150,
                                      borderRadius: '0.75rem',
                                    }}
                                    onChange={(e) =>
                                      this.store.changeDescription(
                                        'en',
                                        'start',
                                        e.target.value
                                      )
                                    }
                                  />
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.description-ar" />
                                  {/* <span className="req"> *</span> */}
                                </p>
                                <p className="mb-3">
                                  <Input
                                    name="name"
                                    className="ar"
                                    value={
                                      this.store.data
                                        ? this.store.data.ar.start.content
                                        : ''
                                    }
                                    type="textarea"
                                    style={{
                                      minHeight: 150,
                                      borderRadius: '0.75rem',
                                    }}
                                    onChange={(e) =>
                                      this.store.changeDescription(
                                        'ar',
                                        'start',
                                        e.target.value
                                      )
                                    }
                                  />
                                </p>
                              </Colxx>

                              <Colxx xxs="12">
                                <Button
                                  color="primary"
                                  size="lg"
                                  className="pointer mb-3 col-12"
                                  style={{}}
                                >
                                  <Label
                                    className="btn btn-default no-padding col-12"
                                    style={{
                                      padding: '0px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    <strong>Change Start Image</strong>
                                    <input
                                      className="col-12 p-0 "
                                      id="image"
                                      name="image"
                                      type="file"
                                      accept="image/x-png,image/jpeg"
                                      onChange={(event) => {
                                        this.store.changeStartImageFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                      hidden
                                    />
                                  </Label>
                                </Button>
                                <Thumb
                                  key="cat-image"
                                  className="almost-full-width"
                                  file={this.store.startImageFile}
                                  src={
                                    this.store.data
                                      ? this.store.data.start
                                        ? this.store.data.start.url
                                        : '/assets/img/noimage.png'
                                      : '/assets/img/noimage.png'
                                  }
                                />
                              </Colxx>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>

                      <Colxx xxs="12" lg="6" className="mb-4">
                        <Card className="mb-2 h-100">
                          <CardBody className="p-3">
                            <CardTitle className="mb-3 pt-3 pr-3 pl-3">
                              <span className="font-weight-bold text-uppercase">
                                {`Now Detail`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.description" />
                                  {/* <span className="req"> *</span> */}
                                </p>
                                <p className="mb-3">
                                  <Input
                                    name="name"
                                    value={
                                      this.store.data
                                        ? this.store.data.en.now.content
                                        : ''
                                    }
                                    type="textarea"
                                    style={{
                                      minHeight: 150,
                                      borderRadius: '0.75rem',
                                    }}
                                    onChange={(e) =>
                                      this.store.changeDescription(
                                        'en',
                                        'now',
                                        e.target.value
                                      )
                                    }
                                  />
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.description-ar" />
                                  {/* <span className="req"> *</span> */}
                                </p>
                                <p className="mb-3">
                                  <Input
                                    name="name"
                                    className="ar"
                                    value={
                                      this.store.data
                                        ? this.store.data.ar.now.content
                                        : ''
                                    }
                                    type="textarea"
                                    style={{
                                      minHeight: 150,
                                      borderRadius: '0.75rem',
                                    }}
                                    onChange={(e) =>
                                      this.store.changeDescription(
                                        'ar',
                                        'now',
                                        e.target.value
                                      )
                                    }
                                  />
                                </p>
                              </Colxx>

                              <Colxx xxs="12">
                                <Button
                                  color="primary"
                                  size="lg"
                                  className="pointer mb-3 col-12"
                                  style={{}}
                                >
                                  <Label
                                    className="btn btn-default no-padding col-12"
                                    style={{
                                      padding: '0px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    <strong>Change Now Image</strong>
                                    <input
                                      className="col-12 p-0 "
                                      id="image"
                                      name="image"
                                      type="file"
                                      accept="image/x-png,image/jpeg"
                                      onChange={(event) => {
                                        this.store.changeNowImageFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                      hidden
                                    />
                                  </Label>
                                </Button>
                                <Thumb
                                  key="cat-image"
                                  className="almost-full-width"
                                  file={this.store.nowImageFile}
                                  src={
                                    this.store.data
                                      ? this.store.data.now
                                        ? this.store.data.now.url
                                        : '/assets/img/noimage.png'
                                      : '/assets/img/noimage.png'
                                  }
                                />
                              </Colxx>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                      <Colxx xxs="12" lg="6" className="mb-4">
                        <Card className="mb-2 h-100">
                          <CardBody className="p-3">
                            <CardTitle className="mb-3 pt-3 pr-3 pl-3">
                              <span className="font-weight-bold text-uppercase">
                                {`future Detail`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.description" />
                                  {/* <span className="req"> *</span> */}
                                </p>
                                <p className="mb-3">
                                  <Input
                                    name="name"
                                    value={
                                      this.store.data
                                        ? this.store.data.en.future.content
                                        : ''
                                    }
                                    type="textarea"
                                    style={{
                                      minHeight: 150,
                                      borderRadius: '0.75rem',
                                    }}
                                    onChange={(e) =>
                                      this.store.changeDescription(
                                        'en',
                                        'future',
                                        e.target.value
                                      )
                                    }
                                  />
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.description-ar" />
                                  {/* <span className="req"> *</span> */}
                                </p>
                                <p className="mb-3">
                                  <Input
                                    name="name"
                                    className="ar"
                                    value={
                                      this.store.data
                                        ? this.store.data.ar.future.content
                                        : ''
                                    }
                                    type="textarea"
                                    style={{
                                      minHeight: 150,
                                      borderRadius: '0.75rem',
                                    }}
                                    onChange={(e) =>
                                      this.store.changeDescription(
                                        'ar',
                                        'future',
                                        e.target.value
                                      )
                                    }
                                  />
                                </p>
                              </Colxx>

                              <Colxx xxs="12">
                                <Button
                                  color="primary"
                                  size="lg"
                                  className="pointer mb-3 col-12"
                                  style={{}}
                                >
                                  <Label
                                    className="btn btn-default no-padding col-12"
                                    style={{
                                      padding: '0px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    <strong>Change Future Image</strong>
                                    <input
                                      className="col-12 p-0 "
                                      id="image"
                                      name="image"
                                      type="file"
                                      accept="image/x-png,image/jpeg"
                                      onChange={(event) => {
                                        this.store.changeFutureImageFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                      hidden
                                    />
                                  </Label>
                                </Button>
                                <Thumb
                                  key="cat-image"
                                  className="almost-full-width"
                                  file={this.store.futureImageFile}
                                  src={
                                    this.store.data
                                      ? this.store.data.future
                                        ? this.store.data.future.url
                                        : '/assets/img/noimage.png'
                                      : '/assets/img/noimage.png'
                                  }
                                />
                              </Colxx>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Row>
              </Colxx>
            </Colxx>
          )}
        </Row>
      </Fragment>
    )
  }
}

export default OurStoryList
