import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CareersList from './list';
import CareersDetail from "./detail"



const Careers = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={CareersList} />
            <Route path={`${match.url}/detail/:id`} component={CareersDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Careers;