import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MemoryList from './list';
import PostDetail from "./detail"
import AddPost from './add';



const Memory = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={MemoryList} />
            <Route path={`${match.url}/add`} component={AddPost} />
            <Route path={`${match.url}/detail/:id`} component={PostDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Memory;