import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class DynamicMenuDetailStore {
  @observable
  menuDetail = null

  @observable
  isLoading = false

  @observable
  openDeleteModal = false

  @observable
  response = null

  @observable
  name_en_validation = null

  @observable
  name_ar_validation = null

  @observable
  url_validation = null

  @observable
  url = null

  @observable
  urlList = []

  @observable
  parent = null

  @observable
  parentList = []

  @observable
  children = []

  @action
  getDetail = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/menu/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.menuDetail = data.data
        await this.getLink(history)
        await this.getParent(history)
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getLink = async (history) => {
    await axios
      .get(`${servicePath}/menu/linkList`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        await data.data.items.forEach((item, index) => {
          if (item === this.menuDetail.link) {
            this.url = { value: index, label: item }
          }
          this.urlList.push({ value: index, label: item })
        })
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getParent = async (history) => {
    const body = {}
    await axios
      .post(`${servicePath}/menu/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        await data.data.items.forEach((item) => {
          if (item.id === this.menuDetail.parent) {
            this.parent = { value: item.id, label: item.title_en }
          }
          if (item.parent === this.menuDetail.id) {
            this.children.push(item)
          }
          this.parentList.push({ value: item.id, label: item.title_en })
        })
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  changeOrder = async (data, id, history) => {
    this.isLoading = false

    const menu = []
    await data.forEach((item, index) => {
      menu.push({ id: item.id, order: index })
    })

    axios
      .post(`${servicePath}/menu/reorder`, menu, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
         this.reset()
        this.getDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  deleteMenu = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/menu/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        this.reset()
        history.push('/app/menu')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.openDeleteModal = false
      })
  }

  @action
  handleUpdateMenu = (id, history) => {
    this.isLoading = false
    const data = {
      title_en: this.menuDetail.title_en,
      title_ar: this.menuDetail.title_ar,
      link: this.url.label,
      is_visible: this.menuDetail.is_visible ? true : false,
    }
    if (this.parent) {
      data.parent = this.parent.value
    }

    axios
      .post(`${servicePath}/menu/${id}`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async () => {
        this.response = { msg: 'Item has been updated successfully', type: 'success' }
        this.isLoading = true
        this.response = null
        await this.reset()
        this.getDetail(id)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action.bound
  changeNameEn = (name) => {
    this.menuDetail.title_en = name
  }

  @action.bound
  changeNameAr = (name) => {
    this.menuDetail.title_ar = name
  }

  @action
  setUrl = (value) => {
    this.url = value.selectedOption
  }

  @action
  setParent = (value) => {
    this.parent = value.selectedOption
  }

  @action
  handleIsVisible = () => {
    this.menuDetail.is_visible = !this.menuDetail.is_visible
  }

  @action
  reset() {
    this.menuDetail = null

    this.isLoading = false

    this.openDeleteModal = false

    this.response = null

    this.name_en_validation = null

    this.name_ar_validation = null

    this.url_validation = null

    this.url = null

    this.urlList = []

    this.parent = null

    this.parentList = []

    this.children = []
  }
}
