import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
import _ from 'lodash'
import axiFetch from '../config/fetch'

export default class ProductDetailStore {
  @observable
  isLoading = true

  @observable
  openItemModal = false

  @observable
  arName = ''

  @observable
  enName = ''

  @observable
  price = ''

  @observable
  enDescription = ''

  @observable
  arDescription = ''

  @observable
  imageFile = null

  @observable
  images = null

  @observable
  category = null

  @observable
  categoryId = null

  @observable
  categoriesList = []

  @observable
  brewsList = []

  @observable
  brews = null

  @observable
  tags = null

  @observable
  tagsList = []

  @observable
  brandsList = []

  @observable
  brand = null

  @observable
  data = null

  @observable
  productDetail = null

  @observable
  orderImages = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  response = null

  @observable
  openDeleteModal = false

  @observable
  en_name_validation = null

  @observable
  sku_validation = null

  @observable
  tag_validation = null

  @observable
  price_validation = null

  @observable
  category_validation = null

  @observable
  brand_validation = null

  @observable
  height_validation = null

  @observable
  length_validation = null

  @observable
  width_validation = null

  @observable
  weight_validation = null

  @observable
  image_validation = null

  @observable
  brew_validation = null

  @observable
  searchProduct = null

  @observable
  searchOnProduct = null

  @observable
  limitProduct = 12

  @observable
  offsetProduct = 0

  @observable
  orderProduct = { id: -1 }

  @observable
  filterProduct = {}

  @observable
  discount = true

  @observable
  currentPageProduct = 1

  @observable
  isLoadingProduct = false

  @observable
  openProductModal = false

  @observable
  paginationProduct = null

  @observable
  productList = []

  @observable
  response = null

  @observable
  showUploader = true

  @observable
  subscribeValue = {
    weight: 0,
    price: 0,
    sku: '',
    dimension_x: '',
    dimension_y: '',
    dimension_z: '',
  }

  @observable
  intervalList = []

  @observable
  roastList = []

  @observable
  selectedRoast = null

  @observable
  roast_validation = null

  @action
  handleImageUploader = () => {
    this.showUploader = !this.showUploader
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(value, id, history) {
    if (value) {
      this.isLoading = true
      this.imageFile = this.base64ToBlob(value, 'image/jpg')
      // this.imageFile = data
      const data = {
        target_id: id,
        image: this.imageFile,
      }
      let formData = new FormData()
      for (let key in data) formData.append(key, data[key])
      axios
        .post(`${servicePath}/product/media/add`, formData, {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        })
        .then((res) => res.data)
        .then(async (data) => {
          this.reset()
          this.getProductDetail(id, history)
        })
        .catch((err) => {
          console.log({ err })
          checkUnauthorized(err, history)
          if (err && err.response) {
            this.response = { msg: err.response.data.meta.msg, type: 'error' }
          }
          this.isLoading = false
          this.response = null
        })
    }
  }

  @action
  async validation(id, history) {
    if (this.productDetail.is_subscribe) {
      for (const item of this.productDetail.subscribeValues) {
        for (var key in item) {
          if ((key === 'weight' || key === 'price') && !item[key]) {
            this.response = {
              msg: 'Please fill all required field',
              type: 'error',
            }
            setTimeout(() => {
              this.response = null
            }, 1000)

            return
          }
        }
      }
    }
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.productDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.brand_validation = await checkInput({
      name: 'Brand',
      value: this.brand,
      require: true,
    })

    this.category_validation = await checkInput({
      name: 'Category',
      value: this.productDetail.category,
      require: true,
    })
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.productDetail.price,
      require: true,
      type: 'number',
      min: 1,
    })
    this.width_validation = await checkInput({
      name: 'Width',
      value: this.productDetail.dimension_x,
      require: true,
      type: 'number',
      min: 1,
    })
    this.height_validation = await checkInput({
      name: 'Height',
      value: this.productDetail.dimension_y,
      require: true,
      type: 'number',
      min: 1,
    })
    this.length_validation = await checkInput({
      name: 'Length',
      value: this.productDetail.dimension_z,
      require: true,
      type: 'number',
      min: 1,
    })
    this.weight_validation = await checkInput({
      name: 'Weigth',
      value: this.productDetail.weight,
      require: true,
      type: 'number',
      min: 1,
    })
    this.tag_validation = await checkInput({
      name: 'Tag',
      value: this.productDetail.tags.length,
      require: true,
    })

    this.sku_validation = await checkInput({
      name: 'SKU',
      value: this.productDetail.sku,
      require: true,
      min: 6,
    })

    this.brew_validation = await checkInput({
      name: 'Brewing Method',
      value: this.productDetail.brew_types.length,
      require: true,
    })

    this.roast_validation = await checkInput({
      name: 'Roast',
      value: this.productDetail.roast_types.length,
      require: true,
    })

    this.image_validation = await checkInput({
      name: 'Image',
      value: this.productDetail.images.length,
      require: true,
    })

    if (this.productDetail.is_subscribe) {
      this.en_name_validation.res &&
        this.brand_validation.res &&
        this.category_validation.res &&
        this.tag_validation.res &&
        this.brew_validation.res &&
        this.roast_validation.res &&
        this.image_validation.res &&
        this.handleUpdateProduct(id, history)
    } else {
      this.en_name_validation.res &&
        this.brand_validation.res &&
        this.category_validation.res &&
        this.price_validation.res &&
        // this.width_validation.res &&
        // this.height_validation.res &&
        // this.length_validation.res &&
        this.weight_validation.res &&
        this.tag_validation.res &&
        this.sku_validation.res &&
        this.brew_validation.res &&
        this.image_validation.res &&
        this.handleUpdateProduct(id, history)
    }
  }

  @action
  async getProductDetail(id, history) {
    this.isLoading = true
    this.imageFile = null
    const value = await axiFetch.get('product', null, id, history)
    if (value) {
      this.productDetail = value.data
      this.productList = value.data.similar
      this.discount = value.data.old_price ? true : false
      this.category = {
        value: value.data.category.id,
        label: value.data.category.name,
      }
      let roastTypes = []
      for (const item of this.productDetail.roast_types) {
        delete item.product_roast
        roastTypes.push(item)
      }

      this.productDetail.roast_types = roastTypes
      await this.getDependencies(history)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      all: true,
    }
    const value = await axiFetch.post('category', body, 'list', history)
    const brew = await axiFetch.post('brew', body, 'list', history)
    const roast = await axiFetch.post('roast', body, 'list', history)
    const tag = await axiFetch.post('tag', body, 'list', history)
    const brand = await axiFetch.post('brand', body, 'list', history)

    // const interval = await axiFetch.get(
    //   'subscribe',
    //   null,
    //   'intervalList',
    //   history
    // )

    if (value) {
      this.data = value.data
      await value.data.items.map((category) =>
        this.categoriesList.push({
          ...category,
        })
      )
      this.categoriesList = _.differenceWith(
        this.categoriesList,
        this.productDetail?.category,
        _.isEqual
      )
    }

    if (tag) {
      await tag.data.items.map((tag) =>
        this.tagsList.push({
          ...tag,
        })
      )
    }

    this.tagsList = _.differenceBy(
      this.tagsList,
      this.productDetail?.tags,
      'id'
    )

    if (brand) {
      await brand.data.items.map((brand) =>
        this.brandsList.push({
          ...brand,
        })
      )
      let targetBrand = brand.data.items.find(
        (item) => item.id === this.productDetail?.brand_id
      )
      this.brand = targetBrand
    }

    this.brandsList = _.differenceWith(this.brandsList, this.brand, _.isEqual)
    if (brew) {
      await brew.data.items.map((brew) =>
        this.brewsList.push({
          ...brew,
        })
      )
    }
    this.brewsList = _.differenceWith(
      this.brewsList,
      this.productDetail?.brew_types,
      _.isEqual
    )

    if (roast) {
      await roast.data.items.map((roast) =>
        this.roastList.push({
          ...roast,
        })
      )
    }

    this.roastList = await _.differenceWith(
      this.roastList,
      this.productDetail?.roast_types,
      _.isEqual
    )

    // if (interval) {
    //   for (var key in interval.data) {
    //     this.intervalList.push({ value: key, label: interval.data[key] })
    //   }
    // }

    if (this.productDetail.is_subscribe) {
      const subscribes = this.productDetail?.subscribeValues || []

      for (const row of subscribes) {
        for (const item of this.intervalList) {
          if (row.interval === item.value) {
            row.interval = item
          }
        }
      }
      this.productDetail.subscribeValues = subscribes
    } else {
      this.productDetail.subscribeValues = []
    }
  }

  @action
  async deleteProduct(id, history) {
    this.isLoading = true
    await axiFetch.del('product', null, id, 'delete', history)
    this.openDeleteModal = false
    history.push('/app/product')
  }

  @action
  handleUpdateProduct = async (id, history) => {
    this.isLoading = true
    const brewsId = []
    const tagsId = []
    const roastId = []
    const similar = []

    await this.productDetail.tags.map((item) => tagsId.push(item.id))

    await this.productDetail.brew_types.map((item) => brewsId.push(item.id))

    await this.productDetail.roast_types.map((item) => roastId.push(item.id))

    await this.productList.map((item) => similar.push(item.id))

    if (!this.orderImages) {
      this.orderImages = []
      await this.productDetail.images.map((item) =>
        this.orderImages.push(item.id)
      )
    }
    const data = {
      name_en: this.productDetail.name_en,
      name_ar: this.productDetail.name_ar,
      description_en: this.productDetail.description_en,
      description_ar: this.productDetail.description_ar,
      image: JSON.stringify(this.orderImages),
      category_id: this.productDetail.category.id,
      brand_id: this.brand.id,
      brew_types: JSON.stringify(brewsId),
      tags: JSON.stringify(tagsId),
      similar: JSON.stringify(similar),
      is_available: this.productDetail.is_available ? 1 : 0,
      is_subscribe: this.productDetail.is_subscribe ? 1 : 0,
    }

    if (this.productDetail.is_subscribe) {
      let values = [...this.productDetail.subscribeValues]
      for (let item of values) {
        if (!item.sku) {
          delete item.sku
        }
        // item.interval = item.interval.value
        delete item.old_price
        delete item.id
        !item.dimension_x && delete item.dimension_x
        !item.dimension_y && delete item.dimension_y
        !item.dimension_z && delete item.dimension_z
      }
      data.subscribeValues = JSON.stringify(values)
      data.roast_types = JSON.stringify(roastId)
    } else {
      data.price = this.productDetail.price
      this.productDetail.sku && (data.sku = this.productDetail.sku)
      data.weight = this.productDetail.weight
      this.productDetail.dimension_x &&
        (data.dimension_x = this.productDetail.dimension_x)
      this.productDetail.dimension_y &&
        (data.dimension_y = this.productDetail.dimension_y)
      this.productDetail.dimension_z &&
        (data.dimension_z = this.productDetail.dimension_z)
      if (this.discount) {
        data.old_price = this.productDetail.old_price
      }
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/product/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        await this.reset()
        this.getProductDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  getProduct = async (id, history) => {
    this.isLoadingProduct = true
    this.changeOpenProductModal()
    const body = {
      order: this.orderProduct,
      searchOn: this.searchOnProduct,
      search: this.searchProduct,
      limit: this.limitProduct,
      offset: this.offsetProduct,
      filter: this.filterProduct,
    }
    const value = await axiFetch.post('product', body, 'list', history)
    if (value) {
      this.products = await value.data.items.filter(
        (item) => item.id !== Number(id)
      )
      this.paginationProduct = Math.ceil(
        value.data.pagination.total / this.limitProduct
      )
      // parseInt(value.data.pagination.total / this.limitProduct) +
      // (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoadingProduct = false
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeOpenItemModal = () => {
    this.openItemModal = true
  }

  @action
  toggleItemModal = () => {
    this.openItemModal = false
  }

  @action
  changeEnName(value) {
    this.productDetail.name_en = value
  }

  @action
  changeDiscount(value) {
    this.discount = value
  }

  @action
  changeArName(value) {
    this.productDetail.name_ar = value
  }

  @action
  changeEnDescription(value) {
    this.productDetail.description_en = value
  }

  @action
  changeArDescription(value) {
    this.productDetail.description_ar = value
  }

  @action
  changePrice(value) {
    this.productDetail.price = value
  }

  @action
  changeOldPrice(value) {
    this.productDetail.old_price = value
  }

  @action
  changeWeight(value) {
    this.productDetail.weight = value
  }

  @action
  changeWidth(value) {
    this.productDetail.dimension_x = value
  }

  @action
  changeHeight(value) {
    this.productDetail.dimension_y = value
  }

  @action
  changeLength(value) {
    this.productDetail.dimension_z = value
  }

  @action
  changeSku(value) {
    this.productDetail.sku = value
  }

  @action
  changeSubscribeValue = (index, target, value) => {
    this.productDetail.subscribeValues[index][target] = value
  }
  @action
  changeSubscribeSwitch = () => {
    this.productDetail.is_subscribe = !this.productDetail.is_subscribe
  }

  @action
  addSubscribe = () => {
    this.productDetail.subscribeValues.push({ ...this.subscribeValue })
  }

  @action
  removeSubscribe = (order) => {
    let data = [...this.productDetail.subscribeValues]
    data.splice(order, 1)
    this.productDetail.subscribeValues = data
  }

  @action
  setItems(data, target) {
    switch (target) {
      case 'brand':
        this.setBrand(data)
        break
      case 'brew':
        this.setBrew(data)
        break
      case 'roast':
        this.setRoast(data)
        break
      case 'tag':
        this.setTag(data)
        break
      case 'category':
        this.setCategory(data)
        break
      default:
        break
    }
  }

  @action
  async setCategory(value) {
    const data = { ...value[0] }
    this.productDetail.category = data
    this.categoriesList = await _.differenceWith(
      this.categoriesList,
      this.productDetail.category,
      _.isEqual
    )
    this.toggleItemModal()
  }
  @action
  removeCategory(value) {
    this.productDetail.category = null
  }

  @action
  async setBrand(value) {
    const data = { ...value[0] }
    this.brand = data
    this.brandsList = await _.differenceWith(
      this.brandsList,
      this.brand,
      _.isEqual
    )
    this.toggleItemModal()
  }
  @action
  removeBrand(value) {
    this.brand = null
  }

  @action
  async setTag(value) {
    await this.productDetail.tags.push(...value)
    this.tagsList = await _.differenceWith(
      this.tagsList,
      this.productDetail.tags,
      _.isEqual
    )
    this.toggleItemModal()
    const intersection = _.intersectionBy(this.productDetail.tags, 'id')
    this.productDetail.tags = intersection
  }

  @action
  removeTag(value) {
    const data = []
    this.productDetail.tags.forEach((item) => {
      if (item.id !== value.id) {
        data.push(item)
      }
    })
    this.tagsList.push(value)
    const intersection = _.intersectionBy(this.tagsList, 'id')
    this.tagsList = intersection
    this.productDetail.tags = data
  }

  @action
  async setBrew(value) {
    await this.productDetail.brew_types.push(...value)
    this.brewsList = await _.differenceWith(
      this.brewsList,
      this.productDetail.brew_types,
      _.isEqual
    )
    this.toggleItemModal()
  }

  @action
  removeBrew(value) {
    const data = []
    this.productDetail.brew_types.forEach((item) => {
      if (item.id !== value.id) {
        data.push(item)
      }
    })
    this.brewsList.push(value)
    const intersection = _.intersectionBy(this.brewsList, 'id')
    this.brewsList = intersection
    this.productDetail.brew_types = data
  }

  @action
  async setRoast(value) {
    await this.productDetail.roast_types.push(...value)
    const intersection = _.intersectionBy(this.productDetail.roast_types, 'id')
    this.productDetail.roast_types = intersection

    this.roastList = await _.differenceWith(
      this.roastList,
      this.productDetail.roast_types,
      _.isEqual
    )
    this.toggleItemModal()
  }

  @action
  removeRoast(value) {
    const data = []
    this.productDetail.roast_types.forEach((item) => {
      if (item.id !== value.id) {
        data.push(item)
      }
    })
    this.roastList.push(value)
    const intersection = _.intersectionBy(this.roastList, 'id')
    this.roastList = intersection
    this.productDetail.roast_types = data
  }

  @action
  handleDeleteImage(id, imageId, history) {
    this.isLoading = true

    axios
      .get(`${servicePath}/product/media/${imageId}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        await this.reset()
        this.getProductDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = false
        this.response = null
      })
  }

  @action
  changeOrderImages(arr) {
    this.orderImages = arr
  }

  @action
  handleIsAvailable() {
    this.productDetail.is_available = this.productDetail.is_available ? 0 : 1
  }

  @action
  changeOpenProductModal = () => {
    this.openProductModal = true
  }

  @action
  toggleProductModal = () => {
    this.openProductModal = false
  }

  @action
  changePage(e, id, history) {
    this.offsetProduct = (e - 1) * 12
    this.currentPageProduct = e
    this.getProduct(id, history)
  }

  @action
  addItemToSelected = async (value) => {
    // await value.map(item => this.productList.push(item));

    // const intersection = _.intersectionBy(this.productList, "id");
    this.productList = [...value]
  }

  @action
  reset() {
    this.isLoading = true

    this.openItemModal = false

    this.arName = null

    this.enName = null

    this.price = null

    this.enDescription = null

    this.arDescription = null

    this.imageFile = null

    this.images = null

    this.category = null

    this.categoryId = null

    this.categoriesList = []

    this.brewsList = []

    this.brews = null

    this.tags = null

    this.tagsList = []

    this.brandsList = []

    this.brand = null

    this.data = null

    this.productDetail = null

    this.orderImages = null

    this.search = null

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.response = null

    this.openDeleteModal = false

    this.en_name_validation = null

    this.sku_validation = null

    this.tag_validation = null

    this.price_validation = null

    this.category_validation = null

    this.brand_validation = null

    this.height_validation = null

    this.length_validation = null

    this.width_validation = null

    this.weight_validation = null

    this.image_validation = null

    this.brew_validation = null

    this.searchProduct = null

    this.searchOnProduct = null

    this.limitProduct = 12

    this.offsetProduct = 0

    this.orderProduct = { id: -1 }

    this.filterProduct = {}

    this.discount = true

    this.currentPageProduct = 1

    this.isLoadingProduct = false

    this.openProductModal = false

    this.paginationProduct = null

    this.productList = []

    this.response = null

    this.showUploader = true

    this.subscribeValue = {
      weight: 0,
      price: 0,
      sku: '',
      dimension_x: '',
      dimension_y: '',
      dimension_z: '',
    }

    this.intervalList = []

    this.roastList = []

    this.selectedRoast = null

    this.roast_validation = null
  }
}
