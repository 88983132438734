
var color = 'light.purple';
if (localStorage.getItem('themeColor')) {
  color = localStorage.getItem('themeColor');
}

let render = () => {
   import('./assets/css/sass/themes/gogo.' + color + '.scss').then(() => {
    require('./App');
  });

};
render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

