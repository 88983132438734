import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Row, Card, CardBody, Button, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import IntlMessages from "../../../util/IntlMessages";
// import { Link } from "react-router-dom";

@inject("stores")
@observer
class CareersDetail extends Component {
  @observable
  store = this.props.stores.careers;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let path = this.props.match.params.id;
    this.setState({ id: path });
    this.store.getCareersDetail(path, this.props.history);
  }

  render() {
    return (
      <Fragment>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : this.store.careersDetail ? (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.careers" />}
                  match={this.props.match}
                />
                <a
                  href={`${this.store.careersDetail.file}`}
                  download
                  target="_blanck"
                  className={this.store.careersDetail.file? '' : 'd-none'}

                >
                  <Button
                    disabled={!this.store.careersDetail.file}
                    color={
                      this.store.careersDetail.file
                        ? "success"
                        : "btn-outline-secondary"
                    }
                    className="btn-shadow float-right ml-2"
                    size="lg"
                  >
                    <IntlMessages id="pages.downloadFile" />
                  </Button>
                </a>
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => window.print()}
                >
                  <IntlMessages id="pages.print" />
                </Button>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row></Row>

            <div className="col-12">
              <Colxx xxs="12">
                <div className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: "25px" }}
                  >
                    <div>
                      <Colxx
                        xxs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="6"
                        className="mb-8"
                      >
                        <Card className="mb-2">
                          <CardBody>
                            <CardTitle className="mb-3">
                              <span className="font-weight-bold text-uppercase">
                                {this.store.careersDetail.title}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />
                            <Colxx xxs="12" className="p-0 d-flex flex-wrap">
                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.personName" />
                                </p>
                                <p className="mb-3">
                                  {this.store.careersDetail.name}
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.phone" />
                                </p>
                                <p className="mb-3">
                                  {this.store.careersDetail.phone
                                    ? this.store.careersDetail.phone
                                    : "-"}
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.position" />
                                </p>
                                <p className="mb-3">
                                  {this.store.careersDetail.title
                                    ? this.store.careersDetail.title
                                    : "-"}
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.address" />
                                </p>
                                <p className="mb-3">
                                  {this.store.careersDetail.address
                                    ? this.store.careersDetail.address
                                    : "-"}
                                </p>
                              </Colxx>

                              <Colxx xxs="12" lg="12">
                                <p className="text-muted text-small mb-2">
                                  <IntlMessages id="pages.message" />
                                </p>
                                <p className="mb-3">
                                  {this.store.careersDetail.message
                                    ? this.store.careersDetail.message
                                    : "-"}
                                </p>
                              </Colxx>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </div>
                  </Colxx>
                </div>
              </Colxx>
            </div>
          </>
        ) : null}
      </Fragment>
    );
  }
}

export default CareersDetail;
