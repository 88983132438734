import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'

export default class RoastDetailStore {
  @observable
  roastDetail = null

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 10

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openDeleteModal = false

  @observable
  imageFile_validation = null

  @observable
  ar_name_validation = null

  @observable
  en_name_validation = null

  @observable
  response = null

  @action
  getRoastDetail = (id, history) => {
    this.isLoading = false
    this.imageFile = null
    axios
      .get(`${servicePath}/roast/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.roastDetail = data.data
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  deleteRoast = (id, history) => {
    this.isLoading = false
    axios
      .delete(`${servicePath}/roast/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        this.reset()
        history.push('/app/roast')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.openDeleteModal = false
        this.isLoading = true
        this.response = null
      })
  }

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })
    this.imageFile_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation &&
      this.handleUpdateRoast(id, history)
  }

  @action
  handleUpdateRoast = (id, history) => {
    this.isLoading = false
    let data = {
      name_en: this.roastDetail.name_en,
      name_ar: this.roastDetail.name_ar,
      description_en: this.roastDetail.description_en,
      description_ar: this.roastDetail.description_ar,
    }
    if (this.imageFile) {
      data.icon = this.imageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/roast/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(() => {
        this.reset()
        this.getRoastDetail(id)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action.bound
  changeEnName = (name) => {
    this.roastDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.roastDetail.name_ar = name
  }

  @action.bound
  changeEnDescription = (name) => {
    this.roastDetail.description_en = name
  }

  @action
  changeArDescription = (name) => {
    this.roastDetail.description_ar = name
  }

  @action
  reset() {
    this.roastDetail = null

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 10

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openDeleteModal = false

    this.imageFile_validation = null

    this.ar_name_validation = null

    this.en_name_validation = null
  }
}
