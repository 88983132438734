import { observable, action } from 'mobx'
import moment from 'moment'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'

export default class DashboardStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  event = []

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  calLoading = false

  @observable
  btnDisable = false

  @observable
  preparingOrders = null

  @observable
  sentOrders = null

  @observable
  canceledOrders = null

  @observable
  complatedOrders = null

  @observable
  totalSale = null

  @observable
  totalSaleItem = null

  @observable
  recentOrders = null

  @observable
  topFilling = null

  @observable
  topFlavor = null

  @observable
  topOuterShell = null

  @observable
  topBakeries = null

  @observable
  topSellers = null

  @observable
  months = moment.months()

  @observable
  years = null

  @observable
  target = 'day'

  @observable
  targetMonth = moment().format('MMMM')

  @observable
  targetYear = moment().format('YYYY')

  @observable
  labels = []

  @observable
  sale = []

  @action
  async getTodayData(history) {
    this.isLoading = true
    this.event = []
    axios
      .get(`${servicePath}/dashboard`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        let date = data.data.date.split('-')

        this.sale = []
        this.labels = []
        this.topSellers = data.data.top_selling_orders
        this.complatedOrders = data.data.delivered_orders
        this.canceledOrders = data.data.cancelled_orders
        this.preparingOrders = data.data.preparing
        this.sentOrders = data.data.sent_orders
        this.totalSaleItem =
          data.data.delivered_orders +
          data.data.preparing +
          data.data.cancelled_orders +
          data.data.sent_orders
        this.totalSale = data.data.totalDailySale
        this.years = data.data.available_years
        this.recentOrders = data.data.recent_orders
        await data.data.orders_in_month.forEach((item) => {
          this.labels.push(`${data.data.date}-${item.day}`)
          this.sale.push(item.totalSale)
          this.event.push({
            key: `${item.day}`,
            title: item.totalSale ? `Total Sale : ${item.totalSale} KD` : null,
            start: `${date[1]} ${item.day} ${date[0]}`,
            end: `${date[1]} ${item.day} ${date[0]}`,
          })
        })
        this.isLoading = false
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = false
        if (err) {
          this.response = {
            msg: err.response && err.response.data.meta.msg,
            type: 'error',
          }
        }
        this.response = null
      })
  }

  @action
  async getAllTimeData(history) {
    this.isLoading = true

    axios
      .get(`${servicePath}/dashboard?alltime=true`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.sale = []
        this.labels = []
        this.topSellers = data.data.top_selling_orders
        this.complatedOrders = data.data.delivered_orders
        this.canceledOrders = data.data.cancelled_orders
        this.preparingOrders = data.data.preparing
        this.sentOrders = data.data.sent_orders
        this.totalSaleItem =
          data.data.delivered_orders +
          data.data.preparing +
          data.data.cancelled_orders +
          data.data.sent_orders
        this.totalSale = data.data.totalDailySale
        this.years = data.data.available_years
        await data.data.orders_in_month.forEach((item) => {
          this.labels.push(`${data.data.date}-${item.day}`)
          this.sale.push(item.totalSale)
        })

        this.isLoading = false
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = false
        if (err) {
          this.response = {
            msg: err.response && err.response.data.meta.msg,
            type: 'error',
          }
        }
        this.response = null
      })

    //   await value.data.orders_in_month.map(item => {
    //     this.labels.push(`${value.data.date}-${item.day}`);
    //     this.sale.push(item.totalSale);
    //   });
  }

  @action
  async getMonthData(history) {
    this.isLoading = true
    const data = {
      month: moment().month(this.targetMonth).format('M'),
      year: this.targetYear,
    }

    axios
      .post(`${servicePath}/dashboard/monthly_orders`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.sale = []
        this.labels = []
        this.topSellers = data.data.top_selling_orders
        this.complatedOrders = data.data.delivered_orders
        this.canceledOrders = data.data.cancelled_orders
        this.preparingOrders = data.data.preparing
        this.sentOrders = data.data.sent_orders
        this.totalSaleItem =
          data.data.delivered_orders +
          data.data.preparing +
          data.data.cancelled_orders +
          data.data.sent_orders
        this.totalSale = data.data.totalSale
        // this.years = data.data.available_years
        await data.data.orders_in_month.forEach((item) => {
          this.labels.push(`${data.data.date}-${item.day}`)
          this.sale.push(item.totalSale)
        })

        this.isLoading = false
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = false
        if (err) {
          this.response = {
            msg: err.response && err.response.data.meta.msg,
            type: 'error',
          }
        }
        this.response = null
      })

    //   await value.data.orders_in_month.map(item => {
    //     this.labels.push(`${value.data.date}-${item.day}`);
    //     this.sale.push(item.totalSale);
    //   });
  }

  @action
  async getYearData(history) {
    this.isLoading = true
    const data = {
      year: this.targetYear,
    }
    axios
      .post(`${servicePath}/dashboard/year_orders`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.sale = []
        this.labels = []
        this.topSellers = data.data.top_selling_orders
        this.complatedOrders = data.data.delivered_orders
        this.canceledOrders = data.data.cancelled_orders
        this.preparingOrders = data.data.preparing
        this.sentOrders = data.data.sent_orders
        this.totalSaleItem =
          data.data.delivered_orders +
          data.data.preparing +
          data.data.cancelled_orders +
          data.data.sent_orders
        this.totalSale = data.data?.year_data?.sumData?.totalSale || 0
        // this.years = data.data.available_years
        await data.data.year_data.data.forEach((item) => {
          this.labels.push(`${item.monthName}`)
          this.sale.push(item.totalSale)
        })

        this.isLoading = false
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = false
        if (err) {
          this.response = {
            msg: err.response && err.response.data.meta.msg,
            type: 'error',
          }
        }
        this.response = null
      })

    //   await value.data.year_data.data.map(item => {
    //     this.labels.push(`${item.monthName}`);
    //     this.sale.push(item.totalSale);
    //   });
  }

  @action
  getMonthDataCal(m, y, history) {
    this.calLoading = true
    const data = {
      month: m,
      year: y,
    }

    axios
      .post(`${servicePath}/dashboard/monthly_orders`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.event = []
        let date = data.data.date.split('-')
        await data.data.orders_in_month.forEach((item) => {
          this.event.push({
            key: `${item.day}`,
            title: item.totalSale ? `Total Sale : ${item.totalSale} KD` : null,
            start: `${date[1]} ${item.day} ${date[0]}`,
            end: `${date[1]} ${item.day} ${date[0]}`,
          })
        })
        this.calLoading = false
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err) {
          this.response = {
            msg: err.response && err.response.data.meta.msg,
            type: 'error',
          }
        }
        this.response = null
      })
  }

  @action
  handleFilter(history) {
    switch (this.target) {
      case 'allTime':
        this.getAllTimeData(history)
        break
      case 'month':
        this.getMonthData(history)
        break
      case 'year':
        this.getYearData(history)
        break

      case 'day':
        this.getTodayData(history)
        break

      default:
        break
    }
  }

  @action
  handleTarget(value) {
    this.target = value
  }

  @action
  handleMonth(value) {
    this.targetMonth = value
  }

  @action
  handleYear(value) {
    this.targetYear = value
  }
}
