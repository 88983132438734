import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { ToastContainer, toast } from "react-toastify";
import textLogo from "../../assets/fonts/altitude-logo-text.svg";
import logo from "../../assets/fonts/altitude-logo.svg";
import LoadingWhite from "../../components/Loading/loadingWhite";

import { Colxx } from "../../components/CustomBootstrap/index";
import IntlMessages from "../../util/IntlMessages";

@inject("stores")
@observer
class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
  }
  @observable
  store = this.props.stores.login;

  onUserLogin() {
    if (this.state.email !== "" && this.state.password !== "") {
      this.store.loginUser(this.state, this.props.history);
    }
  }
  componentDidMount() {
    document.body.classList.add("background");
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }

  _handleEmail = event => {
    this.setState({ email: event.target.value });
  };

  _handlePassword = event => {
    this.setState({ password: event.target.value });
  };

  notify = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      default:
        break;
    }
  };

  render() {
    if (!this.store.loginLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type);
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />

        <div className="fixed-background" />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="8" lg="6" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="form-side">
                    <NavLink
                      to={`/`}
                      className="white d-flex justify-content-center align-items-center"
                    >
                      <img
                        className="login-imgLogo d-xl-none d-md-none d-block"
                        src={logo}
                        alt="nizek shop dark mobile logo "
                      />
                      <img
                        className="login-logo d-xl-block d-md-block d-none"
                        src={textLogo}
                        alt="nizek shop dark mobile logo"
                      />
                    </NavLink>
                    <CardTitle className="mb-4">
                      <IntlMessages id="user.login-title" />
                    </CardTitle>
                    <Form>
                      <Label className="form-group has-float-label mb-4">
                        <Input
                          type="email"
                          value={this.state.email}
                          onChange={e => this._handleEmail(e)}
                        />
                        <IntlMessages id="user.email" />
                      </Label>
                      <Label className="form-group has-float-label mb-4">
                        <Input
                          type="password"
                          value={this.state.password}
                          onChange={e => this._handlePassword(e)}
                        />
                        <IntlMessages
                          id="user.password"
                          value={this.state.password}
                        />
                      </Label>
                      <div className="d-flex justify-content-between align-items-center">
                        <NavLink to={`/forgot-password`}>
                          <IntlMessages id="user.forgot-password-question" />
                        </NavLink>
                        <Button
                          color="primary"
                          className="btn-shadow"
                          size="lg"
                          onClick={() => this.onUserLogin()}
                          style={{ maxHeight: 43, width: 128.33 }}
                          disabled={this.store.btnDisable}
                        >
                          {this.store.btnDisable ? (
                            <LoadingWhite />
                          ) : (
                            <IntlMessages id="user.login-button" />
                          )}{" "}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default LoginLayout;
