import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import axiFetch from '../config/fetch'

export default class UserStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  name = null

  @observable
  password = null

  @observable
  email = null

  @observable
  btnDisable = false

  @observable
  response = null

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.post('user', body, 'list', history)
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    }
  }

  @action
  handleSaveNewUser = (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
      image: this.imageFile,
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/user`, formData, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        history.push(`/app/user/detail/${data.data.id}`)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.btnDisable = false
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeName = (value) => {
    this.name = value
  }

  @action
  changePassword = (value) => {
    this.password = value
  }

  @action
  changeEmail = (value) => {
    this.email = value
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.name = null

    this.password = null

    this.email = null

    this.btnDisable = false
  }
}
