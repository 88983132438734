import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Card,
  CardBody,
  Col,
  CardText,
  CardImg,
  ButtonGroup,
  Button,
} from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import { ToastContainer, toast } from 'react-toastify'
import { NavLink } from 'react-router-dom'

@inject('stores')
@observer
class OrderDetail extends Component {
  @observable
  store = this.props.stores.order
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getOrderDetail(path, this.props.history)
  }
  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        <Row>
          <Colxx xxs="12" className="d-flex align-items-center">
            <Colxx xxs="8">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.order" />}
                match={this.props.match}
              />
            </Colxx>
            <Colxx xxs="4" className="float-right">
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => window.print()}
              >
                <IntlMessages id="pages.print" />
              </Button>
            </Colxx>
          </Colxx>
          <Colxx xxs="12">
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row></Row>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : this.store.orderDetail ? (
          <>
            <div className="col-12 div-print">
              <Colxx xxs="12">
                <Card>
                  <CardBody>
                    <Colxx xxs="12">
                      <div
                        className="d-flex flex-wrap  p-3 h-100"
                        style={{ border: '1px solid gray' }}
                      >
                        <Col className="col-xl-6 col-md-12 col-sm-12 h-100 d-xl-none d-md-block d-block">
                          <Card
                            className="col-12 p-3 h-100 "
                            style={{
                              backgroundColor: 'transparent',
                              boxShadow: 'unset',
                            }}
                          >
                            <ButtonGroup className="d-flex justify-content-center btn-print">
                              <Button
                                onClick={() =>
                                  this.store.changeStatus(
                                    'preparing',
                                    this.state.id
                                  )
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'preparing'
                                    ? 'light'
                                    : 'danger'
                                }
                              >
                                <IntlMessages id="pages.preparing" />
                              </Button>
                              <Button
                                onClick={() =>
                                  this.store.changeStatus('sent', this.state.id)
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'sent'
                                    ? 'light'
                                    : 'danger'
                                }
                              >
                                <IntlMessages id="pages.inTransit" />
                              </Button>
                              <Button
                                onClick={() =>
                                  this.store.changeStatus(
                                    'delivered',
                                    this.state.id
                                  )
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'delivered'
                                    ? 'light'
                                    : 'danger'
                                }
                              >
                                <IntlMessages id="pages.completed" />
                              </Button>
                              <Button
                                onClick={() =>
                                  this.store.changeStatus(
                                    'cancelled',
                                    this.state.id
                                  )
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'cancelled'
                                    ? 'light'
                                    : 'danger'
                                }
                                disabled={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'delivered'
                                }
                              >
                                <IntlMessages id="pages.cancelled" />
                              </Button>
                            </ButtonGroup>
                          </Card>
                        </Col>
                        <Col className="p-2 col-xl-3 col-md-6 col-sm-12 col-12 h-100">
                          <Card
                            className="col-12  h-100"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <CardBody className="p-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Order Date :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.summary.created_at.split(
                                    ' '
                                  )[0]
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Id :{' '}
                                </span>
                                {this.store.orderDetail.summary.code}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Total Items :{' '}
                                </span>{' '}
                                {this.store.orderDetail.summary.total_items}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Customer :{' '}
                                </span>{' '}
                                {this.store.orderDetail.summary.customer.name}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Phone Number :{' '}
                                </span>{' '}
                                {this.store.orderDetail?.summary?.customer?.phonenumber}
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-xl-6 col-md-6 col-sm-12 h-100 d-xl-block d-md-none d-none">
                          <Card
                            className="col-12 p-3 h-100 "
                            style={{
                              backgroundColor: 'transparent',
                              boxShadow: 'unset',
                            }}
                          >
                            <ButtonGroup className="d-flex justify-content-center">
                              <Button
                                onClick={() =>
                                  this.store.changeStatus(
                                    'preparing',
                                    this.state.id
                                  )
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'preparing'
                                    ? 'light'
                                    : 'danger'
                                }
                              >
                                <IntlMessages id="pages.preparing" />
                              </Button>
                              <Button
                                onClick={() =>
                                  this.store.changeStatus('sent', this.state.id)
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'sent'
                                    ? 'light'
                                    : 'danger'
                                }
                              >
                                <IntlMessages id="pages.inTransit" />
                              </Button>
                              <Button
                                onClick={() =>
                                  this.store.changeStatus(
                                    'delivered',
                                    this.state.id
                                  )
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'delivered'
                                    ? 'light'
                                    : 'danger'
                                }
                              >
                                <IntlMessages id="pages.completed" />
                              </Button>
                              <Button
                                onClick={() =>
                                  this.store.changeStatus(
                                    'cancelled',
                                    this.state.id
                                  )
                                }
                                color={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'cancelled'
                                    ? 'light'
                                    : 'danger'
                                }
                                disabled={
                                  this.store.orderDetail.summary
                                    .delivery_status === 'delivered'
                                }
                              >
                                <IntlMessages id="pages.cancelled" />
                              </Button>
                            </ButtonGroup>
                          </Card>
                        </Col>
                        <Col className="p-2 col-xl-3 col-md-6 col-sm-12 col-12 h-100">
                          <Card
                            className="col-12  h-100"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <CardBody className="p-3">
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Delivery Date :{' '}
                                </span>{' '}
                                {
                                  this.store.orderDetail.summary.delivery_date.split(
                                    'on'
                                  )[1]
                                }
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Delivery Time :{' '}
                                </span>{' '}
                                {`
                                  ${
                                    this.store.orderDetail.summary.delivery_date.split(
                                      ' '
                                    )[0]
                                  }  -  ${
                                  this.store.orderDetail.summary.delivery_date.split(
                                    ' '
                                  )[2]
                                }`}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Payment Method :{' '}
                                </span>
                                {this.store.orderDetail.summary.payment_type}
                              </CardText>

                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Total Price :{' '}
                                </span>{' '}
                                {`${this.store.orderDetail.summary.total_price} ${this.store.orderDetail.summary.currency}`}
                              </CardText>
                              <CardText className="text-small text-muted">
                                <span className="font-weight-bold text-black ">
                                  Address :{' '}
                                </span>{' '}
                                {`
                                ${
                                  this.store.orderDetail.address?.area
                                    ?.name_en &&
                                  this.store.orderDetail.address.area.name_en +
                                    ', '
                                }
                                ${
                                  this.store.orderDetail.address?.block &&
                                  'block ' +
                                    this.store.orderDetail.address.block +
                                    ', '
                                }
                                ${
                                  this.store.orderDetail.address?.street &&
                                  'street ' +
                                    this.store.orderDetail.address.street +
                                    ', '
                                } ${
                                  this.store.orderDetail.address?.avenue &&
                                  'avenue ' +
                                    this.store.orderDetail.address.avenue +
                                    ', '
                                }
                                ${
                                  this.store.orderDetail.address?.building &&
                                  'building ' +
                                    this.store.orderDetail.address.building +
                                    ', '
                                }
                                ${
                                  this.store.orderDetail.address?.floor &&
                                  'floor ' +
                                    this.store.orderDetail.address.floor +
                                    ', '
                                }
                                ${this.store.orderDetail.address?.other}   `}
                              </CardText>
                              {/* other, floor, building, block, street, avenue, area ??? */}
                            </CardBody>
                          </Card>
                        </Col>
                      </div>
                      <div
                        className="d-flex align-items-center p-3 flex-wrap"
                        style={{ border: '1px solid gray' }}
                      >
                        <Colxx
                          xxs="12"
                          className="p-0 d-xl-block d-md-none d-none"
                        >
                          <div
                            className="col-12 p-2 d-flex align-items-center mb-3"
                            style={{ borderBottom: '1px solid gray' }}
                          >
                            <div className="col-1">
                              <p className="col-12 p-2 m-0 text-muted text-small text-center">
                                Row
                              </p>
                            </div>
                            <div className="col-1">
                              <p className="col-12 p-2 m-0 text-muted text-small text-center">
                                Image
                              </p>
                            </div>

                            <div className="col-10 d-flex align-items-center">
                              <p className="col-2 p-2 m-0 text-muted text-small text-center">
                                Prudoct Name
                              </p>
                              <p className="col-2 p-2 m-0 text-muted text-small text-center">
                                Weight
                              </p>
                              <p className="col-2 p-2 m-0 text-muted text-small text-center">
                                Count
                              </p>
                              <p className="col-2 p-2 m-0 text-muted text-small text-center">
                                Price (KD)
                              </p>
                              <p className="col-2 p-2 m-0 text-muted text-small text-center">
                                Total Price (KD)
                              </p>
                            </div>
                          </div>
                        </Colxx>
                        {this.store.orderDetail.cart.map((item, index) => (
                          <Colxx xxs="12" className="pl-0 pr-0" key={index}>
                            <div className="p-0">
                              <Card className="col-12 pl-0 pr-0 mb-3">
                                <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                                  <div className="position-relative col-xl-1 d-none d-md-none d-xl-inline col-md-4 col-sm-12">
                                    <CardText className="col-xl-12 col-md-12   p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Row :{' '}
                                      </span>
                                      {index + 1}
                                    </CardText>
                                  </div>
                                  <div className="position-relative col-xl-1 col-md-4 col-sm-12">
                                    <div
                                      className="col-12 position-relative"
                                      style={{ paddingTop: '100%' }}
                                    >
                                      <CardImg
                                        src={item.detail.images[0].url}
                                        className=" p-2"
                                        style={{}}
                                      ></CardImg>
                                    </div>
                                  </div>
                                  <div className="col-xl-10 col-md-8 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap">
                                    <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Product name :{' '}
                                      </span>
                                      <NavLink
                                        to={`/app/product/detail/${item.detail.id}`}
                                        className="text-info"
                                      >
                                        {item.detail.name}
                                      </NavLink>
                                    </CardText>
                                    <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Weight :{' '}
                                      </span>
                                      {item.detail.weight}
                                    </CardText>
                                    <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Count :{' '}
                                      </span>
                                      {item.quantity}
                                    </CardText>
                                    <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Price (KD):{' '}
                                      </span>
                                      {item.detail.price}
                                    </CardText>
                                    <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                      <span className="d-xl-none d-md-inline font-weight-bold">
                                        Total Price (KD):{' '}
                                      </span>
                                      {item.price}
                                    </CardText>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </Colxx>
                        ))}
                      </div>
                      <div
                        className="d-flex align-items-center p-3 flex-wrap"
                        style={{ border: '1px solid gray' }}
                      >
                        <Colxx xxs="12" className="p-0 ">
                          <div>
                            <Card className="col-12 pl-0 pr-0 ">
                              <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                                <div
                                  className="col-12 d-flex align-items-center flex-wrap p-2"
                                  style={{ borderBottom: '1px solid gray' }}
                                >
                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    Sub Total :{' '}
                                    {`${this.store.orderDetail.subtotal} KD`}
                                  </CardText>
                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    Coupon :{' '}
                                    {`${
                                      this.store.orderDetail.coupon
                                        ? this.store.orderDetail.coupon
                                        : 0
                                    } KD`}
                                  </CardText>

                                  <CardText className="col-xl-4 col-md-12 text-small text-muted m-0 p-2 text-center">
                                    Shipping :{' '}
                                    {`${this.store.orderDetail.delivery} KD`}
                                  </CardText>
                                </div>
                                <CardText className="col-12 text-one font-weight-bold text-center text-muted m-0 p-3">
                                  Total :{' '}
                                  {`${this.store.orderDetail.total_price.value} ${this.store.orderDetail.summary.currency}`}
                                </CardText>
                              </CardBody>
                            </Card>
                          </div>
                        </Colxx>
                      </div>
                    </Colxx>
                  </CardBody>
                </Card>
              </Colxx>
            </div>
          </>
        ) : null}
      </Fragment>
    )
  }
}

export default OrderDetail
