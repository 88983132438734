import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import moment from 'moment'
export default class EventDetailStore {
  @observable
  postDetail = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  ar_name_validation = null

  @observable
  en_name_validation = null

  @observable
  btnDisable = false

  @observable
  enName = ''

  @observable
  arName = ''

  @observable
  content_en = ''

  @observable
  content_ar = ''

  @observable
  coverImageFile = null

  @observable
  thumbImageFile = null

  @observable
  tags = []

  @observable
  tagsList = []

  @observable
  area = null

  @observable
  areasList = []

  @observable
  enSummary = ''

  @observable
  arSummary = ''

  @observable
  openDeleteModal = false

  @observable
  startDate = new Date()

  @observable
  coverImage_validation = null

  @observable
  content_validation = null

  @observable
  thumbImage_validation = null

  @observable
  en_name_validation = null

  @observable
  tag_validation = null

  @observable
  summary_validation = null

  @observable
  date_validation = null

  @observable
  area_validation = null

  @observable
  response = null

  @observable
  adminsList = []

  @observable
  admin = null

  @observable
  showUploaderEnglish = true

  @observable
  showUploaderArabic = true

  @action
  handleImageUploaderEnglish = () => {
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  handleImageUploaderArabic = () => {
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  handleChangeDate = (date) => {
    this.startDate = date
  }

  @action
  handleChangeEnContent = (data) => {
    this.content_en = data
  }

  @action
  handleChangeArContent = (data) => {
    this.content_ar = data
  }

  @action
  changeCoverImageFile =async (file) => {
    this.coverImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  changeThumbImageFile =async (file) => {
    this.thumbImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  changeEnName = (value) => {
    this.postDetail.title_en = value
  }

  @action
  changeArName = (value) => {
    this.postDetail.title_ar = value
  }

  @action
  setTag(value) {
    this.tags = value.selectedOption
  }

  @action
  setAuthor(value) {
    this.admin = value.selectedOption
  }

  @action
  setArea(value) {
    this.area = value.selectedOption
  }

  @action
  changeArSummary = (value) => {
    this.postDetail.summary_ar = value
  }

  @action
  changeEnSummary = (value) => {
    this.postDetail.summary_en = value
  }

  @action
  handleIsHot = () => {
    this.postDetail.is_hot = this.postDetail.is_hot ? 0 : 1
  }

  @action
  async getDependencies(history) {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      all: true,
    }
    // const value=  await axiFetch.post("category", body, "list", history);
    const area = await axiFetch.get('area', history, 'all')
    const tag = await axiFetch.post('tag', body, 'list', history)

    if (tag) {
      await tag.data.items.map((tag) =>
        this.tagsList.push({
          value: tag.id,
          label: tag.name_en,
        })
      )
    }

    if (area) {
      await area.data.items.map((area) =>
        this.areasList.push({
          value: area.id,
          label: area.name_en,
        })
      )

      let targetArea = await area.data.items.find(
        (item) => item.id === this.postDetail.area_id
      )
      this.area = { value: targetArea.id, label: targetArea.name_en }
    }

    this.isLoading = true
  }

  @action
  getPostDetail = async (id, history) => {
    this.isLoading = false
    this.coverImageFile = null
    this.thumbImageFile = null
    axios
      .get(`${servicePath}/blog/${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.postDetail = data.data
        this.startDate = Date.parse(data.data.target_date)
        if (data.data.author) {
          this.admin = {
            value: data.data.author.id,
            label: data.data.author.name,
          }
        }
        if (data.data.tags) {
          await data.data.tags.map((tag) =>
            this.tags.push({
              value: tag.id,
              label: tag.name_en,
            })
          )
        }
        await this.getAdmins(history)

        await this.getDependencies(history)

        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  getAdmins = (history) => {
    const body = {}
    axios
      .post(`${servicePath}/admin/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        const admins = []
        await data.data.forEach((admin) => {
          admins.push({ value: admin.id, label: admin.name })
        })

        this.adminsList = admins
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        this.isLoading = true
      })
  }

  @action
  deletePost = (id, history) => {
    this.isLoading = false
    axios
      .get(`${servicePath}/blog/${id}/delete`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openDeleteModal = false
        this.reset()
        history.push('/app/event')
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
      })
  }

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Title',
      value: this.enName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.tag_validation = await checkInput({
      name: 'Tag',
      value: this.tags,
      require: true,
    })

    this.summary_validation = await checkInput({
      name: 'English Summary',
      value: this.enSummary,
      require: true,
      type: 'string',
      min: 3,
    })

    this.content_validation = await checkInput({
      name: 'English Content',
      value: this.content_en,
      require: true,
      min: 3,
    })
    this.coverImage_validation = await checkInput({
      name: 'Cover Image',
      value: this.coverImageFile,
      require: true,
    })

    this.thumbImage_validation = await checkInput({
      name: 'Thumb Image',
      value: this.thumbImageFile,
      require: true,
    })

    this.date_validation = await checkInput({
      name: 'Date',
      value: this.startDate,
      require: true,
    })

    this.area_validation = await checkInput({
      name: 'Area',
      value: this.area,
      require: true,
    })
    this.coverImage_validation.res &&
      this.thumbImage_validation.res &&
      this.tag_validation.res &&
      this.en_name_validation.res &&
      this.summary_validation.res &&
      this.content_validation.res &&
      this.area_validation.res &&
      this.date_validation.res &&
      this.handleUpdatePost(id, history)
  }

  @action
  handleUpdatePost = (id, history) => {
    this.isLoading = false
    this.btnDisable = true

    const tagsId = []

    this.tags.forEach((item) => {
      tagsId.push(item.value)
    })
    let data = {
      title_en: this.postDetail.title_en,
      title_ar: this.postDetail.title_ar,
      summary_en: this.postDetail.summary_en,
      summary_ar: this.postDetail.summary_ar,
      tags: JSON.stringify(tagsId),
      area_id: this.area.value,
      target_date: moment(this.startDate).format('YYYY-MM-DD hh:mm'),
      content_en: this.content_en
        ? this.content_en
        : this.postDetail.content_en,
      content_ar: this.content_ar
        ? this.content_ar
        : this.postDetail.content_ar,
      is_hot: this.postDetail.is_hot,
    }
    if(this.admin){
      data.author_id=this.admin.value
    }

    if (this.thumbImageFile) {
      data.thumb = this.thumbImageFile
    }

    if (this.coverImageFile) {
      data.cover = this.coverImageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    axios
      .post(`${servicePath}/blog/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.btnDisable = false
        this.reset()
        this.getPostDetail(id, history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
        if (err && err.response) {
          this.response = { msg: err.response.data.meta.msg, type: 'error' }
        }
        this.isLoading = true
        this.response = null
        this.btnDisable = false
      })
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
    this.postDetail = null

    this.pagination = 0

    this.currentPage = 1

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.ar_name_validation = null

    this.en_name_validation = null

    this.btnDisable = false

    this.enName = ''

    this.arName = ''

    this.content_en = ''

    this.content_ar = ''

    this.coverImageFile = null

    this.thumbImageFile = null

    this.tags = []

    this.tagsList = []

    this.area = null

    this.areasList = []

    this.enSummary = ''

    this.arSummary = ''

    this.openDeleteModal = false

    this.startDate = new Date()

    this.coverImage_validation = null

    this.content_validation = null

    this.thumbImage_validation = null

    this.en_name_validation = null

    this.tag_validation = null

    this.summary_validation = null

    this.date_validation = null

    this.area_validation = null

    this.adminsList = []

    this.admin = null

    this.showUploaderEnglish = true

    this.showUploaderArabic = true
  }
}
