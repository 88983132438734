import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { Thumb } from '../../../components/Thumb'
import DeleteModal from '../../../components/Modal/deleteModal'
import { ToastContainer, toast } from 'react-toastify'

@inject('stores')
@observer
class UserDetail extends Component {
  @observable
  store = this.props.stores.userDetail
  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getUserDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.user" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={
                  this.store.userDetail ? this.store.userDetail.name : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteUser(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() => this.store.validation(this.state.id)}
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx xxs="12">
                  <Colxx xxs="12">
                    <Row className="d-flex justify-content-center">
                      <Colxx
                        xxs="12"
                        lg="12"
                        className="mb-6 border-bottom"
                        style={{ marginBottom: '25px' }}
                      >
                        <Row>
                          <Colxx
                            xxs="12"
                            sm="12"
                            md="6"
                            lg="4"
                            className="mb-4"
                          >
                            <Card className="mb-2 ">
                              <CardBody>
                                <CardTitle className="mb-3">
                                  <span className="font-weight-bold text-uppercase">
                                    {`image`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                <Button
                                  color="primary"
                                  size="lg"
                                  className="pointer mb-3 col-12"
                                  style={{}}
                                >
                                  <Label
                                    className="btn btn-default no-padding col-12"
                                    style={{
                                      padding: '0px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    <strong>Change Image</strong>
                                    <input
                                      className="col-12 p-0 "
                                      id="image"
                                      name="image"
                                      type="file"
                                      accept="image/x-png,image/jpeg"
                                      onChange={(event) => {
                                        this.store.changeImageFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                      hidden
                                    />
                                  </Label>
                                </Button>
                                <Thumb
                                  key="cat-image"
                                  className="almost-full-width"
                                  file={this.store.imageFile}
                                  src={
                                    this.store.userDetail
                                      ? this.store.userDetail.image
                                        ? this.store.userDetail.image.url
                                        : '/assets/img/noimage.png'
                                      : '/assets/img/noimage.png'
                                  }
                                />
                              </CardBody>
                            </Card>
                          </Colxx>
                          <Colxx
                            xxs="12"
                            sm="12"
                            md="6"
                            lg="4"
                            className="mb-8"
                          >
                            <Card className="mb-2">
                              <CardBody>
                                <CardTitle className="mb-3">
                                  <span className="font-weight-bold text-uppercase">
                                    {`Detail`}
                                  </span>
                                </CardTitle>
                                <Separator className="mb-5" />
                                <Colxx
                                  xxs="12"
                                  className="p-0 d-flex flex-wrap"
                                >
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.email" />
                                    </p>
                                    <p className="mb-3">
                                      {this.store.userDetail
                                        ? this.store.userDetail.email
                                        : ''}
                                    </p>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.personName" />
                                      <span className="req"> *</span>
                                    </p>
                                    <p className="mb-3">
                                      <Input
                                        name="name"
                                        value={
                                          this.store.userDetail
                                            ? this.store.userDetail.name
                                            : ''
                                        }
                                        type="text"
                                        onChange={(e) =>
                                          this.store.changeEnName(
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="req-validation ">
                                        {' '}
                                        {this.store.name_validation
                                          ? this.store.name_validation.msg
                                            ? this.store.name_validation.msg
                                            : null
                                          : null}
                                      </span>
                                    </p>
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.about" />
                                      {/* <span className="req"> *</span> */}
                                    </p>
                                    <p className="mb-3">
                                      <Input
                                        name="name"
                                        style={{
                                          height: 150,
                                          borderRadius: '0.75rem',
                                        }}
                                        value={
                                          this.store.userDetail
                                            ? this.store.userDetail.about_en
                                            : ''
                                        }
                                        type="textarea"
                                        onChange={(e) =>
                                          this.store.changeEnAbout(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </p>
                                    {/* <span className="req-validation ">
                                      {" "}
                                      {this.store.about_en_validation
                                        ? this.store.about_en_validation.msg
                                          ? this.store.about_en_validation.msg
                                          : null
                                        : null}
                                    </span> */}
                                  </Colxx>
                                  <Colxx xxs="12" lg="12">
                                    <p className="text-muted text-small mb-2">
                                      <IntlMessages id="pages.about-ar" />
                                    </p>
                                    <p className="mb-3">
                                      {/* {this.store.userDetail
                                        ? this.store.userDetail.about_ar
                                        : ""} */}
                                      <Input
                                        name="name"
                                        className="ar"
                                        style={{
                                          height: 150,
                                          borderRadius: '0.75rem',
                                        }}
                                        value={
                                          this.store.userDetail
                                            ? this.store.userDetail.about_ar
                                            : ''
                                        }
                                        type="textarea"
                                        onChange={(e) =>
                                          this.store.changeArAbout(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </p>
                                  </Colxx>
                                </Colxx>
                              </CardBody>
                            </Card>
                          </Colxx>
                        </Row>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default UserDetail
