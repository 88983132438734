import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardText,
  CardImg
} from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import DeleteModal from "../../../components/Modal/deleteModal";
import ImageGalleryCard from "../../../components/imageGalleryCard";
import { FormikSwitch } from "../../../components/FormValidations/FormikFields";
import ItemModal from "../../../components/Modal/ItemModal";
import CollectionModal from "../../../components/Modal/collectionModal";

@inject("stores")
@observer
class ProductDetail extends Component {
  @observable
  store = this.props.stores.productDetail;
  constructor(props) {
    super(props);
    this.state = { id: null, imageFile: null, available: true };
  }
  componentDidMount() {
    let path = this.props.match.params.id;
    this.setState({ id: path });
    this.store.getProductDetail(path, this.props.history);
  }

  componentWillUnmount() {
    this.store.reset();
  }
  handleHasDiscount = (target, value) => {
    this.setState({ discount: value });
  };

  handleIsAvailable = (target, value) => {
    this.setState({ available: value });
  };

  setData = async (value, target, isMulti) => {
    await this.setState({ data: value, target, isMulti });
    this.store.changeOpenItemModal();
  };

  render() {
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            {this.store.openProductModal ? (
              <CollectionModal
                openModal={this.store.openProductModal}
                data={this.store.products}
                loading={this.store.isLoadingProduct}
                toggleModal={() => this.store.toggleProductModal()}
                pagination={this.store.paginationProduct}
                limit={this.store.limitProduct}
                currentPage={this.store.currentPageProduct}
                changePage={e => this.store.changePage(e)}
                target="product"
                selectedItems={this.store.productList}
                addItems={data => this.store.addItemToSelected(data)}
              ></CollectionModal>
            ) : null}
            {this.store.openItemModal ? (
              <ItemModal
                openModal={this.store.openItemModal}
                toggleModal={() => this.store.toggleItemModal()}
                target={this.state.target}
                data={this.state.data}
                addItems={(data, target) => {
                  this.store.setItems(data, target);
                }}
                isMulti={this.state.isMulti}
              ></ItemModal>
            ) : null}
            <Colxx xxs="12">
              <Colxx xxs="12" className="d-flex flex-wrap align-items-center mb-3">
                <Colxx xxs="12" sm="4" md="6">
                  <BreadcrumbContainer
                    heading={<IntlMessages id="menu.product" />}
                    match={this.props.match}
                  />
                </Colxx>
                <Colxx xxs="12" sm="8" md="6" className="d-flex  justify-content-sm-end justify-content-center ">
                  
                  <Button
                    color="primary"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() =>
                      this.store.validation(this.state.id, this.props.history)
                    }
                  >
                    <IntlMessages id="pages.save" />
                  </Button>

                  <Button
                    color="btn-outline-secondary"
                    className="btn-shadow float-right ml-2"
                    size="lg"
                    onClick={() => this.store.changeOpenDeleteModal()}
                  >
                    <IntlMessages id="pages.delete" />
                  </Button>
                  <DeleteModal
                    target={
                      this.store.categoryDetail
                        ? this.store.categoryDetail.name_en
                        : null
                    }
                    openModal={this.store.openDeleteModal}
                    toggleModal={() => this.store.toggleDeleteModal()}
                    action={() =>
                      this.store.deleteCake(this.state.id, this.props.history)
                    }
                  />
                </Colxx>
              </Colxx>
              <Colxx xxs="12">
                <Separator className="mb-5" />
              </Colxx>
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: "25px" }}
                >
                  <CardTitle></CardTitle>
                  <Row>
                    <Colxx
                      xxs="12"
                      md="6"
                      lg="4"
                      className="mb-4 d-flex flex-column align-item-center"
                    >
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Button
                            color="primary"
                            size="lg"
                            className="pointer mb-4 col-12"
                            style={{}}
                          >
                            <Label
                              className="btn btn-default no-padding col-12"
                              style={{
                                padding: "0px",
                                marginBottom: "0px"
                              }}
                            >
                              <strong>Add New Image</strong>
                              <input
                                className="col-12 p-0 "
                                id="image"
                                name="image"
                                type="file"
                                accept="image/x-png,image/jpeg"
                                onChange={e =>
                                  this.store.changeImageFile(
                                    e.currentTarget.files[0],
                                    this.state.id
                                  )
                                }
                                hidden
                              />
                            </Label>
                          </Button>
                          <ImageGalleryCard
                            data={this.store.productDetail.images}
                            deleteImage={id =>
                              this.store.handleDeleteImage(
                                this.state.id,
                                id,
                                this.props.history
                              )
                            }
                            changeOrderImages={arr =>
                              this.store.changeOrderImages(arr)
                            }
                          />
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx xxs="12" md="6" lg="4" className="mb-4 ">
                      <Card className="mb-2 h-100">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.available" />
                            </p>

                            <div className="d-flex align-items-center col-12 p-0">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse"
                                name="available"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.is_available
                                    : false
                                }
                                onChange={() => this.store.handleIsAvailable()}
                              />
                            </div>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.name_en
                                    : ""
                                }
                                type="text"
                                onChange={e =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.name_ar
                                    : ""
                                }
                                type="text"
                                onChange={e =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.price" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.price
                                    : ""
                                }
                                type="text"
                                onChange={e =>
                                  this.store.changePrice(e.target.value)
                                }
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.price_validation
                                ? this.store.price_validation.msg
                                  ? this.store.price_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.discount" />
                            </p>

                            <div className="d-flex align-items-center col-12 p-0">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse"
                                name="discount"
                                value={this.store.discount}
                                onChange={e =>
                                  this.store.changeDiscount(
                                    !this.store.discount
                                  )
                                }
                              />
                            </div>
                          </Colxx>
                          {this.store.discount ? (
                            <Colxx xxs="12" lg="12" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.oldPrice" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-0">
                                <Input
                                  name="name"
                                  value={
                                    this.store.productDetail
                                      ? this.store.productDetail.old_price
                                      : ""
                                  }
                                  type="text"
                                  onChange={e =>
                                    this.store.changeOldPrice(e.target.value)
                                  }
                                />
                              </p>
                              <span className="req-validation ">
                                {this.store.price_validation
                                  ? this.store.price_validation.msg
                                    ? this.store.price_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          ) : null}
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.dimensions" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0 d-flex ">
                              <Colxx xxs="4" className="pl-0 pr-2 ml-0">
                                <Input
                                  name="width"
                                  value={this.store.productDetail.dimension_x}
                                  type="text"
                                  onChange={e =>
                                    this.store.changeWidth(e.target.value)
                                  }
                                />
                                <span className="req-validation ">
                                  {this.store.width_validation
                                    ? this.store.width_validation.msg
                                      ? this.store.width_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                              <Colxx xxs="4" className="pl-1 pr-1 ml-0">
                                <Input
                                  name="height"
                                  value={this.store.productDetail.dimension_y}
                                  type="text"
                                  onChange={e =>
                                    this.store.changeHeight(e.target.value)
                                  }
                                />
                                <span className="req-validation ">
                                  {this.store.height_validation
                                    ? this.store.height_validation.msg
                                      ? this.store.height_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                              <Colxx xxs="4" className="pl-2 ml-0 pr-0">
                                <Input
                                  name="length"
                                  value={this.store.productDetail.dimension_z}
                                  type="text"
                                  onChange={e =>
                                    this.store.changeLength(e.target.value)
                                  }
                                />
                                <span className="req-validation ">
                                  {this.store.length_validation
                                    ? this.store.length_validation.msg
                                      ? this.store.length_validation.msg
                                      : null
                                    : null}
                                </span>
                              </Colxx>
                            </p>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.weight" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.weight
                                    : ""
                                }
                                type="text"
                                onChange={e =>
                                  this.store.changeWeight(e.target.value)
                                }
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.weight_validation
                                ? this.store.weight_validation.msg
                                  ? this.store.weight_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.sku" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.productDetail
                                    ? this.store.productDetail.sku
                                    : ""
                                }
                                type="text"
                                onChange={e =>
                                  this.store.changeSku(e.target.value)
                                }
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.price_validation
                                ? this.store.price_validation.msg
                                  ? this.store.price_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.tag" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: "1px solid #ddd" }}
                            >
                              {this.store.productDetail.tags.map(item => (
                                <Card
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: "max-content",
                                    maxWidth: "100%"
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: "40px" }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: "100%" }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image.url
                                              : "/assets/img/noimage.png"
                                          }
                                          style={{ borderRadius: "50%" }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.name_en}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon"
                                      onClick={() => this.store.removeTag(item)}
                                    ></i>
                                  </div>
                                </Card>
                              ))}
                              <Button
                                color="primary"
                                className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                onClick={() => {
                                  this.setData(
                                    this.store.tagsList,
                                    "tag",
                                    true
                                  );
                                }}
                                style={{ height: 40, width: 70 }}
                              >
                                {" "}
                                <IntlMessages id="pages.add" />
                                <i className="iconsminds-add" />
                              </Button>
                            </div>
                            <span className="req-validation ">
                              {this.store.tag_validation
                                ? this.store.tag_validation.msg
                                  ? this.store.tag_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.brand" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: "1px solid #ddd" }}
                            >
                              {this.store.brand && this.store.brand && (
                                <Card className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1">
                                  <div className=" d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: "40px" }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: "100%" }}
                                      >
                                        <CardImg
                                          src={
                                            this.store.brand.image
                                              ? this.store.brand.image.url
                                              : "/assets/img/noimage.png"
                                          }
                                          style={{ borderRadius: "50%" }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText
                                      className="text-small pl-2 pr-2 m-0 d-flex "
                                      style={{ flex: 1 }}
                                    >
                                      {this.store.brand.name_en}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon "
                                      onClick={() =>
                                        this.store.removeBrand(this.store.barnd)
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              )}
                              {this.store.productDetail && !this.store.brand ? (
                                <Button
                                  color="primary"
                                  className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                  onClick={() => {
                                    this.setData(
                                      this.store.brandsList,
                                      "brand",
                                      false
                                    );
                                  }}
                                  style={{ height: 40, width: 70 }}
                                >
                                  {" "}
                                  <IntlMessages id="pages.add" />
                                  <i className="iconsminds-add" />
                                </Button>
                              ) : null}
                            </div>
                            <span className="req-validation ">
                              {this.store.category_validation
                                ? this.store.category_validation.msg
                                  ? this.store.category_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.category" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: "1px solid #ddd" }}
                            >
                              {this.store.productDetail &&
                                this.store.productDetail.category && (
                                  <Card className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1">
                                    <div className=" d-flex align-items-center">
                                      <div
                                        className=" p-0 m-0"
                                        style={{ width: "40px" }}
                                      >
                                        <div
                                          className="position-relative col-12 m-0"
                                          style={{ paddingTop: "100%" }}
                                        >
                                          <CardImg
                                            src={
                                              this.store.productDetail.category
                                                .image
                                                ? this.store.productDetail
                                                    .category.image
                                                : "/assets/img/noimage.png"
                                            }
                                            style={{ borderRadius: "50%" }}
                                          ></CardImg>
                                        </div>
                                      </div>
                                      <CardText
                                        className="text-small pl-2 pr-2 m-0 d-flex "
                                        style={{ flex: 1 }}
                                      >
                                        {
                                          this.store.productDetail.category
                                            .name_en
                                        }
                                      </CardText>
                                      <i
                                        className="iconsminds-close remove-icon "
                                        onClick={() =>
                                          this.store.removeCategory(
                                            this.store.productDetail.category
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </Card>
                                )}
                              {this.store.productDetail &&
                              !this.store.productDetail.category ? (
                                <Button
                                  color="primary"
                                  className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                  onClick={() => {
                                    this.setData(
                                      this.store.categoriesList,
                                      "category",
                                      false
                                    );
                                  }}
                                  style={{ height: 40, width: 70 }}
                                >
                                  {" "}
                                  <IntlMessages id="pages.add" />
                                  <i className="iconsminds-add" />
                                </Button>
                              ) : null}
                            </div>
                            <span className="req-validation ">
                              {this.store.category_validation
                                ? this.store.category_validation.msg
                                  ? this.store.category_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.make" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: "1px solid #ddd" }}
                            >
                              {this.store.productDetail.brew_types.map(item => (
                                <Card
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: "max-content",
                                    maxWidth: "100%"
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: "40px" }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: "100%" }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image.url
                                              : "/assets/img/noimage.png"
                                          }
                                          style={{ borderRadius: "50%" }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.name_en}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon"
                                      onClick={() =>
                                        this.store.removeBrew(item)
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              ))}
                              <Button
                                color="primary"
                                className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                onClick={() => {
                                  this.setData(
                                    this.store.brewsList,
                                    "brew",
                                    true
                                  );
                                }}
                                style={{ height: 40, width: 70 }}
                              >
                                {" "}
                                <IntlMessages id="pages.add" />
                                <i className="iconsminds-add" />
                              </Button>
                            </div>
                            <span className="req-validation ">
                              {this.store.category_validation
                                ? this.store.category_validation.msg
                                  ? this.store.category_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description" />
                            </p>
                            <p className="mb-3">
                              <Input
                                name="address"
                                value={
                                  this.store.productDetail.description_en
                                    ? this.store.productDetail.description_en
                                    : ""
                                }
                                type="textarea"
                                onChange={e =>
                                  this.store.changeEnDescription(e.target.value)
                                }
                              />
                            </p>
                          </Colxx>

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description-ar" />
                            </p>
                            <p className="mb-3">
                              <Input
                                name="address"
                                value={
                                  this.store.productDetail.description_ar
                                    ? this.store.productDetail.description_en
                                    : ""
                                }
                                type="textarea"
                                onChange={e =>
                                  this.store.changeArDescription(e.target.value)
                                }
                              />
                            </p>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>

                    <Colxx
                      xxs="12"
                      md="12"
                      lg="4"
                      className="mb-4"
                      style={{ height: "max-content" }}
                    >
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Similar Product`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx
                            xxs="12"
                            className="p-0 m-0 mb-3 d-flex flex-wrap"
                          >
                            {this.store.productList
                              ? this.store.productList.map(item => (
                                  <a
                                    href={`/app/product/detail/${item.id}`}
                                    className="p-1 col-6"
                                  >
                                    <Card className="col-12 m-0 p-0">
                                      <div
                                        className="position-relative col-12"
                                        style={{ paddingTop: "100%" }}
                                      >
                                        <CardImg
                                          src={
                                            item.images
                                              ? item.images.url
                                              : "/assets/img/noimage.png"
                                          }
                                        ></CardImg>
                                      </div>
                                    </Card>
                                  </a>
                                ))
                              : null}
                          </Colxx>
                          <Button
                            color="primary"
                            size="lg"
                            className="pointer mb-4 col-12"
                            style={{}}
                            onClick={() =>
                              this.store.getProduct(this.props.history)
                            }
                          >
                            <Label
                              className="btn btn-default no-padding"
                              style={{
                                padding: "0px",
                                marginBottom: "0px"
                              }}
                            >
                              <strong>Add New Similar</strong>{" "}
                            </Label>
                          </Button>
                          <Colxx xxs="12" lg="12" className="mb-3"></Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default ProductDetail;
