import React, { Component } from 'react'
import { Button, Label, FormGroup, Input } from 'reactstrap'
import { Colxx } from '../CustomBootstrap'
import { FormikSwitch } from '../FormValidations/FormikFields'

class CustomItem extends Component {
  constructor(props) {
    super(props)
    this.state = { items: null }
  }

  componentDidMount() {
    this.setState({ items: this.props.array })
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.array })
  }

  handleChangePrice(target, name, value) {
    let selected = this.state.items
    selected.forEach((item, index) => {
      if (item.name === name) {
        selected[index].price = value
      }
    })
    this.setState({ items: selected })
    this.props.changePrice(target, name, value)
  }

  handleIsSupported(target, name) {
    let selected = this.state.items
    let is_supported
    selected.forEach((item, index) => {
      if (item.name === name) {
        is_supported = !selected[index].is_supported
        selected[index].is_supported = !selected[index].is_supported
      }
    })
    this.setState({ items: selected })
    this.props.changeIsSupport(target, name, is_supported)
  }
  render() {
    const { name, handleOpenModal } = this.props
    return (
      <Colxx xxs="12" className="pt-4">
        <div
          className="col-12"
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <p className="p-0 m-0 pr-2 font-weight-bold">{name.toUpperCase()}</p>
          <div
            className=""
            style={{
              display: 'flex',
              flex: 1,
              borderBottom: '1px solid black',
            }}
          ></div>
        </div>
        {name === 'bakery_info' ? (
          <Colxx xxs="12" className=" pt-4">
            {this.state.items
              ? this.state.items.map((item) => (
                  <FormGroup className="col-6 mb-4">
                    <Label>
                      <img
                        src={
                          item.image
                            ? item.image.url
                            : '/assets/img/noimage.png'
                        }
                        alt={name}
                        style={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                        }}
                        className="mr-1"
                      />
                      {item.name}
                    </Label>
                    <div className="d-flex align-items-center col-12">
                      <FormikSwitch
                        className="custom-switch custom-switch-primary-inverse mr-2"
                        name={item.name}
                        value={item.is_supported}
                        onChange={() => this.handleIsSupported(name, item.name)}
                        // onBlur={handleBlur}
                      />
                      {item.is_supported ? (
                        <Input
                          className="col-8"
                          type="text"
                          value={item.price}
                          onChange={(e) =>
                            this.handleChangePrice(
                              name,
                              item.name,
                              e.target.value
                            )
                          }
                        />
                      ) : null}
                    </div>
                  </FormGroup>
                ))
              : null}
          </Colxx>
        ) : (
          <Colxx xxs="12" className="d-flex flex-wrap pt-4">
            {this.state.items
              ? this.state.items.map((item) =>
                  item.is_supported ? (
                    <FormGroup className="col-4">
                      <Label>
                        <img
                          src={
                            item.image
                              ? item.image.url
                              : '/assets/img/noimage.png'
                          }
                          alt={name}
                          style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                          }}
                          className="mr-1"
                        />
                        {item.name}
                      </Label>
                      <Input
                        type="text"
                        value={item.price}
                        onChange={(e) =>
                          this.handleChangePrice(
                            name,
                            item.name,
                            e.target.value
                          )
                        }
                      />
                    </FormGroup>
                  ) : null
                )
              : null}
            <div className="col-4 p-3 pb-0 pt-4">
              <Button
                style={{ borderRadius: 0, height: '42.8px', marginTop: 3 }}
                className="col-12"
                onClick={() => handleOpenModal(name)}
                color="primary"
              >
                {`Add a new ${name}`}
              </Button>
            </div>
          </Colxx>
        )}
      </Colxx>
    )
  }
}

export default CustomItem
