import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Card,
  CardText,
  CardBody,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import Pagination from '../../../components/List/Pagination'
import { NavLink } from 'react-router-dom'
import { sum } from 'lodash'
import noResult from '../../../assets/img/NoResult.png'
import classnames from 'classnames'

@inject('stores')
@observer
class CalculationList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
  }
  @observable
  store = this.props.stores.calculation

  @observable
  setting = this.props.stores.setting

  componentDidMount(){
    this.store.getData(this.props.history)
  }
  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12" className="d-flex align-items-center flex-wrap">
            <Colxx xxs="8">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.calculation" />}
                match={this.props.match}
              />
            </Colxx>
            <Row className="col-12 justify-content-start p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-block d-md-inline-block">
                  <UncontrolledDropdown
                    className="mr-1 float-md-left btn-group mb-1"
                    disabled={!this.store.data.length}
                  >
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.orderby" />
                      {this.store.selectedOrderOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.orderOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeOrderByList(
                                order.column,
                                this.props.history
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </Row>
          </Colxx>

          <Colxx xxs="12">
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row className="mb-4">
          <Colxx>
            <Card
              className="p-2 pb-4 pt-4 d-flex  flex-row"
              style={{ overflow: 'unset' }}
            >
              <Colxx xl="4" className="p-2">
                <div className="col-12  p-0 pr-lg-2 d-flex">
                  <div className="col-12">
                    <DatePicker
                      className="custom-datePicker text-center font-weight-bold"
                      placeholderText="---- Click To Select 'Start Date' ----"
                      selected={this.store.startDate}
                      isClearable
                      onChange={(date) =>
                        this.store.changeStartDate(date, this.props.history)
                      }
                    />
                  </div>
                </div>
              </Colxx>
              <Colxx xl="4" className="p-2">
                <div className="col-12  p-0 pr-lg-2 d-flex">
                  <div className="col-12">
                    <DatePicker
                      className="custom-datePicker text-center font-weight-bold"
                      placeholderText="---- Click To Select 'End Date' ----"
                      selected={this.store.endDate}
                      isClearable
                      onChange={(date) =>
                        this.store.changeEndDate(date, this.props.history)
                      }
                    />
                  </div>
                </div>
              </Colxx>

              <Colxx xl="4" className="p-2">
                <div className="col-12  p-0 pr-lg-2 d-flex">
                  <div className="col-12">
                    <Button
                    disabled={(!this.store.startDate || !this.store.endDate)}
                      color="primary"
                      className="btn-shadow col-12 font-weight-bold"
                      onClick={() => this.store.getData(this.props.history)}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </Colxx>
            </Card>
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="pl-0 d-xl-block d-md-none d-none">
                <div
                  className="col-12 p-3 d-flex align-items-center mb-3"
                  style={{ borderBottom: '1px solid gray' }}
                >
                  <p className="col-1  m-0 text-muted  font-weight-bold text-center">
                    Image
                  </p>
                  <p
                    className={classnames(
                      'col-2  m-0  font-weight-bold text-center pointer',
                      {
                        'color-theme-1': this.store.selectedOrderOption.column.includes(
                          'name_en'
                        ),
                        'text-muted': !this.store.selectedOrderOption.column.includes(
                          'name_en'
                        ),
                      }
                    )}
                    onClick={() => this.store.changeOrderBy('name_en')}
                  >
                    English Name
                  </p>
                  <p
                    className={classnames(
                      'col-2  m-0  font-weight-bold text-center pointer',
                      {
                        'color-theme-1': this.store.selectedOrderOption.column.includes(
                          'normal_order_item_count'
                        ),
                        'text-muted': !this.store.selectedOrderOption.column.includes(
                          'normal_order_item_count'
                        ),
                      }
                    )}
                    onClick={() =>
                      this.store.changeOrderBy('normal_order_item_count')
                    }
                  >
                    Normal Item (Count){' '}
                  </p>

                  <p
                    className={classnames(
                      'col-2  m-0  font-weight-bold text-center pointer',
                      {
                        'color-theme-1': this.store.selectedOrderOption.column.includes(
                          'normal_order_weight_total_kilograms'
                        ),
                        'text-muted': !this.store.selectedOrderOption.column.includes(
                          'normal_order_weight_total_kilograms'
                        ),
                      }
                    )}
                    onClick={() =>
                      this.store.changeOrderBy(
                        'normal_order_weight_total_kilograms'
                      )
                    }
                  >
                    Normal Item (Weight kg)
                  </p>

                  <p
                    className={classnames(
                      'col-2  m-0  font-weight-bold text-center pointer',
                      {
                        'color-theme-1': this.store.selectedOrderOption.column.includes(
                          'whole_sale_orders_count'
                        ),
                        'text-muted': !this.store.selectedOrderOption.column.includes(
                          'whole_sale_orders_count'
                        ),
                      }
                    )}
                    onClick={() =>
                      this.store.changeOrderBy('whole_sale_orders_count')
                    }
                  >
                    {' '}
                    Whole Sale (Count)
                  </p>
                  <p
                    className={classnames(
                      'col-2  m-0  font-weight-bold text-center pointer',
                      {
                        'color-theme-1': this.store.selectedOrderOption.column.includes(
                          'whole_sale_weight_kilos'
                        ),
                        'text-muted': !this.store.selectedOrderOption.column.includes(
                          'whole_sale_weight_kilos'
                        ),
                      }
                    )}
                    onClick={() =>
                      this.store.changeOrderBy('whole_sale_weight_kilos')
                    }
                  >
                    {' '}
                    Whole Sale (Weight kg)
                  </p>

                  <p
                    className={classnames(
                      'col-1  m-0  font-weight-bold text-center pointer',
                      {
                        'color-theme-1': this.store.selectedOrderOption.column.includes(
                          'sum'
                        ),
                        'text-muted': !this.store.selectedOrderOption.column.includes(
                          'sum'
                        ),
                      }
                    )}
                    onClick={() => this.store.changeOrderBy('sum')}
                  >
                    {' '}
                    Sum (kg)
                  </p>
                </div>
              </Colxx>
              {this.store.data.length ? (
                this.store.data.map((item) => (
                  <Colxx
                    key={item.product_id}
                    xxs="12"
                    sm="6"
                    md="4"
                    lg="4"
                    xl="12"
                    className=" mb-3 p-0"
                  >
                    <NavLink
                      to={`/app/product/detail/${item.product_id}`}
                      className="col-12 d-flex flex-wrap"
                    >
                      <Card className="col-12 p-0 pointer">
                        <CardBody className="d-flex  p-0 align-items-center flex-wrap">
                          <div className="position-relative col-12 d-flex flex-wrap p-xl-0 p-md-3">
                            <CardText className="col-xl-1 col-md-12  p-0 pr-2 m-0  font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                              <img
                                alt={item.name_en}
                                src={
                                  item.images
                                    ? item.images.url
                                    : '/assets/img/noimage.png'
                                }
                                className="list-thumbnail responsive border-0 p-0"
                              />
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                English Name:{' '}
                              </span>
                              {item.name_en}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Normal Item (Count) :{' '}
                              </span>
                              {item.normal_order_item_count}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Normal Item (Weight kg) :{' '}
                              </span>
                              {item.normal_order_weight_total_kilograms}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Whole Sale (Count) :{' '}
                              </span>
                              {item.whole_sale_orders_count}
                            </CardText>
                            <CardText className="col-xl-2 col-md-12 p-2 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Whole Sale (Weight kg) :{' '}
                              </span>
                              {item.whole_sale_weight_kilos}
                            </CardText>
                            <CardText className="col-xl-1 col-md-12 p-2 pr-3 m-0 font-weight-bold truncate d-flex align-items-center justify-content-xl-center justify-content-md-start  ">
                              <span className="d-xl-none d-md-inline font-weight-bold">
                                Sum (kg) :{' '}
                              </span>
                              {sum([
                                item.whole_sale_weight_kilos,
                                item.normal_order_weight_total_kilograms,
                              ])}
                              {/* {item.whole_sale_weight_kilos +item.normal_order_weight_total_kilograms} */}
                            </CardText>
                          </div>
                        </CardBody>
                      </Card>
                    </NavLink>
                  </Colxx>
                ))
              ) : (
                <div className="col-12 p-2 d-flex align-items-center justify-content-center">
                  <img
                    className="col-xl-4 xol-md-4 col-sm-12 col-12"
                    src={noResult}
                    alt="noresult"
                    style={{ maxWidth: 300 }}
                  />
                </div>
              )}
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default CalculationList
