import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FeaturetteList from './list';
import FeaturetteDetail from './detail';
import BannerDetail from './detail/banner';

const Featurette = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={FeaturetteList} />
            <Route path={`${match.url}/featurette/:id`} component={FeaturetteDetail} />
            <Route path={`${match.url}/banner/:id`} component={BannerDetail} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Featurette;