import { observable, action } from 'mobx'
// import axiFetch from "../config/fetch";
import { servicePath } from '../constants/defaultValues'
import axios from 'axios'
import { checkInput } from '../constants/validation'

export default class LoginStore {
  @observable
  email = 'demo@gogo.com'

  @observable
  password = 'gogo123'

  @observable
  authUser = false

  @observable
  loginLoading = false

  @observable
  forgetEmail = ''

  @observable
  newPassword = ''

  @observable
  confirmPassword = ''

  @observable
  isLoading = true

  @observable
  expire = null

  @observable
  response = null

  @observable
  new_password_validation = null

  @observable
  confirm_password_validation = null

  @action
  async checkUserLogin(history) {
    const userEmail = await window.localStorage.getItem('user_email')
    if (userEmail) {
      this.authUser = true
    }
  }

  @action
  async loginUser(userInfo, history) {
    this.btnDisable = true
    this.loginLoading = true
    const data = { email: userInfo.email, password: userInfo.password }
    axios
      .post(`${servicePath}/admin/login`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((response) => {
        if (response && response.meta.code === 200) {
          localStorage.setItem('user_email', userInfo.email)
          localStorage.setItem('user_name', response.data.name)
          localStorage.setItem('admin-coffee-type', response.data.type)
          if (response.data.image && response.data.image.url) {
            localStorage.setItem('user-profile', response.data.image.url)
          }
          this.authUser = true
          this.btnDisable = false
          history.push('/app/dashboards/default')
          this.loginLoading = false
        }
      })
      .catch((error) => {
        this.loginLoading = false
        this.btnDisable = false
        if (error) {
          this.response = { msg: error.response.data.meta.msg, type: 'error' }
        }
        this.response = null
      })
  }

  @action
  logoutUser = (history) => {
    this.authUser = false
    localStorage.removeItem('user_email')
    localStorage.removeItem('nizek_id')
    history.replace('/login')
  }

  @action
  changeNewPassword(value) {
    this.newPassword = value
  }

  @action
  changeConfirmPassword(value) {
    this.confirmPassword = value
  }

  @action
  async validationPassword(history, code) {
    if (this.newPassword === this.confirmPassword) {
      this.new_password_validation = await checkInput({
        name: 'New Password',
        value: this.newPassword,
        require: true,
        min: 6,
      })
      this.confirm_password_validation = await checkInput({
        name: 'Confirm Password',
        value: this.confirmPassword,
        require: true,
        min: 6,
      })

      this.new_password_validation.res &&
        this.confirm_password_validation.res &&
        this.handleSavePassword(history, code)
    } else {
      this.new_password_validation = null
      this.confirm_password_validation = {
        res: false,
        msg: 'New Password And Confirm Password Must Be Matched',
      }
    }
  }

  @action
  handleSavePassword(history, code) {
    this.loginLoading = true
    this.btnDisable = true
    const data = {
      token: code,
      password: this.newPassword,
      confirm_password: this.confirmPassword,
    }
    axios
      .post(`${servicePath}/admin/reset_password`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((response) => {
        this.loginLoading = false
        this.btnDisable = false
        history.push('/login')
      })
      .catch((error) => {
        this.loginLoading = false
        this.btnDisable = false
        if (error) {
          this.response = { msg: error.response.data.meta.msg, type: 'error' }
        }
        this.response = null
      })
  }

  @action
  checkCode(code, history) {
    this.isLoading = true
    axios
      .get(`${servicePath}/admin/check_forget_token/${code}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((response) => {
        this.isLoading = false
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.isLoading = false
          this.expire = 'Your Code Is Expired, Please Try Again'
          setTimeout(() => {
            history.push('/forgot-password')
          }, 5000)
        } else {
          history.push('/forgot-password')
        }
      })
  }

  @action
  changeForgetEmail(value) {
    this.forgetEmail = value
  }
  @action
  setEmailPassword(history) {
    this.loginLoading = true
    this.btnDisable = true
    const data = {
      email: this.forgetEmail,
    }
    axios
      .post(`${servicePath}/admin/forget_password`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.forgetEmail = ''
        this.loginLoading = false
        this.btnDisable = false
        if (data) {
          this.response = { msg: data.meta.msg, type: 'success' }
        }
        this.response = null
      })
      .catch((error) => {
        this.loginLoading = false
        this.btnDisable = false
        if (error) {
          this.response = { msg: error.response.data.meta.msg, type: 'error' }
        }
        this.response = null
      })
  }
}
