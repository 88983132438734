import { action, observable } from 'mobx'

export default class SettingStore {
  @observable
  locale = null

  @observable
  displayMode = 'imagelist'

  @observable
  defaultLocale = 'en'

  @observable
  localeOptions = [
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Español' },
  ]

  @action
  changeLocale(locale) {
    localStorage.setItem('currentLanguage', locale)
    this.locale = locale
  }

  @action
  setLocale() {
    this.locale =
      localStorage.getItem('currentLanguage') &&
      this.localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : this.defaultLocale
  }

  @action
  changeDisplayMode(mode) {
    this.displayMode = mode
  }
}
