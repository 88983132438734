import React, { Component, Fragment } from 'react'
import {
  Row,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  CardTitle,
  Badge,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ReactTable from 'react-table'
import { Colxx, Separator } from '../../components/CustomBootstrap'
import BreadcrumbContainer from '../../components/BreadcrumbContainer'
import DataTablePagination from '../../components/DataTables/Pagination'
import BigCalendar from 'react-big-calendar'
import 'react-table/react-table.css'
import IntlMessages from '../../util/IntlMessages'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import moment from 'moment'

import {
  LineShadow,
  chartTooltip,
  // centerTextPlugin
} from '../../components/Charts'
import { ThemeColors } from '../../util/ThemeColors'
const localizer = BigCalendar.momentLocalizer(moment)

const colors = ThemeColors()
const dataTableColumns = [
  {
    width: 230,
    Header: 'Name',
    accessor: 'name_en',
    Cell: (props) => <p className="list-item-heading">{props.value}</p>,
  },
  {
    Header: 'Sales',
    accessor: 'count',
    Cell: (props) => <p className="text-muted">{props.value}</p>,
  },
  // {
  //   Header: "Stock",
  //   accessor: "stock",
  //   Cell: props => <p className="text-muted">{props.value.remaining}</p>
  // },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: (props) => <p className="text-muted">{props.value}</p>,
  },
]

@inject('stores')
@observer
class DefaultDashboard extends Component {
  @observable
  store = this.props.stores.dashboard
  constructor(props) {
    super(props)
    this.state = { target: 'day', sale: [], labels: [1, 2, 3], helper: true }
  }

  async componentDidMount() {
    await this.store.getTodayData(this.props.history)
    this.setState({ sale: this.store.sale })
  }

  chart = () => {
    const options = {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: chartTooltip,
      // scaleBeginAtZero: true,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
              lineWidth: 1,
              color: 'rgba(0,0,0,0.1)',
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              padding: 50,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    }

    const data = {
      labels: this.store.labels ? this.store.labels : [1, 2, 3],
      datasets: [
        {
          label: 'Sale',
          data: this.store.sale.length ? this.store.sale : [100, 200, 300],
          borderColor: colors.themeColor1,
          pointBackgroundColor: colors.foregroundColor,
          pointBorderColor: colors.themeColor1,
          pointHoverBackgroundColor: colors.themeColor1,
          pointHoverBorderColor: colors.foregroundColor,
          pointRadius: 6,
          pointBorderWidth: 2,
          pointHoverRadius: 8,
          fill: false,
        },
      ],
    }
    return <LineShadow options={options} data={data} />
  }

  CalendarToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV')
      const date = moment(toolbar.date)
      const month = moment().month(date.format('MMMM')).format('M')
      const year = moment().year(date.format('YYYY')).format('Y')
      const m = month === 1 ? 12 : parseInt(month) - 1
      const y = month === 12 ? parseInt(year) - 1 : parseInt(year)
      this.store.getMonthDataCal(m, y)
    }
    const goToNext = () => {
      toolbar.onNavigate('NEXT')
      const date = moment(toolbar.date)
      const month = moment().month(date.format('MMMM')).format('M')
      const year = moment().year(date.format('YYYY')).format('Y')
      const m = month === 12 ? 1 : parseInt(month) + 1
      const y = month === 12 ? parseInt(year) + 1 : parseInt(year)
      this.store.getMonthDataCal(m, y)
    }
    const goToCurrent = () => {
      toolbar.onNavigate('TODAY')
      const today = new Date()
      const month = today.getMonth()
      const year = today.getFullYear()
      const m = parseInt(month) + 1
      const y = parseInt(year)
      this.store.getMonthDataCal(m, y)
    }

    const label = () => {
      const date = moment(toolbar.date)
      return (
        <span>
          <span>{date.format('MMMM')} </span>
          <span> {date.format('YYYY')}</span>
        </span>
      )
    }

    return (
      <div className="big-calendar-header">
        <div className="float-left">
          <label>{label()}</label>
        </div>

        <div className="float-right">
          <div>
            <button
              className="btn btn-primary calendar-today-btn mr-2"
              onClick={goToCurrent}
            >
              Today
            </button>
            <button className="btn calendar-prev-btn  mr-1" onClick={goToBack}>
              <span className="simple-icon-arrow-left" />
            </button>
            <button className="btn calendar-next-btn " onClick={goToNext}>
              <span className="simple-icon-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    )
  }

  handleTarget = (value) => {
    this.setState({ target: value })
  }

  render() {
    // aert()

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.dashboards" />}
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row className="d-flex justify-content-around mb-4">
              <Row className="col-xl-6 col-lg-6 col-md-12 col-xs-12 p-0 justify-content-center">
                <Row className="col-12 mb-4 " style={{ height: 'max-content' }}>
                  <Card
                    className="d-flex flex-wrap flex-row justify-content-around position-relative col-12"
                    style={{ height: 'max-content', overflow: 'unset' }}
                  >
                    <UncontrolledDropdown
                      // style={{borderRight:'1px solid #ddd'}}
                      className="pt-3 pb-3  position-relative col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center"
                      // style={{ width: "max-content" }}
                    >
                      <DropdownToggle color="" className="btn btn-link">
                        <span>
                          <IntlMessages
                            id={`dashboards.${this.store.target}`}
                          />{' '}
                          <i className="simple-icon-arrow-down ml-4" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('day')}
                        >
                          <IntlMessages id="dashboards.day" />
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('month')}
                        >
                          <IntlMessages id="dashboards.month" />
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('year')}
                        >
                          <IntlMessages id="dashboards.year" />
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.store.handleTarget('allTime')}
                        >
                          <IntlMessages id="dashboards.allTime" />
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                      // style={{borderRight:'1px solid  #ddd'}}
                      className="pt-3 pb-3  position-relative col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center"
                    >
                      <DropdownToggle
                        color=""
                        className="btn btn-link"
                        disabled={
                          this.store.target === 'day' ||
                          this.store.target === 'year' ||
                          this.store.target === 'allTime'
                        }
                      >
                        <span>
                          <span>{this.store.targetMonth}</span>{' '}
                          <i className="simple-icon-arrow-down ml-4" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="padding-drop">
                        {!this.store.months
                          ? ''
                          : this.store.months.map((month, index) => (
                              <DropdownItem
                                key={index + 1}
                                onClick={() => this.store.handleMonth(month)}
                              >
                                <span>{month}</span>
                              </DropdownItem>
                            ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                      // style={{borderRight:'1px solid  #ddd'}}
                      className="pt-3 pb-3  col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center"
                    >
                      <DropdownToggle
                        color=""
                        className="btn btn-link "
                        disabled={
                          this.store.target === 'day' ||
                          this.store.target === 'allTime'
                        }
                      >
                        <span>
                          <span>{this.store.targetYear}</span>{' '}
                          <i className="simple-icon-arrow-down ml-4" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {!this.store.years
                          ? ''
                          : this.store.years.map((year, index) => (
                              <DropdownItem
                                key={index + 1}
                                onClick={() => this.store.handleYear(year)}
                              >
                                <span>{year}</span>
                              </DropdownItem>
                            ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <div className="pt-3 pb-3 col-xl-2 col-lg-2  col-md-3 col-xs-3 col-12 d-flex flex-row justify-content-center">
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          this.store.handleFilter(this.props.history)
                        }
                      >
                        <span className="font-weight-bold">
                          Apply <i className="simple-icon-refresh ml-4" />
                        </span>
                      </button>
                    </div>
                  </Card>
                </Row>
                <Row className="col-12 p-0">
                  <Colxx
                    xl="6"
                    md="6"
                    xs="6"
                    xxs="12"
                    className="mb-4"
                    style={{ height: 255 }}
                  >
                    <Card className="col-xl-12 col-md-12 col-xs-12 h-100 pointer">
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-coins iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.total-sale" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.totalSale
                            ? this.store.totalSale + ' KD'
                            : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                  <Colxx
                    xl="6"
                    md="6"
                    xs="6"
                    xxs="12"
                    className="mb-4"
                    style={{ height: 255 }}
                  >
                    <Card className="col-xl-12 col-md-12 col-xs-12 h-100 pointer">
                      <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                        <i className="iconsminds-checkout iconHome" />
                        <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                          <IntlMessages id="dashboards.total-sale-item" />
                        </p>
                        <p className="lead text-center number-home m-0">
                          {this.store.totalSaleItem
                            ? this.store.totalSaleItem
                            : 0}
                        </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                </Row>
              </Row>
              <Row className="col-xl-3 col-lg-3 col-md-6 col-xs-6 col-12 mb-4">
                <Card className="mb-4 col-xl-12 col-md-12 col-xs-12 pointer">
                  <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                    <i className="iconsminds-scooter iconHome" />
                    <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                      <IntlMessages id="dashboards.inTransit" />
                    </p>
                    <p className="lead text-center number-home m-0">
                      {this.store.sentOrders ? this.store.sentOrders : 0}
                    </p>
                  </CardBody>
                </Card>
                <Card className=" col-xl-12 col-md-12 col-xs-12 pointer">
                  <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                    <i className="iconsminds-box-close iconHome" />
                    <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                      <IntlMessages id="dashboards.preparing-orders" />
                    </p>
                    <p className="lead text-center number-home m-0">
                      {this.store.preparingOrders
                        ? this.store.preparingOrders
                        : 0}
                    </p>
                  </CardBody>
                </Card>
              </Row>
              <Row className="col-xl-3 col-lg-3 col-md-6 col-xs-6 col-12 mb-4 ">
                <Card className="mb-4 col-xl-12 col-md-12 col-xs-12 pointer">
                  <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                    <i className="iconsminds-yes iconHome" />
                    <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                      <IntlMessages id="dashboards.completed-orders" />
                    </p>
                    <p className="lead text-center number-home m-0">
                      {this.store.complatedOrders
                        ? this.store.complatedOrders
                        : 0}
                    </p>
                  </CardBody>
                </Card>
                <Card className=" col-xl-12 col-md-12 col-xs-12 pointer">
                  <CardBody className="text-center d-flex flex-column justify-content-center pr-0 pl-0">
                    <i className="iconsminds-close iconHome" />
                    <p className="card-text font-weight-semibold mb-0 text-home pt-1 pb-1">
                      <IntlMessages id="dashboards.cancelled-orders" />
                    </p>
                    <p className="lead text-center number-home m-0">
                      {this.store.canceledOrders
                        ? this.store.canceledOrders
                        : 0}
                    </p>
                  </CardBody>
                </Card>
              </Row>
            </Row>
            <Row className="p-0 col-12 m-0">
              <Colxx md="12" className="mb-4 p-0">
                <Card style={{ height: 'max-content' }}>
                  <CardBody>
                    <CardTitle>
                      <IntlMessages id="dashboards.sales" />
                      {' KD '}
                      {this.state.target === 'year'
                        ? `( ${this.store.targetYear} )`
                        : this.store.target === 'month'
                        ? `( ${moment()
                            .month(this.store.targetMonth)
                            .format('M')}-${this.store.targetYear} )`
                        : this.store.target === 'day'
                        ? `( ${moment()
                            .month(this.store.targetMonth)
                            .format('M')}-${this.store.targetYear} )`
                        : 'All Time'}
                    </CardTitle>
                    <div
                      className="dashboard-line-chart"
                      // style={{ height: "max-content" }}
                    >
                      {this.chart()}
                      {/* <LineShadow  data={data} /> */}
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <Row>
              <Colxx xl="6" md="12" className="mb-4">
                <Card>
                  <CardBody>
                    <CardTitle>
                      <IntlMessages id="dashboards.recent-orders" />
                    </CardTitle>

                    <div className="scroll dashboard-list-with-thumbs ">
                      <PerfectScrollbar
                        option={{
                          suppressScrollX: true,
                          wheelPropagation: true,
                        }}
                      >
                        {this.store.recentOrders
                          ? this.store.recentOrders.map((order, index) => {
                              return (
                                <div
                                  key={index}
                                  className="d-flex flex-row mb-3 col-12 col-md-6  float-left"
                                >
                                  <NavLink
                                    to={`/app/order/detail/${order.summary.id}`}
                                    className="d-block position-relative"
                                  >
                                    <img
                                      src={order.items[0].images[0].url}
                                      alt={order.name}
                                      className="list-thumbnail-letters"
                                      style={{
                                        borderRadius: '8px',
                                        backgroundColor: '#eee',
                                      }}
                                    />
                                    <Badge
                                      key={index}
                                      className="position-absolute badge-top-right"
                                      color={
                                        order.summary.delivery_status ===
                                        'delivered'
                                          ? 'success'
                                          : order.summary.delivery_status ===
                                            'sent'
                                          ? 'primary'
                                          : order.summary.delivery_status ===
                                            'cancelled'
                                          ? 'danger'
                                          : 'info'
                                      }
                                      pill
                                    >
                                      {order.summary.delivery_status ===
                                      'delivered'
                                        ? 'Completed'
                                        : order.summary.delivery_status ===
                                          'sent'
                                        ? 'In Transit'
                                        : order.summary.delivery_status ===
                                          'cancelled'
                                        ? 'Cancelled'
                                        : 'Preparing'}
                                    </Badge>
                                  </NavLink>

                                  <div className="pl-3 pt-2 pr-2 pb-2">
                                    <NavLink
                                      to={`/app/order/detail/${order.summary.id}`}
                                    >
                                      <p className="list-item-heading truncate">
                                        {order.summary.customer.name}
                                      </p>
                                      <div className="pr-4">
                                        <p className="text-muted mb-1 text-small truncate">
                                          {order.summary.date.split(' ')[0]}
                                        </p>
                                      </div>
                                      <div className="text-primary text-small font-weight-medium d-none d-sm-block truncate">
                                        {order.summary.payment_type ===
                                        'master_card'
                                          ? `Master Card ${Number(
                                              order.summary.total_price
                                            ).toFixed(2)} ${
                                              order.summary.currency
                                            }`
                                          : order.summary.payment_type ===
                                            'knet'
                                          ? `KNET ${Number(
                                              order.summary.total_price
                                            ).toFixed(2)} ${
                                              order.summary.currency
                                            }`
                                          : order.summary.payment_type ===
                                            'cash'
                                          ? `Cash ${Number(
                                              order.summary.total_price
                                            ).toFixed(2)} ${
                                              order.summary.currency
                                            }`
                                          : `${
                                              order.summary.payment_type
                                            } ${Number(
                                              order.summary.total_price
                                            ).toFixed(2)} ${
                                              order.summary.currency
                                            } `}
                                      </div>
                                    </NavLink>
                                  </div>
                                </div>
                              )
                            })
                          : ''}
                      </PerfectScrollbar>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>

              <Colxx xl="6" md="12" className="mb-4">
                <Card className="h-100">
                  <CardBody>
                    <CardTitle>
                      <IntlMessages id="dashboards.best-sellers" />
                      {this.store.target === 'year'
                        ? ` ( ${this.store.targetYear} )`
                        : this.store.target === 'month'
                        ? ` ( ${moment()
                            .month(this.store.targetMonth)
                            .format('M')}-${this.store.targetYear} )`
                        : this.store.target === 'day'
                        ? ` ( ${moment().date()}-${moment()
                            .month(this.store.targetMonth)
                            .format('M')}-${this.store.targetYear} )`
                        : ' All Time'}
                    </CardTitle>
                    {this.store.topSellers && this.store.topSellers.length ? (
                      <ReactTable
                        defaultPageSize={6}
                        data={this.store.topSellers}
                        columns={dataTableColumns}
                        minRows={0}
                        showPageJump={false}
                        showPageSizeOptions={false}
                        PaginationComponent={DataTablePagination}
                      />
                    ) : (
                      <p className="col-12 text-center">No Items Found</p>
                    )}
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <Colxx md="12" className="mb-4 p-0">
              <Card className="h-100">
                <CardBody className="position-relative">
                  <CardTitle>
                    <IntlMessages id="dashboards.calendar" />
                  </CardTitle>
                  {this.store.calLoading ? (
                    <div className="loading"></div>
                  ) : null}
                  <BigCalendar
                    localizer={localizer}
                    style={{ padding: '0 0 50px 0 ' }}
                    events={this.store.event ? this.store.event : []}
                    views={['month']}
                    components={{
                      toolbar: this.CalendarToolbar,
                    }}
                  />
                </CardBody>
              </Card>
            </Colxx>
          </>
        )}
      </Fragment>
    )
  }
}

export default DefaultDashboard
