import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProfileList from './list';



const Profile = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={ProfileList} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Profile;