import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
// import DisplayOption from "../../../components/DisolayOption";
// import CardImageList from "../../../components/Card/cardImageList";
// import ModalContainer from "../../../components/Modal";
import Pagination from '../../../components/List/Pagination'

@inject('stores')
@observer
class CustomerList extends Component {
  @observable
  store = this.props.stores.customer

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.customers" />}
                  match={this.props.match}
                />

                <Separator className="mb-5" />
              </Colxx>
            </Row>

            <Row>
              <Colxx xxs="12">
                <Row>
                  {this.store.data
                    ? this.store.data.map((ingredient) => (
                        <CardThumbList
                          key={ingredient.id}
                          data={ingredient}
                          path="customers/detail"
                        />
                      ))
                    : null}
                </Row>
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default CustomerList
