import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as Provide} from "mobx-react";
import store from './store/index';
// import "react-datepicker/dist/react-datepicker.css";
 import "./assets/css/vendor/react-big-calendar.css";
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/sass/_gogo.style.scss';
import 'suneditor/dist/css/suneditor.min.css';
// import 'bootstrap/dist/css/bootstrap.css';

import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from './containers/App';



function MainApp() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>
  );
}

export default ReactDOM.render(
  <Provide stores={store}>
       <MainApp />
   </Provide>, document.getElementById('root'))
;
serviceWorker.unregister();