import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import Sidebar from "../containers/Sidebar";
import TopNav from "../containers/TopNav";

import Dashboards from "./dashboards";
import Category from "./category";
import FaqCategory from "./faqCategory";
import Product from "./product";
import Order from "./order";
import SocialNetwork from "./social";
import DeliveryTime from "./deliveryTime";
import Featurette from "./featurette";
import Customers from "./customer"
import Tag from "./tag";
import Wholesale from "./wholesale";
import Brew from "./brew";
import Blog from "./blog";
import Brand from "./brand";
import Profile from "./profile";
import Careers from "./careers";
import User from "./user";
import OurStory from "./story";
import Memory from "./momory";
import Recipes from "./recipes";
import Event from "./event";
import ContactUs from "./contactUs";
import HomePage from "./homePage";
import Faq from "./faq";
import Coupon from "./coupon";
import Area from "./area";
import Calculation from './calculation'
import Menu from "./menu/index";
import Roast from "./roast";
import SubscribeOrder from "./subscribeOrder";
import Terms from "./terms/index";

@inject("stores")
@observer
class MainRoute extends Component {
  @observable
  store = this.props.stores.menu;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { match } = this.props;
    const { containerClassnames } = this.store;
    return (
      <div id="app-container" className={containerClassnames}>
        <TopNav history={this.props.history}></TopNav>
        <Sidebar location={this.props.location}/>
        <main>
          <div className="container-fluid">
            <Switch>
              <Route path={`${match.url}/dashboards`} component={Dashboards} />
              <Route path={`${match.url}/product`} component={Product} />
              <Route path={`${match.url}/order`} component={Order} />
              <Route path={`${match.url}/subscribeOrder`} component={SubscribeOrder} />
              <Route path={`${match.url}/faqCategory`} component={FaqCategory} />
              <Route path={`${match.url}/category`} component={Category} />
              <Route path={`${match.url}/social`} component={SocialNetwork} />
              <Route path={`${match.url}/deliveryTime`} component={DeliveryTime} />
              <Route path={`${match.url}/slider`} component={Featurette} />
              <Route path={`${match.url}/customers`} component={Customers} />
              <Route path={`${match.url}/tag`} component={Tag} />
              <Route path={`${match.url}/wholesale`} component={Wholesale} />
              <Route path={`${match.url}/brew`} component={Brew} />  
              <Route path={`${match.url}/roast`} component={Roast} />  
              <Route path={`${match.url}/blog`} component={Blog} />  
              <Route path={`${match.url}/brand`} component={Brand} />  
              <Route path={`${match.url}/profile`} component={Profile} />  
              <Route path={`${match.url}/careers`} component={Careers} />  
              <Route path={`${match.url}/user`} component={User} />  
              <Route path={`${match.url}/ourStory`} component={OurStory} />  
              <Route path={`${match.url}/memory`} component={Memory} />  
              <Route path={`${match.url}/recipes`} component={Recipes} />  
              <Route path={`${match.url}/event`} component={Event} />  
              <Route path={`${match.url}/contactUs`} component={ContactUs} /> 
              <Route path={`${match.url}/homePage`} component={HomePage} /> 
              <Route path={`${match.url}/faq`} component={Faq} /> 
              <Route path={`${match.url}/coupon`} component={Coupon} /> 
              <Route path={`${match.url}/area`} component={Area} /> 
              <Route path={`${match.url}/calculation`} component={Calculation} /> 
              <Route path={`${match.url}/menu`} component={Menu} /> 
              <Route path={`${match.url}/termsOfServices`} component={Terms} /> 

              <Redirect to="/error" />
            </Switch>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(MainRoute);
