import React, { Component, Fragment } from 'react'
import {
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Label,
  CardTitle,
} from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import IntlMessages from '../../../util/IntlMessages'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import SunEditor from 'suneditor-react'
import { Thumb } from '../../../components/Thumb'
import SelectInput from '../../../components/select'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import DeleteModal from '../../../components/Modal/deleteModal'
import { ToastContainer, toast } from 'react-toastify'
import ImageUploader from '../../../components/Uploader/Uploader'

@inject('stores')
@observer
class PostDetail extends Component {
  @observable
  store = this.props.stores.memoryDetail

  constructor(props) {
    super(props)
    this.state = { id: null }
  }

  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path, getImage: false })
    this.store.getPostDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }
  notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg)
        break
      case 'error':
        toast.error(msg)
        break
      default:
        break
    }
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFileEnglish = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeCoverImageFile(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.showUploaderArabic &&
      !this.store.imageFileAr &&
      this.store.handleUpdatePost(this.state.id, this.props.history)
  }

  changeImageFileArabic = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeThumbImageFile(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.handleUpdatePost(this.state.id, this.props.history)
  }

  render() {
    if (this.store.isLoading) {
      if (this.store.response) {
        this.notify(this.store.response.msg, this.store.response.type)
      }
    }
    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.memory" />}
                  match={this.props.match}
                />
                <Button
                  color="btn-outline-secondary"
                  className="btn-shadow float-right ml-2"
                  size="lg"
                  onClick={() => this.store.changeOpenDeleteModal()}
                >
                  <IntlMessages id="pages.delete" />
                </Button>
                <DeleteModal
                  target={
                    this.store.postDetail
                      ? this.store.postDetail.title_en
                      : null
                  }
                  openModal={this.store.openDeleteModal}
                  toggleModal={() => this.store.toggleDeleteModal()}
                  action={() =>
                    this.store.deletePost(this.state.id, this.props.history)
                  }
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => {
                    this.store.showUploaderEnglish &&
                    this.store.showUploaderArabic
                      ? this.store.handleUpdatePost(
                          this.state.id,
                          this.props.history
                        )
                      : this.handleSave()
                  }}
                >
                  <IntlMessages id="pages.save" />
                </Button>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                  <Card className="mb-2 h-100">
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`cover image`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      {this.store.showUploaderEnglish ? (
                            <>
                              {' '}
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploaderEnglish()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Cover Image</strong>
                                </Label>
                              </Button>
                              <Thumb
                              imageSize={{
                                imageHeight: '35%',
                                imageTop: 5,
                              }}
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.coverImageFile}
                                src={
                                  this.store.postDetail
                                    ? this.store.postDetail.image.url
                                    : '/assets/img/noimage.png'
                                }
                              />
                            </>
                          ) : (
                            <ImageUploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              getImage={this.state.getImage}
                              flag={true}
                              ratio="3:1"
                              size={{ width: 1920, height: 640 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploaderEnglish()
                              }
                              changeImageFile={(data) => {
                                this.changeImageFileEnglish(data)
                              }}
                            />
                          )}
                    
                    </CardBody>
                  </Card>
                </Colxx>

                <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                  <Card className="mb-2 h-100">
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`thumb image`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      {this.store.showUploaderArabic ? (
                        <>
                          {' '}
                          <Button
                            color="primary"
                            size="lg"
                            className="pointer mb-4 col-12"
                            style={{}}
                            onClick={() => {
                              this.store.handleImageUploaderArabic()
                            }}
                          >
                            <Label
                              className="btn btn-default no-padding col-12"
                              style={{
                                padding: '0px',
                                marginBottom: '0px',
                              }}
                            >
                              <strong>Change Thumb Image</strong>
                            </Label>
                          </Button>
                          <Thumb
                          imageSize={{
                            imageWidth: '66%',
                            imageTop: 5,
                            imageLeft:'17%'
                          }}
                            key="cat-image"
                            className="almost-full-width"
                            file={this.store.thumbImageFile}
                            src={
                              this.store.postDetail
                                ? this.store.postDetail.thumb.url
                                : '/assets/img/noimage.png'
                            }
                          />
                        </>
                      ) : (
                        <ImageUploader
                          multiple={true}
                          ref={(instance) => (this.productDetail = instance)}
                          images={this.state.imageFile}
                          getImage={this.state.getImage}
                          flag={true}
                          ratio="2:3"
                          size={{ width: 640, height: 960 }}
                          handleImageUploader={() =>
                            this.store.handleImageUploaderArabic()
                          }
                          changeImageFile={(data) => {
                            this.changeImageFileArabic(data)
                          }}
                        />
                      )}
                      
                    </CardBody>
                  </Card>
                </Colxx>
                <Colxx xxs="12" sm="4" lg="4" className="mb-4">
                  <Card className="mb-2 h-100" style={{ overflow: 'unset' }}>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`Detail`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.title-en" />
                          <span className="req"> *</span>
                        </p>
                        <p className="mb-0">
                          <Input
                            name="name"
                            value={
                              this.store.postDetail.title_en
                                ? this.store.postDetail.title_en
                                : ''
                            }
                            type="text"
                            onChange={(e) =>
                              this.store.changeEnName(e.target.value)
                            }
                          />
                        </p>
                        <span className="req-validation ">
                          {this.store.en_name_validation
                            ? this.store.en_name_validation.msg
                              ? this.store.en_name_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.title-ar" />
                          <span className="req"> *</span>
                        </p>
                        <p className="mb-0">
                          <Input
                            name="title_ar"
                            value={
                              this.store.postDetail.title_ar
                                ? this.store.postDetail.title_ar
                                : ''
                            }
                            type="text"
                            onChange={(e) =>
                              this.store.changeArName(e.target.value)
                            }
                            className="ar"
                          />
                        </p>
                        <span className="req-validation ">
                          {this.store.ar_name_validation
                            ? this.store.ar_name_validation.msg
                              ? this.store.ar_name_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.tag" />
                          <span className="req"> *</span>
                        </p>
                        <SelectInput
                          className="react-select"
                          classNamePrefix="react-select"
                          value={this.store.tags}
                          onChange={(value) => this.store.setTag(value)}
                          options={this.store.tagsList}
                          isMulti={true}
                        />
                        <span className="req-validation ">
                          {this.store.tag_validation
                            ? this.store.tag_validation.msg
                              ? this.store.tag_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12" className="mb-3">
                        <p className="text-muted text-small mb-2">
                          {/* <IntlMessages id="pages.tag" /> */}
                          Author
                          <span className="req"> *</span>
                        </p>
                        <SelectInput
                          className="react-select"
                          classNamePrefix="react-select"
                          value={this.store.admin}
                          onChange={(value) => this.store.setAuthor(value)}
                          options={this.store.adminsList}
                          isMulti={false}
                        />
                        <span className="req-validation ">
                          {this.store.author_validation
                            ? this.store.author_validation.msg
                              ? this.store.author_validation.msg
                              : null
                            : null}
                        </span>
                      </Colxx>

                      <Colxx xxs="12" lg="12">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.summary" />
                        </p>
                        <p className="mb-3">
                          <Input
                            name="summary"
                            value={
                              this.store.postDetail.summary_en
                                ? this.store.postDetail.summary_en
                                : ''
                            }
                            type="textarea"
                            onChange={(e) =>
                              this.store.changeEnSummary(e.target.value)
                            }
                          />
                        </p>
                      </Colxx>

                      <Colxx xxs="12" lg="12">
                        <p className="text-muted text-small mb-2">
                          <IntlMessages id="pages.summary-ar" />
                        </p>
                        <p className="mb-3">
                          <Input
                            name="summary-ar"
                            value={
                              this.store.postDetail.summary_ar
                                ? this.store.postDetail.summary_ar
                                : ''
                            }
                            type="textarea"
                            onChange={(e) =>
                              this.store.changeArSummary(e.target.value)
                            }
                          />
                        </p>
                      </Colxx>
                    </CardBody>
                  </Card>
                </Colxx>

                <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                  <Card className="mb-2 h-100" style={{ overflow: 'unset' }}>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`English Content`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <SunEditor
                        setContents={this.store.postDetail.content_en}
                        onChange={(data) =>
                          this.store.handleChangeEnContent(data)
                        }
                        setOptions={{
                          placeholder: 'Please type here...',
                          // autoFocus: true,
                          showPathLabel: true,
                          charCounter: true,
                          // maxCharCount: 720,
                          width: '100%',
                          height: 'auto',
                          minHeight: '200px',
                          buttonList: [
                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                            [
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                              'removeFormat',
                            ],
                            [
                              'fontColor',
                              'hiliteColor',
                              'outdent',
                              'indent',
                              'align',
                              'horizontalRule',
                              'list',
                              'table',
                            ],
                            [
                              'link',
                              'image',
                              'video',
                              'fullScreen',
                              'showBlocks',
                              'codeView',
                              'preview',
                              'print',
                              'save',
                            ],
                          ],
                        }}
                      />
                    </CardBody>
                  </Card>
                </Colxx>

                <Colxx xxs="12" sm="12" lg="6" xl="6" className="mb-4">
                  <Card className="mb-2 h-100" style={{ overflow: 'unset' }}>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span className="font-weight-bold text-uppercase">
                          {`Arabic Content`}
                        </span>
                      </CardTitle>
                      <Separator className="mb-5" />
                      <SunEditor
                        setContents={this.store.postDetail.content_ar}
                        onChange={(data) =>
                          this.store.handleChangeArContent(data)
                        }
                        setOptions={{
                          placeholder: 'Please type here...',
                          // autoFocus: true,
                          showPathLabel: true,
                          charCounter: true,
                          // maxCharCount: 720,
                          width: '100%',
                          height: 'auto',
                          minHeight: '200px',
                          buttonList: [
                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                            [
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                              'removeFormat',
                            ],
                            [
                              'fontColor',
                              'hiliteColor',
                              'outdent',
                              'indent',
                              'align',
                              'horizontalRule',
                              'list',
                              'table',
                            ],
                            [
                              'link',
                              'image',
                              'video',
                              'fullScreen',
                              'showBlocks',
                              'codeView',
                              'preview',
                              'print',
                              'save',
                            ],
                          ],
                        }}
                      />
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </Colxx>
          </>
        )}
      </Fragment>
    )
  }
}

export default PostDetail
